import React from 'react';
import { useTheme } from '../../../../../hooks/useTheme';
import { NavLink } from 'react-router-dom';
import './DkSubmenu.scss';
import { useDispatch, useSelector } from 'react-redux';
import TrashCan from '../../../../icons/TrashCan';
import { Pencil } from '../../../../icons/Pencil';
import { confirmAlert } from 'react-confirm-alert';
import { DkEditFolderModal } from '../DkEditFolderModal/DkEditFolderModal';
import { toggleEditArchiveModal } from '../../../../../redux/actions/dk';
import { apiPost } from '../../../../../api';
import { createUrlQuery } from '../../../../../utils/createApiUrl';
import { message } from 'antd';
import { getFolderIdUrl } from '../../../../../utils/getFolderId';

export const DkSubmenu = folderList => {
  const dispatch = useDispatch();
  const { isDark } = useTheme();
  const { isVisibleFolders } = useSelector(state => state.dk);
  const { response, fetchData } = folderList;

  const DeleteFolder = async id => {
    const { error, data } = await apiPost({ url: `/dk-folder/delete${createUrlQuery({ id })}` });
    if (!error) {
      await fetchData();
      message.success('Успешно удалено!');
    }
  };
  const showEditModal = (e, id, name) => {
    e.preventDefault();
    dispatch(toggleEditArchiveModal({ show: true, id, name }));
  };
  const handleDeleteFolder = (e, id) => {
    e.preventDefault();
    confirmAlert({
      title: 'Удаление',
      message: 'Вы точно хотите удалить архив?',
      buttons: [
        {
          label: 'Да',
          onClick: () => DeleteFolder(id),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  if (!isVisibleFolders) return null;
  return (
    <div>
      <div className="folders-wrap">
        {response?.models?.map(({ name, id }) => (
          <NavLink
            activeClassName="_active"
            className="folders-item"
            key={id}
            to={`/reason/${id}${getFolderIdUrl(id)}`}
          >
            <span>{name}</span>

            <div className="d-flex ai-center folders-item-icons">
              <Pencil
                onClick={e => showEditModal(e, id, name)}
                className="folders-item-icons_edit"
                color={isDark ? '#f6f6f6' : '#7b8190'}
              />
              <TrashCan onClick={e => handleDeleteFolder(e, id)} color={isDark ? '#f6f6f6' : '#7b8190'} />
            </div>
          </NavLink>
        ))}
      </div>

      <DkEditFolderModal {...folderList} />
    </div>
  );
};
