const initialState = {
  name: null,
};

export const modalReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'MODAL_VISIBLE':
      return { ...state, name: payload };

    default:
      return state;
  }
};
