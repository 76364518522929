import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Modal } from '../../components';
import { toggleCreateReasonModal } from '../../../redux/actions/dk';
import { apiPost } from '../../../api';
import { useParams } from 'react-router-dom';
import { createUrlQuery } from '../../../utils/createApiUrl';
import { message } from 'antd';
import { CustomEditor, hideOptionEditor } from '../../components/CustomEditor/CustomEditor';
const DEFAULT_VALUES = {
  name: '',
  text: '',
};
// eslint-disable-next-line react/prop-types
export const ReasonCreateModal = ({ fetchData }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isVisibleCreateReasonModal } = useSelector(state => state.dk);
  const [values, setValues] = useState(DEFAULT_VALUES);
  const [errors, setErrors] = useState({
    name: '',
    text: '',
  });

  const hideModal = () => {
    dispatch(toggleCreateReasonModal(false));
    setValues(DEFAULT_VALUES);
    hideOptionEditor();
  };

  const createReason = async () => {
    const { data, error, status } = await apiPost({
      url: `/dk-reason/create${createUrlQuery({ dkFolderId: id })}`,
      postData: values,
    });
    if (status === 406) {
      setErrors({
        name: data.name,
        text: data.text,
      });
    }
    if (!error) {
      hideModal();
      fetchData();
      message.success('Успешно создано!');
    }
  };

  const handleInputsChange = (value, name) => {
    setValues(p => ({ ...p, [name]: value }));
  };

  return (
    <Modal
      footer={
        <button className="btn btn-bg-acent" onClick={createReason}>
          Создать
        </button>
      }
      modalTitle="Создать причину приглашения"
      show={isVisibleCreateReasonModal}
      onClose={hideModal}
    >
      <div className="d-flex flex-column">
        <Input
          label="Название"
          error={errors.name && [errors.name]}
          value={values.name}
          onChange={v => handleInputsChange(v, 'name')}
        />
        <CustomEditor
          label="Текст"
          error={errors.text && [errors.text]}
          className="mt-3 editor"
          value={values.text}
          onChange={v => handleInputsChange(v, 'text')}
        />
      </div>
    </Modal>
  );
};
