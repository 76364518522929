import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Index from '../Spinner';

export const CustomButton = ({ view = 'primary', disabled, className, loading, size, children, icon, ...props }) => {
  return (
    <button
      disabled={disabled}
      {...props}
      className={cn('btn', `btn-${view}`, className, { '!cursor-not-allowed !opacity-30': disabled })}
    >
      {children}

      {icon && !loading && <div>{icon}</div>}
      {loading && <Index size={35} />}
    </button>
  );
};

CustomButton.propTypes = {
  view: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.any,
  children: PropTypes.any.isRequired,
};
