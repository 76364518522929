import { useHistory, useLocation, useParams } from 'react-router-dom';
import useUrl from '../../../hooks/useUrl';
import getUrlParams from '../../../hooks/getUrlParams';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchExtract } from '../../../api/dk/dk';
import { prepareSearchParams } from '../../../utils/prepareSearchParams';
import { apiGet, apiPost } from '../../../api';
import { createUrlQuery } from '../../../utils/createApiUrl';
import { message } from 'antd';
import { downloadReport } from '../../../utils/downloadReport';
import { confirmAlert } from 'react-confirm-alert';

const DEFAULT_VALUES = {
  status: '',
  executedStatus: '',
  organizationNameSearch: '',
  limit: 0,
};

export const useExtractPage = () => {
  const history = useHistory();
  const { page } = useUrl();
  const { pathname } = useLocation();
  const { id } = useParams();
  const urlParams = getUrlParams();
  const [values, setValues] = useState(DEFAULT_VALUES);
  const [extractIdsList, setExtractIdsList] = useState([]);
  const [extractALlIdsCheckbox, setExtractALlIdsCheckbox] = useState(false);
  const resetFiltersVisible = !!Object.values(values).filter(i => Boolean(i)).length;

  const extractQueryList = useQuery(['extract-list', page, id, values], () =>
    fetchExtract({
      dk_folder_id: id,
      page,
      ...values,
    })
  );

  const resetTableFilters = () => {
    setValues(DEFAULT_VALUES);
    history.push(`${pathname}${createUrlQuery({ archive: urlParams.archive })}`);
  };

  const handleValuesChange = (value, name, page = '1') => {
    setValues(p => ({ ...p, [name]: value }));
    const { fullUrl } = prepareSearchParams([
      { key: 'page', value: page },
      { key: name, value },
    ]);

    fullUrl && history.push(fullUrl);
  };

  const handleSendExtract = async ({ id, all }) => {
    confirmAlert({
      title: 'Отправление выписки',
      message: `Вы действительно хотите отправить ${all ? 'выписки' : 'выписку'}?`,
      buttons: [
        {
          label: 'Да',
          onClick: () => (all ? sendSelectedExtract() : sendExtract(id)),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  const sendExtract = async id => {
    const { error } = await apiPost({ url: `/dk-extract/send${createUrlQuery({ id })}` });
    if (!error) {
      await extractQueryList.refetch();
      message.success('Успешно отправлено!');
    }
  };

  const downloadFile = id => {
    apiGet({ url: `/dk-extract/download?id=${id}` }).then(res => {
      if (res.error) {
        console.error('Скачивание документа (шаблон)');
        return;
      }

      downloadReport(res.data, true);
    });
  };

  const downloadFileOrganization = (extractId, protocolId) => {
    apiGet({ url: `/dk-protocol/download-organization-doc?id=${protocolId}&extractId=${extractId}` }).then(res => {
      if (res.error) {
        console.error('Скачивание документа (шаблон)');
        return;
      }
      downloadReport(res.data, true);
    });
  };

  const setExecuted = async id => {
    const { error } = await apiPost({ url: `dk-extract/set-executed${createUrlQuery({ id })}` });
    if (!error) {
      message.success('Успешно изменено!');
      await extractQueryList.refetch();
    }
  };

  const setSelectExtractIds = id => {
    if (extractIdsList.includes(id)) {
      setExtractIdsList(p => p.filter(i => i !== id));
      if (extractIdsList.length === 1) {
        setExtractALlIdsCheckbox(false);
      }
    } else {
      setExtractIdsList(p => [...p, id]);
    }
  };

  const setSelectExtractAll = e => {
    const checked = e.target.checked;
    setExtractALlIdsCheckbox(checked);
    const ids = [];
    extractQueryList.data.data.models.map(i => {
      if (!i.isExtractSent) {
        ids.push(i.id);
      }
    });
    setExtractIdsList(checked ? ids : []);
  };

  const sendSelectedExtract = async () => {
    const { error } = await apiPost({
      url: `/dk-extract/send-multiple${createUrlQuery({ ids: extractIdsList.join(',') })}`,
    });
    if (!error) {
      message.success('Успешно оправлено!');
      setExtractIdsList([]);
      await extractQueryList.refetch();
    }
  };

  const handleExecute = async id => {
    confirmAlert({
      message: 'Вы действительно хотите сделать выписку исполненной?',
      buttons: [
        {
          label: 'Да',
          onClick: () => setExecuted(id),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  useEffect(() => {
    const newValues = {
      limit: urlParams.limit,
      status: urlParams.status,
      organizationNameSearch: urlParams.organizationNameSearch,
      executedStatus: urlParams.executedStatus,
    };
    for (const k in newValues) {
      if (newValues[k]) {
        handleValuesChange(newValues[k], k, page);
      }
    }
  }, []);

  useEffect(() => {
    setExtractIdsList([]);
    setExtractALlIdsCheckbox(false);
  }, [page]);

  return {
    setSelectExtractAll,
    handleSendExtract,
    extractIdsList,
    setSelectExtractIds,
    extractALlIdsCheckbox,
    downloadFile,
    handleExecute,
    handleValuesChange,
    values,
    extractQueryList,
    page,
    resetTableFilters,
    resetFiltersVisible,
    sendSelectedExtract,
    downloadFileOrganization,
  };
};
