import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-quill/dist/quill.snow.css';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { apiGet, apiPost } from '../../../../api';
import { useFetch } from '../../../../hooks/useFetch';
import { dataInboxFolders, getUnReadMessagesIncoming } from '../../../../redux/actions/user';
import { downloadBase64File } from '../../../../utils/downloadBase64';
import { Dropzone, Input, Modal } from '../../../components';
import './index.scss';
import { CustomEditor } from '../../../components/CustomEditor/CustomEditor';
import ReactQuill from 'react-quill';

const InboxMessage = () => {
  const { idMessage } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [clickedGroupId, setClickedGroupdId] = useState('');
  const [sendToFolderChat, setSendToFolderChat] = useState(false);
  const [modalSendMsg, setModalSendMsg] = useState(false);
  const [titleMessage, setTitleMessage] = useState();
  const [errObj, setErrObj] = useState({});
  const [bodyMessage, setBodyMessage] = useState('');
  const [files, setFiles] = useState([]);
  const [sendMessageLoad, setSendMessageLoad] = useState(false);

  const { response: folderList } = useFetch({
    url: '/mail-message-group',
  });

  const { response: getMessage } = useFetch({
    url: `/mail-message/view?id=${idMessage}`,
  });

  const alertMoveToFolder = () => {
    confirmAlert({
      title: '',
      message: 'Вы точно хотите переместить сообщение в другую папку?',
      buttons: [
        {
          label: 'Да',
          onClick: () => setSendToFolderChat(true),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  const alertUnreadMessage = () => {
    confirmAlert({
      title: '',
      message: 'Вы точно хотите сделать сообщение не прочитаным?',
      buttons: [
        {
          label: 'Да',
          onClick: () => toggleViewMessage(true),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  const alertDeleteMessage = () => {
    confirmAlert({
      title: '',
      message: 'Вы точно хотите удалить сообщение?',
      buttons: [
        {
          label: 'Да',
          onClick: () => deleteMessage(true),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  const toggleViewMessage = () => {
    apiGet({ url: `/mail-message/set-as-not-viewed?id=${idMessage}` }).then(res => {
      if (res.error) {
        console.error('Получение папок');
        return;
      }
      getUnreadableMsg();
      getInboxFolders();
    });
  };

  const deleteMessage = () => {
    apiGet({ url: `/mail-message/delete?ids=${idMessage}` }).then(res => {
      if (res.error) {
        console.error('Получение папок');
        return;
      }
      history.goBack();
    });
  };

  const getInboxFolders = () => {
    apiGet({ url: '/mail-message-group' }).then(res => {
      if (res.error) {
        console.error('Получение папок');
        return;
      }
      dispatch(dataInboxFolders(res));
      setSendToFolderChat(false);
    });
  };

  const getUnreadableMsg = () => {
    apiGet({ url: '/mail-message/not-viewed-count?group=0' }).then(res => {
      if (res?.error) {
        console.error('Получение непрочитаных сообщений');
        return;
      }
      dispatch(getUnReadMessagesIncoming(res.data));
    });
  };

  const moveToFolder = () => {
    apiPost({
      url: '/mail-message-group/move',
      postData: {
        mailMessageGroupId: clickedGroupId,
        mailMessageIds: idMessage,
      },
    }).then(res => {
      if (res.error) {
        console.error('Перемещение чата');
        return;
      }
      setClickedGroupdId([]);
      getInboxFolders();
      getUnreadableMsg();
    });
  };

  const sendMessage = () => {
    setSendMessageLoad(true);
    if (titleMessage == '<p><br></p>') {
      setTitleMessage('');
    }
    let formData = new FormData();

    formData.append('organizationIds', getMessage[getMessage.length - 1].organization.id);
    formData.append('title', titleMessage);
    formData.append('body', bodyMessage);

    if (getMessage[getMessage.length - 1]?.mailMessageGroup?.id) {
      formData.append('mailMessageGroupId', getMessage[getMessage.length - 1]?.mailMessageGroup.id);
    }

    if (files.length) {
      files.forEach(f => {
        formData.append('files[]', f);
      });
    }
    apiPost({
      url: `/mail-message/create?answerTo=${idMessage}`,
      postData: formData,
    })
      .then(res => {
        setSendMessageLoad(false);
        if (res.error) {
          setErrObj(res.data);
          return;
        }
        setErrObj({});
        setTitleMessage('');
        setBodyMessage('');
        setFiles();
        history.goBack();
      })
      .finally(() => setSendMessageLoad(false));
  };

  const handleUploadFiles = file => {
    setFiles([...files, ...file]);
  };

  const removeFile = fileName => {
    const data = files.filter(item => item.name !== fileName);
    setFiles(data);
  };

  useEffect(() => {
    if (getMessage) {
      setTitleMessage(getMessage[getMessage.length - 1].title);
    }
  }, [getMessage]);

  useEffect(() => {
    getUnreadableMsg();
  }, []);

  const downloadFile = id => {
    apiGet({
      url: `/mail-message/file?id=${id}&withContent=0`,
      timeout: 60000000,
    }).then(res => {
      if (res?.error) {
        console.error('Скачивание файла');
        return;
      }
      apiGet({
        url: `/mail-message/file?id=${id}`,
        timeout: 60000000,
        fileWeight: res?.data?.sizeInBytes,
      }).then(res => {
        if (res?.error) {
          console.error('Скачивание файла');
          return;
        }
        downloadBase64File(res.data.asBase64, res.data.name);
      });
    });
  };

  const downloadAllFiles = id => {
    apiGet({
      url: `/mail-message/files-as-archive?id=${id}`,
      timeout: 60000000,
      fileWeight: getMessage?.[0]?.archiveSize,
    }).then(res => {
      if (res?.error) {
        console.error('Скачивание всех файлов');
        return;
      }

      downloadBase64File(res.data.content, res.data.name);
    });
  };

  const funcRecUpdate = children => {
    return (
      <div className=" ml-2 mt-2 left-border">
        <div
          style={{ whiteSpace: 'pre-line' }}
          className=" col-auto"
          dangerouslySetInnerHTML={{ __html: children.body }}
        ></div>
        {/* {children.body} */}
        {children.parentMessageStd && funcRecUpdate(children.parentMessageStd)}
      </div>
    );
  };

  return (
    <div className="page-message">
      <div className="card">
        <div className="card-header">
          <div className="row ai-center jc-between">
            <div className="col-auto">Сообщение</div>
          </div>
        </div>

        <div className="card-content">
          <div className="row">
            <div className="col-auto">
              <button className="btn btn-primary" onClick={() => alertMoveToFolder(true)}>
                Переместить
              </button>
            </div>
            <div className="col-auto">
              <button className="btn btn-danger" onClick={() => alertDeleteMessage()}>
                Удалить
              </button>
            </div>
            {getMessage?.[getMessage.length - 1].from == '1' && (
              <div className="col-auto">
                <button className="btn btn-warning" onClick={() => alertUnreadMessage()}>
                  Не прочитано
                </button>
              </div>
            )}

            <div className="col-auto">
              <button className="btn btn-light" onClick={() => window.print()}>
                Печать
              </button>
            </div>

            <div className="col-auto">
              <button className="btn btn-success" onClick={() => setModalSendMsg(true)}>
                Ответить
              </button>
            </div>
          </div>
          {getMessage?.map((i, index) => (
            <>
              <div className="card-header mt-3 d-flex flex-column" style={{ fontSize: '15px' }} key={index}>
                <div>от: {i.from === 2 ? 'sromfoalliance' : `${i.organization.name}`}</div>

                <div>кому: {i.from === 1 ? 'sromfoalliance' : `${i.organization.name}`}</div>

                <div>{i.from === 1 && `инн: ${i.organization?.inn}`}</div>
                <div>Тема: {i.title}</div>
              </div>
              <div className="card-body">
                <div className="row  mt-4 mail" key={index}>
                  <div className="col-10">
                    <div
                      style={{ whiteSpace: 'pre-line' }}
                      className="mail-msg"
                      dangerouslySetInnerHTML={{ __html: i.body }}
                    />
                    <div className="files">
                      {i.files.map(f => (
                        <span className="file-mail" key={f.name} onClick={() => downloadFile(f.id)}>
                          {f.name}
                        </span>
                      ))}
                      {i.isHasFile && (
                        <span className="file-mail" onClick={() => downloadAllFiles(i.id)}>
                          Скачать все файлы
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-2">
                    <span className="small-date">{i.createdAt}</span>
                  </div>
                  <div className="childrens">{i.parentMessageStd && funcRecUpdate(i.parentMessageStd)}</div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      <Modal
        modalTitle="Переместить в папку"
        show={sendToFolderChat}
        onClose={() => setSendToFolderChat(false)}
        footer={
          <button className="btn btn-bg-acent" onClick={() => moveToFolder()}>
            Переместить в папку
          </button>
        }
      >
        <label className="form-field">
          <span className="form-label">Выберите папку</span>
          <select
            value={clickedGroupId}
            onChange={e => setClickedGroupdId(e.target.value)}
            className="form-input _select"
          >
            <option value={0}>Входящие</option>
            {folderList?.data.map(i => (
              <option value={i.id} key={i.id}>
                {i.name}
              </option>
            ))}
          </select>
        </label>
      </Modal>
      <Modal
        modalTitle="Ответить на сообщение"
        show={modalSendMsg}
        onClose={() => setModalSendMsg(false)}
        footer={
          <button
            className={`btn btn-bg-acent ${sendMessageLoad ? 'blocked-btn' : ''}`}
            onClick={() => (sendMessageLoad ? null : sendMessage())}
          >
            Ответить на сообщение
          </button>
        }
      >
        <Input fieldClasses="mb-0 mt-3" label="Тема" onChange={setTitleMessage} value={titleMessage} />

        {errObj.title &&
          errObj.title.map((i, index) => (
            <span key={index} className="_error">
              {i}
            </span>
          ))}

        {/*<CustomEditor className="mt-3 editor" value={bodyMessage} onChange={setBodyMessage} />*/}
        <ReactQuill className="mt-3 editor" value={bodyMessage} onChange={setBodyMessage} />

        {errObj.body &&
          errObj.body.map((i, index) => (
            <span key={index} className="_error">
              {i}
            </span>
          ))}

        <div className="mt-3">
          {' '}
          <Dropzone
            maxSize={100000000}
            onFileRemove={removeFile}
            onFileUpload={handleUploadFiles}
            styles={{ height: 120 }}
            files={files}
            // acceptFileType=".jpg, .jpeg, .png, .doc, .docx, .pdf, .xls, .xlsx, .xtdd"
          />
        </div>
        {errObj.files && <span style={{ color: '#f46a6a', fontSize: '12px' }}>{errObj.files}</span>}
      </Modal>
    </div>
  );
};

export default InboxMessage;
