import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Modal } from '../../components';
import { apiPost } from '../../../api';
import { createUrlQuery } from '../../../utils/createApiUrl';
import { toggleUpdateReasonModal } from '../../../redux/actions/dk';
import { message } from 'antd';
import { useFetch } from '../../../hooks/useFetch';
import { CustomEditor } from '../../components/CustomEditor/CustomEditor';

// eslint-disable-next-line react/prop-types
export const ReasonUpdateModal = ({ fetchData }) => {
  const dispatch = useDispatch();
  const { show, reason } = useSelector(state => state.dk.updateReasonModal);
  const { response } = useFetch({ url: show && `/dk-reason/view${createUrlQuery({ id: reason?.id })}` });

  const [values, setValues] = useState({
    name: '',
    text: '',
  });
  const [errors, setErrors] = useState({
    name: '',
    text: '',
  });

  const hideModal = () => {
    dispatch(toggleUpdateReasonModal({ show: false, reason: null }));
  };

  const updateReason = async () => {
    const { error, status, data } = await apiPost({
      url: `/dk-reason/update${createUrlQuery({ id: reason.id })}`,
      postData: values,
    });
    if (status === 406) {
      setErrors({
        name: data.name,
        text: data.text,
      });
    }
    if (!error) {
      hideModal();
      fetchData();
      message.success('Успешно отредактировано!');
    }
  };

  const handleInputsChange = (value, name) => {
    setValues(p => ({ ...p, [name]: value }));
  };

  useEffect(() => {
    if (reason && show && response) {
      setValues({ name: reason.name, text: response?.text });
    }
  }, [show, response]);

  return (
    <Modal
      footer={
        <button className="btn btn-bg-acent" onClick={updateReason}>
          Редактировать
        </button>
      }
      modalTitle="Редактировать причину приглашения"
      show={show}
      onClose={hideModal}
    >
      <div className="d-flex flex-column">
        <Input
          label="Название"
          error={errors.name && [errors.name]}
          value={values.name}
          onChange={v => handleInputsChange(v, 'name')}
        />
        <CustomEditor
          label="Текст"
          error={errors.text && [errors.text]}
          className="mt-3 editor"
          value={values.text}
          onChange={v => handleInputsChange(v, 'text')}
        />
      </div>
    </Modal>
  );
};
