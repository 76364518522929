import React from 'react';
import { useInvitationViewPage } from '../useInvitationViewPage';
import { Pagination } from '../../../../components';
import Index from '../../../../components/Spinner';
import { Download, TrashCan } from '../../../../icons';
import { Pencil } from '../../../../icons/Pencil';
import { InvitationAddFileModal } from './InvitationAddFileModal';
import { useDispatch } from 'react-redux';
import { modalApi } from '../../../../../redux/actions/modal';
import { apiGet, apiPost } from '../../../../../api';
import { createUrlQuery } from '../../../../../utils/createApiUrl';
import { message } from 'antd';
import { confirmAlert } from 'react-confirm-alert';
import { downloadReport } from '../../../../../utils/downloadReport';

export const InvitationFilesTable = () => {
  const { response, loading, fetchData } = useInvitationViewPage();
  const dispatch = useDispatch();

  const deleteInvitation = async id => {
    const { error } = await apiPost({ url: `/dk-invitation/delete-doc${createUrlQuery({ id })}` });
    if (!error) {
      await fetchData();
      message.success('Успешно удалено!');
    }
  };
  const handleConfirmDeleteInvitation = (e, id) => {
    e.stopPropagation();
    confirmAlert({
      title: 'Удаление',
      message: 'Вы точно хотите удалить файл?',
      buttons: [
        {
          label: 'Да',
          onClick: () => deleteInvitation(id),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };
  const downloadFile = id => {
    apiGet({ url: `/dk-invitation/download-doc?id=${id}` }).then(res => {
      if (res.error) {
        console.error('Скачивание документа (шаблон)');
        return;
      }
      console.log(res);
      downloadReport(res.data, true);
    });
  };

  return (
    <div>
      <div className="card">
        <div className="card-header jc-between d-flex ai-center">
          Файлы
          <button onClick={() => dispatch(modalApi('invitation-add-file'))} className="btn btn-primary btn-xs">
            Добавить файл
          </button>
        </div>
        {response?.docs?.length ? (
          <div className="card-content">
            {loading ? (
              <div className="d-flex jc-center ">
                <Index size={60} />
              </div>
            ) : (
              <div className="scroll-x">
                <table className="mt-4 middle-table">
                  <thead>
                    <tr>
                      <th>Название</th>
                      <th>Размер</th>
                      <th className="settings" />
                    </tr>
                  </thead>

                  <tbody>
                    {response.docs.map(file => (
                      <tr key={file.id}>
                        <td>
                          {file.name || ''}.{file.extension}
                        </td>
                        <td>{file.size}</td>
                        <td>
                          <div onClick={e => e.stopPropagation()} className="d-flex ai-center jc-end gap-2">
                            <button
                              className="action-btn btn btn-light"
                              title="Скачать"
                              onClick={() => downloadFile(file.id)}
                            >
                              <Download />
                            </button>
                            <button
                              className="action-btn btn btn-danger"
                              onClick={e => handleConfirmDeleteInvitation(e, file.id)}
                              title="Удалить"
                            >
                              <TrashCan />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        ) : (
          <div className="w-full text-center font-medium text-primary py-5">Ничего не найдено</div>
        )}
      </div>

      <InvitationAddFileModal fetchData={fetchData} />
    </div>
  );
};
