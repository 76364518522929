import { useFetch } from '../useFetch';
import { createUrlQuery } from '../../utils/createApiUrl';

export const useOrganization = ({ enabled, ...props }) => {
  const { response, loading, fetchData } = useFetch({
    url: enabled && `/organization${createUrlQuery({ limit: 1500, ...props })}`,
  });

  const selectOrganizationOptions = !loading ? response?.models?.map(({ id, name }) => ({ id, name })) : [];
  return { response, loading, fetchData, selectOrganizationOptions };
};
