export const createUrlQuery = ({ ...props }) => {
  const url = new URLSearchParams(getEntries(props)).toString();

  return url.length ? `?${url}` : '';
};

const getEntries = (o = {}) => {
  const entries = [];

  for (const [k, v] of Object.entries(o)) {
    if (v) {
      if (Array.isArray(v)) entries.push(...v.flatMap(getEntries));
      else if (typeof v === 'object') entries.push(...getEntries(v));
      else entries.push([k, v]);
    }
  }

  return entries;
};
