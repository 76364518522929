import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import { apiPost } from '../../../api';
import { createUrlQuery } from '../../../utils/createApiUrl';
import { modalApi } from '../../../redux/actions/modal';
import { Dropzone, Input, Modal } from '../../components';
import useUrl from '../../../hooks/useUrl';

export const OrganizationAddFileModal = ({ fetchData }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { protocolId } = useUrl();
  const isVisible = useSelector(state => state.modal.name) === 'organization-add-file';
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  const addFile = async () => {
    if (!file) {
      setError('Необходимо заполнить Файл');

      return;
    }
    const postData = new FormData();
    // postData.append('name', form.name);
    postData.append('file', file);
    const { error, data, status } = await apiPost({
      url: `/dk-protocol/upload-organization-doc${createUrlQuery({ extractId: id, id: protocolId })}`,
      postData,
    });

    if (status === 406) {
      setError(data.file[0]);
    }

    if (!error) {
      message.success('Добавлено');
      hideModal();
      fetchData?.();
    }
  };
  const hideModal = () => {
    setError(null);
    setFile(null);
    dispatch(modalApi(null));
  };
  return (
    <Modal
      footer={
        <button className="btn btn-bg-acent" onClick={addFile}>
          Добавить
        </button>
      }
      modalTitle="Добавить файл"
      show={isVisible}
      onClose={hideModal}
    >
      <Dropzone
        maxSize={1000000000000000}
        onFileRemove={() => setFile(null)}
        files={file ? [file] : []}
        errorMessage={error || ''}
        multiple={false}
        onFileUpload={f => setFile(f[0])}
      />
    </Modal>
  );
};

OrganizationAddFileModal.propTypes = {
  fetchData: PropTypes.func,
};
