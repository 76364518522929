const initialState = {
  isVisibleFolders: false,
  isVisibleNewArchiveModal: false,
  isVisibleCreateReasonModal: false,
  isVisibleCreateInvitationModal: false,
  updateInvitationModal: {
    show: false,
    invitation: null,
  },
  editArchiveModal: {
    show: false,
    id: null,
    name: null,
  },
  updateReasonModal: {
    show: false,
    reason: null,
  },
  protocol: null,
};

export const dkReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'TOGGLE_DK':
      return { ...state, isVisibleFolders: !state.isVisibleFolders };
    case 'SHOW_DK':
      return { ...state, isVisibleFolders: true };
    case 'NEW_ARCHIVE_MODAL':
      return { ...state, isVisibleNewArchiveModal: payload };
    case 'EDIT_ARCHIVE_MODAL':
      return { ...state, editArchiveModal: { ...payload } };
    case 'CREATE_REASON_MODAL':
      return { ...state, isVisibleCreateReasonModal: payload };
    case 'UPDATE_REASON_MODAL':
      return { ...state, updateReasonModal: { ...payload } };
    case 'CREATE_INVITATION_MODAL':
      return { ...state, isVisibleCreateInvitationModal: payload };
    case 'UPDATE_INVITATION_MODAL':
      return { ...state, updateInvitationModal: { ...payload } };
    case 'SET_PROTOCOL':
      return { ...state, protocol: payload };
    default:
      return state;
  }
};
