import PropTypes from 'prop-types';
import * as React from 'react';

const Cancel = ({ size = 15, color = '#fff', ...props }) => {
  return (
    <svg viewBox="0 0 512 512.001" width={size} height={size} fill={color} {...props}>
      <path d="M284.284 256L506.142 34.142c7.811-7.81 7.811-20.474 0-28.284-7.811-7.811-20.474-7.811-28.284 0L256 227.716 34.142 5.858c-7.811-7.811-20.474-7.811-28.284 0-7.811 7.81-7.811 20.474 0 28.284L227.716 256 5.858 477.858c-7.811 7.811-7.811 20.474 0 28.284 7.81 7.81 20.473 7.811 28.284 0L256 284.284l221.858 221.858c7.81 7.81 20.473 7.811 28.284 0s7.811-20.474 0-28.284L284.284 256z" />
    </svg>
  );
};

Cancel.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default Cancel;
