import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { apiPost } from '../../../api';
import { createUrlQuery } from '../../../utils/createApiUrl';
import { errorParse } from '../../../utils/errorParse';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import useUrl from '../../../hooks/useUrl';
import { useFetch } from '../../../hooks/useFetch';
import { getFolderIdUrl } from '../../../utils/getFolderId';
import { useDebouncedCallback } from 'use-debounce';

export const useOrganizationUpdateDkPage = () => {
  const methods = useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const params = useUrl();
  const history = useHistory();
  const [values, setValues] = useState({});

  const { response, loading } = useFetch({
    url: `/dk-protocol/view-organization${createUrlQuery({
      extractId: id,
      id: params.protocolId,
    })}`,
  });

  const onFormChange = (key, value) => {
    setValues(p => ({
      ...p,
      [key]: value,
    }));
    debouncedSave();
  };

  const debouncedSave = useDebouncedCallback(async () => {
    await updateInfo(false);
  }, 5000);

  const linkRoute = [
    {
      name: 'Протоколы',
      link: `/protocol/${params.archive}${getFolderIdUrl()}`,
    },
    {
      name: 'Протокол ',
      link: `/protocol-view/${params.protocolId}${getFolderIdUrl()}`,
    },
    {
      name: 'Организация',
      link: `/dk-organization/${id}${getFolderIdUrl(null, { protocolId: params.protocolId })}`,
    },

    {
      name: `Изменение информации ${id}`,
    },
  ];

  const updateInfo = async (redirect = true) => {
    const { data, error, status } = await apiPost({
      url: `/dk-protocol/update-organization${createUrlQuery({
        extractId: id,
        id: params.protocolId,
      })}`,
      postData: { ...values },
      showProgress: redirect,
    });

    if (status === 406) {
      errorParse(data, methods.setError);
      return;
    }

    if (!error) {
      message.success('Успешно сохранено!');
      redirect && history.goBack();
    }
  };

  useEffect(() => {
    if (response) {
      const object = {
        mainText: response.main_text || '',
        solveText: response.solve_text || '',
        votingText: response.voting_text || '',
      };
      setValues(object);
    }
  }, [id, response]);

  return { id, dispatch, onFormChange, methods, history, linkRoute, loading, updateInfo, values, setValues };
};
