import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { apiGet, apiPost } from '../../../../api';
import updateUrl from '../../../../hooks/updateUrl';
import { useFetch } from '../../../../hooks/useFetch';
import useUrl from '../../../../hooks/useUrl';
import { countNotReprimanded } from '../../../../redux/actions/user';
import { goToLink } from '../../../../utils/goToLink';
import { Input, Modal, Pagination, Select } from '../../../components';
import { Cancel, Gear, Plus, TrashCan } from '../../../icons';
import './index.scss';

const Reports = () => {
  const state = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const { page, template_id, period_id } = useUrl();
  const [createReportModal, setCreateReportModal] = useState(false);
  const [editReportModal, setEditReportModal] = useState(false);
  // const [page, setPage] = useState(1)
  const [sending, setSending] = useState(false);
  const [newReportDataTemplate, setNewReportDataTemplate] = useState('');
  const [newReportDataPeriod, setNewReportDataPeriod] = useState('');
  const [editReportId, setEditReportId] = useState('');
  const [editReportTemplateId, setEditReportTemplateId] = useState('');
  const [editReportPeriodId, setEditReportPeriodId] = useState('');
  const [newReportPeriodData, setnewReportPeriodData] = useState(null);
  const [newReportTemplateData, setNewReportTemplateData] = useState(null);
  const [fileName, setFileName] = useState('');
  const [fileError, setFileError] = useState('');

  const [filterReport, setFilterReport] = useState(template_id);
  const [filterPeriod, setFilterPeriod] = useState(period_id);

  const viewCreateModal = isShow => {
    setCreateReportModal(isShow);
  };
  const editReportMdal = isShow => {
    setEditReportModal(isShow);
  };

  const filterReportFunc = v => {
    setFilterReport(v);
    history.push(updateUrl({ key: 'page', value: '1' }));
    history.push(updateUrl({ key: 'template_id', value: v }));
  };

  const filterPeriodFunc = v => {
    setFilterPeriod(v);
    history.push(updateUrl({ key: 'page', value: '1' }));
    history.push(updateUrl({ key: 'period_id', value: v }));
  };

  //get report list
  let url = `/report?page=${page ? page : 1}&limit=10&`;

  if (filterReport) {
    if (filterReport == 0) {
      setFilterReport('');
    } else url += `&template_id=${filterReport}`;
  }

  if (filterPeriod) {
    if (filterPeriod == 0) {
      setFilterPeriod('');
    } else url += `&period_id=${filterPeriod}`;
  }

  const { response, fetchData } = useFetch({ url });

  // Получение списка отчетных периодов
  const { response: reportPeriod } = useFetch({
    url: `/report-period?limit=10000`,
  });

  // Получение списка шаблонов
  const { response: reportTemplate } = useFetch({
    url: `/report-template?limit=10000`,
  });

  useEffect(() => {
    let newReportPeriod = { id: 0, name: 'Выберите период' };
    reportPeriod?.models?.unshift(newReportPeriod);
    setnewReportPeriodData(reportPeriod);
  }, [reportPeriod]);

  useEffect(() => {
    let newReportTemplate = { id: 0, name: 'Выберите шаблон' };
    reportTemplate?.models?.unshift(newReportTemplate);
    setNewReportTemplateData(reportTemplate);
  }, [reportTemplate]);

  useEffect(() => {
    apiGet({ url: `/report/not-reprimanded-count` }).then(res => {
      dispatch(countNotReprimanded(res.data));
    });
  }, []);

  const createReportAlert = () => {
    confirmAlert({
      message: 'Отчет успешно создан',
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  const createReportError = text => {
    let arr = [];
    for (let key in text) {
      arr.push(text[key]);
    }
    let textMsg = arr.join(',');

    confirmAlert({
      message: textMsg,
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  const editActualityReport = id => {
    confirmAlert({
      title: 'Изменение',
      message: 'Вы точно хотите сделать отчет актуальным?',
      buttons: [
        {
          label: 'Да',
          onClick: () => reportActual(id),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  const editNoActualityReport = id => {
    confirmAlert({
      title: 'Изменение',
      message: 'Вы точно хотите сделать отчет не актуальным?',
      buttons: [
        {
          label: 'Да',
          onClick: () => reportUnActual(id),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  const delReportAlert = id => {
    confirmAlert({
      title: 'Удаление',
      message: 'Вы точно хотите удалить отчет?',
      buttons: [
        {
          label: 'Да',
          onClick: () => deleteReport(id),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  // create report
  const createReport = () => {
    apiPost({
      url: `/report/create`,
      postData: {
        template_id: newReportDataTemplate,
        period_id: newReportDataPeriod,
        filename: fileName,
        filename_error_text: fileError,
      },
    }).then(res => {
      if (res.error) {
        createReportError(res.data);
        console.error('Изменение триггера');
        return;
      }
      setFileName('');
      setFileError();
      setCreateReportModal(false);
      fetchData();
      createReportAlert();
    });
  };

  //Изменение отчетного периода

  const openEditReport = item => {
    setFileName(item?.filename || '');
    setFileError(item?.filename_error_text || '');
    editReportMdal(true);
    setEditReportId(item.id);
    setEditReportTemplateId(item.template.id);
    setEditReportPeriodId(item.period.id);
  };

  const editReport = id => {
    if (!sending) {
      setSending(true);

      const postData = {
        template_id: editReportTemplateId,
        period_id: editReportPeriodId,
        filename: fileName,
        filename_error_text: fileError,
      };

      apiPost({ url: `/report/update?id=${id}`, postData })
        .then(res => {
          if (res.error) {
            console.error('Загрузка отчета');
            return;
          }
          setFileName('');
          setFileError('');
          editReportMdal(false);
          fetchData();
        })
        .finally(() => setSending(false));
    }
  };
  //Сделать отчет актуальным

  const reportActual = id => {
    if (!sending) {
      setSending(true);

      apiGet({ url: `/report/set-actual?id=${id}` })
        .then(res => {
          if (res.error) {
            console.error('Сделать отчет актуальным');
            return;
          }

          fetchData();
        })
        .finally(() => setSending(false));
    }
  };

  //Сделать отчет не актуальным

  const reportUnActual = id => {
    if (!sending) {
      setSending(true);

      apiGet({ url: `/report/set-not-actual?id=${id}` })
        .then(res => {
          if (res.error) {
            console.error('Сделать отчет не актуальным');
            return;
          }

          fetchData();
        })
        .finally(() => setSending(false));
    }
  };

  // Удаление отчета периода
  const deleteReport = id => {
    if (!sending) {
      setSending(true);

      apiGet({ url: `/report/delete?id=${id}` })
        .then(res => {
          if (res.error) {
            console.error('Удаление отчетного периода');
            return;
          }

          editReportMdal(false);
          fetchData();
        })
        .finally(() => setSending(false));
    }
  };

  useEffect(() => {
    setFileName('');
    setFileError('');
  }, [createReportModal]);

  useEffect(() => {
    if (editReportModal === false) {
      setFileName('');
      setFileError('');
    }
  }, [editReportModal]);

  return (
    <div className="reports">
      <div className="card">
        <div className="card-header">
          <div className="row ai-center jc-between">
            <div className="col-auto">Отчеты</div>
            {state.user.accessUser.report.create && (
              <div className="col-auto">
                <button className="btn btn-primary" onClick={() => viewCreateModal(true)}>
                  Создать новый отчет
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="card-content">
          <div className="row">
            <div className="col-12 col-md-4">
              <Select
                label="Шаблон"
                onChange={filterReportFunc}
                value={filterReport}
                values={newReportTemplateData?.models || []}
              />
            </div>
            <div className="col-12 col-md-4">
              <label className="form-field">
                <span className={`form-label`}>Период</span>

                <select
                  value={filterPeriod}
                  onChange={e => filterPeriodFunc(e.target.value)}
                  className="form-input _select"
                  disabled={false}
                >
                  {newReportPeriodData?.models.map(v => {
                    return (
                      <option key={v.id} value={v.id}>
                        {v.name} {v?.startDate && v?.endDate && '('}
                        {v?.startDate} {v?.startDate && v?.endDate && '-'} {v?.endDate}
                        {v?.startDate && v?.endDate && ')'}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
          </div>
          <div className="scroll-x">
            <table className="big-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Шаблон</th>
                  <th>Период</th>
                  <th>Дата отчетного периода</th>
                  <th>Загружено отчетов</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {response?.models.map((i, index) => (
                  <tr
                    key={i.id}
                    className={!i.isActual ? 'notActual' : 'go-link'}
                    style={{ cursor: 'pointer' }}
                    onClick={e => goToLink(e, () => history.push(`/organization-reports/${i.id}`))}
                  >
                    <td>{index + 1}</td>
                    <td>{i.template.name}</td>
                    <td>{i.period.name}</td>
                    <td>
                      {i.period.startDate} - {i.period.endDate}
                    </td>
                    <td>
                      {i.isNotReprimandedCount !== 0 && (
                        <div
                          style={{
                            borderRadius: '300px',
                            backgroundColor: '#5b73e8',
                            display: 'inline-flex',
                            padding: '2px 8px',
                            color: '#fff',
                            fontSize: '12px',
                          }}
                        >
                          {' '}
                          {i.isNotReprimandedCount}
                        </div>
                      )}
                    </td>
                    <td>
                      <div className="row row-sm jc-end">
                        {state.user.accessUser.report[`set-actual`] && (
                          <span>
                            {!i.isActual ? (
                              <div className="col-auto">
                                <button
                                  className="action-btn btn btn-success"
                                  onClick={() => editActualityReport(i.id)}
                                  title="Сделать отчет актуальным"
                                >
                                  <Plus />
                                </button>
                              </div>
                            ) : (
                              <div className="col-auto">
                                <button
                                  className="action-btn btn btn-secondary"
                                  onClick={() => editNoActualityReport(i.id)}
                                  title="Сделать отчет не актуальным"
                                >
                                  <Cancel />
                                </button>
                              </div>
                            )}
                          </span>
                        )}
                        {/* <div className="col-auto">
													<Link
														to={"/organization-reports/" + i.id}
														className="action-btn btn btn-primary"
													>
														<View />
													</Link>
												</div> */}
                        {state.user.accessUser.report.update && (
                          <div className="col-auto">
                            <button
                              className="action-btn btn btn-warning"
                              onClick={() => openEditReport(i)}
                              title="Изменить"
                            >
                              <Gear />
                            </button>
                          </div>
                        )}
                        {state.user.accessUser.report.delete && (
                          <div className="col-auto">
                            <button
                              className="action-btn btn btn-danger"
                              onClick={() => delReportAlert(i.id)}
                              title="Удалить"
                            >
                              <TrashCan />
                            </button>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-2 ta-center">
            <Pagination lastPage={response?.lastPage} page={page} />
          </div>
        </div>
      </div>

      <Modal
        show={createReportModal}
        onClose={() => setCreateReportModal(false)}
        modalTitle="Создание нового отчета"
        footer={
          <button className="btn btn-primary" onClick={() => createReport()}>
            Создать
          </button>
        }
      >
        <div className="row">
          <div className="col-12 col-md-6">
            <Select
              label="Шаблон"
              value={newReportDataTemplate}
              onChange={e => setNewReportDataTemplate(e)}
              values={reportTemplate?.models || []}
            />
          </div>

          <div className="col-12 col-md-6">
            <label className="form-field">
              <span className={`form-label`}>Период</span>

              <select
                value={newReportDataPeriod}
                onChange={e => setNewReportDataPeriod(e.target.value)}
                className="form-input _select"
                disabled={false}
              >
                {reportPeriod?.models.map(v => {
                  return (
                    <option key={v.id} value={v.id}>
                      {v.name} {v?.startDate && v?.endDate && '('}
                      {v?.startDate} {v?.startDate && v?.endDate && '-'} {v?.endDate}
                      {v?.startDate && v?.endDate && ')'}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
          <div className="col-12 col-md-6">
            <Input label="Название файла" value={fileName} onChange={e => setFileName(e)} />
          </div>
          <div className="col-12 col-md-6">
            <Input label="Текст ошибки" value={fileError} onChange={e => setFileError(e)} />
          </div>
        </div>
      </Modal>

      <Modal
        show={editReportModal}
        onClose={() => editReportMdal(false)}
        modalTitle="Редактирование"
        footer={
          <div className="row row-sm">
            {state.user.accessUser.report.delete && (
              <div className="col-auto">
                <button className="btn btn-danger   mr-1 delete-reports" onClick={() => deleteReport(editReportId)}>
                  Удалить
                </button>
              </div>
            )}

            <div className="col-auto">
              <button className="btn btn-primary   save-reports" onClick={() => editReport(editReportId)}>
                Сохранить
              </button>
            </div>
          </div>
        }
      >
        <div className="row">
          <div className="col-12 col-md-6">
            <Select
              label="Шаблон"
              value={editReportTemplateId}
              onChange={setEditReportTemplateId}
              values={reportTemplate?.models || []}
            />
          </div>

          <div className="col-12 col-md-6">
            <label className="form-field">
              <span className={`form-label`}>Период</span>

              <select
                value={editReportPeriodId}
                onChange={e => setEditReportPeriodId(e.target.value)}
                className="form-input _select"
                disabled={false}
              >
                {reportPeriod?.models.map(v => {
                  return (
                    <option key={v.id} value={v.id}>
                      {v.name} {v?.startDate && v?.endDate && '('}
                      {v?.startDate} {v?.startDate && v?.endDate && '-'} {v?.endDate}
                      {v?.startDate && v?.endDate && ')'}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
          <div className="col-12 col-md-6">
            <Input label="Название файла" value={fileName} onChange={e => setFileName(e)} />
          </div>
          <div className="col-12 col-md-6">
            <Input label="Текст ошибки" value={fileError} onChange={e => setFileError(e)} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Reports;
