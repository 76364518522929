/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { apiGet, apiPost } from '../../../../api/index';
import updateUrl from '../../../../hooks/updateUrl';
import { useFetch } from '../../../../hooks/useFetch';
import useUrl from '../../../../hooks/useUrl';
import { goToLink } from '../../../../utils/goToLink';
import { Input, Modal, Pagination } from '../../../components';
import Index from '../../../components/Spinner';
import { Gear, ProfileUser, TrashCan } from '../../../icons';
import './index.scss';

const Users = () => {
  const { page, search } = useUrl();
  const state = useSelector(state => state);
  const history = useHistory();
  const [viewModal, setViewModal] = useState(false);
  const [viewModalCreate, setViewModalCreate] = useState(false);
  const [sending, setSending] = useState(false);
  const [errObj, setErrObj] = useState({});

  const [NewUserName, setNewUserName] = useState('');
  const [NewUserEmail, setNewUserEmail] = useState('');
  const [newUserPhone, setNewUserPhone] = useState('');
  const [NewUserLastName, setNewUserLastName] = useState('');
  const [newUserUserName, setNewUserUserName] = useState('');
  const [NewUserPatronymic, setNewUserPatronymic] = useState('');

  const [editNameUser, setEditNameUser] = useState('');
  const [editEmailUser, setEditEmailUser] = useState('');
  const [editLastNameUser, setEditLastNameUser] = useState('');
  const [editUserName, setEditUserName] = useState('');
  const [editUserPhone, setEditUserPhone] = useState('');
  const [editPatronymicUser, setEditPatronymicUser] = useState('');
  const [editIdEditUser, setEditIdEditUser] = useState('');
  const [searchInput, setSearchInput] = useState(search);
  const [deleted, setDeleted] = useState(false);
  // const [userSearchName, setUserSearchName] = useState("")

  let url = `/user?page=${page || 1}&limit=10`;

  if (search) {
    url += `&q=${search}`;
  }

  if (deleted) {
    url += `&deleted=1`;
  }

  const { response, loading, fetchData } = useFetch({ url });

  const changeUserSearchName = v => {
    history.push(updateUrl({ key: 'page', value: '1' }));
    history.push(updateUrl({ key: 'search', value: v }));
    setSearchInput(v);
  };

  const openUserAcc = id => {
    if (!sending) {
      setSending(true);

      apiGet({ url: `/user/auth-as-user?id=${id}` })
        .then(res => {
          if (res.error) {
            console.error('Аккаунт пользователя');
            return;
          }
          window.open(`${process.env.REACT_APP_SITE_URL}?key=${res.data.access_token}`);
        })
        .finally(() => setSending(false));
    }
  };

  // const emptyField = () => {
  // 	confirmAlert({
  // 		message: "Пожалуйста заполните все поля",
  // 		buttons: [
  // 			{
  // 				label: "Ок",
  // 				onClick: () => {},
  // 			},
  // 		],
  // 	})
  // }

  const newPassAlert = id => {
    confirmAlert({
      title: 'Пароль',
      message: 'Вы точно хотите выслать новый пароль ?',
      buttons: [
        {
          label: 'Да',
          onClick: () => newPass(id),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  const createUser = () => {
    if (!sending) {
      setSending(true);

      const postData = {
        username: newUserUserName,
        email: NewUserEmail,
        phone: newUserPhone,
        lastName: NewUserLastName,
        name: NewUserName,
        patronymic: NewUserPatronymic,
      };

      apiPost({ url: `/user/create?`, postData })
        .then(res => {
          if (res.status === 406) {
            setErrObj(res.data);
            return;
          } else if (res.error) {
            console.error('Создание профиля');
            return;
          }

          setNewUserName('');
          setNewUserEmail('');
          setNewUserPhone('');
          setNewUserLastName('');
          setNewUserUserName('');
          setNewUserPatronymic('');

          toggleModalCreate(false);
          fetchData();
          setErrObj({});
        })
        .finally(() => setSending(false));
    }
  };

  const openEditUser = item => {
    setEditIdEditUser(item.id || '');
    setEditNameUser(item.name || '');
    setEditEmailUser(item.email || '');
    setEditLastNameUser(item.lastName || '');
    setEditUserName(item.username || '');
    setEditUserPhone(item.phone || '');
    setEditPatronymicUser(item.patronymic || '');
    setViewModal(true);
  };

  const editUser = id => {
    // if (
    // 	editNameUser === "" ||
    // 	editEmailUser === "" ||
    // 	editLastNameUser === "" ||
    // 	editPatronymicUser === ""
    // ) {
    // 	emptyField()
    // } else if
    if (!sending) {
      setSending(true);

      const postData = {
        email: editEmailUser,
        lastName: editLastNameUser,
        name: editNameUser,
        patronymic: editPatronymicUser,
        username: editUserName,
        phone: editUserPhone,
      };

      apiPost({ url: `/user/update?id=${id}`, postData })
        .then(res => {
          if (res.error) {
            setErrObj(res.data);
            console.error('Редактирование профиля');
            return;
          }
          setErrObj({});
          setViewModal(false);
          fetchData();
        })
        .finally(() => setSending(false));
    }
  };

  const deleteUserAlert = id => {
    confirmAlert({
      message: 'Вы точно хотите удалить пользователя?',
      buttons: [
        {
          label: 'Да',
          onClick: () => deleteUser(id),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  const deleteUser = id => {
    if (!sending) {
      setSending(true);

      apiGet({ url: `/user/delete?id=${id}` })
        .then(res => {
          if (res.error) {
            console.error('Удаление пользователя');
            return;
          }
          fetchData();
          setViewModal(false);
        })
        .finally(() => setSending(false));
    }
  };

  const newPass = id => {
    if (!sending) {
      setSending(true);

      apiGet({ url: `/user/generate-password?id=${id}` })
        .then(res => {
          if (res.error) {
            console.error('Новый пароль для пользователя');
            return;
          }

          fetchData();
          setViewModal(false);
        })
        .finally(() => setSending(false));
    }
  };

  const toggleModalCreate = isShow => {
    setViewModalCreate(isShow);
  };

  useEffect(() => {
    setErrObj({});
  }, [viewModal]);

  useEffect(() => {
    setErrObj({});
  }, [viewModalCreate]);

  return (
    <div className="users">
      <div className="card">
        <div className="card-header">Пользователи</div>
        <div className="card-content">
          <div className="row ai-center jc-between">
            <div className="col-12 col-md-4">
              {state.user.accessUser.user.create && (
                <button onClick={() => toggleModalCreate(true)} id="new-user" className="btn btn-primary btn-xs">
                  Создать нового пользователя
                </button>
              )}

              {deleted ? (
                <button onClick={() => setDeleted(false)} id="new-user" className="btn btn-success btn-xs ml-2">
                  Действующие
                </button>
              ) : (
                <button onClick={() => setDeleted(true)} id="new-user" className="btn btn-danger btn-xs ml-2">
                  Исключенные
                </button>
              )}
            </div>

            <div className="col-12 col-md-5 col-lg-4 col-xl-3">
              <Input fieldClasses="mb-0" placeholder="Поиск..." onChange={changeUserSearchName} value={searchInput} />
            </div>
          </div>

          {loading ? (
            <div className="my-5 d-flex jc-center">
              <Index size={80} />
            </div>
          ) : (
            <div className="scroll-x">
              <table className="mt-4 middle-table">
                <thead>
                  <tr>
                    <th>Username</th>
                    <th>ФИО</th>
                    <th>Почта</th>
                    <th>Название организации</th>
                    <th className="settings" />
                  </tr>
                </thead>
                <tbody>
                  {response?.models.map(item => (
                    <tr
                      style={{ cursor: 'pointer' }}
                      onClick={e => goToLink(e, () => history.push(`/view-user/${item.id}`))}
                      className={item.status === 10 ? 'go-link' : 'notActual'}
                      key={item.id}
                    >
                      <td>{item.username || ''}</td>
                      <td>
                        {item?.lastName || ' '} {item?.name?.slice(0, 1).toUpperCase().concat('. ') || ' '}{' '}
                        {item?.patronymic?.slice(0, 1).toUpperCase().concat('. ') || ''}
                      </td>

                      <td>{item.email || ''}</td>
                      <td style={{ maxWidth: '100px' }}>{item.organization?.name || ''}</td>
                      <td>
                        <div className="row row-sm jc-end">
                          {/* <div className="col-auto">
														<Link
															to={"/view-user/" + item.id}
															className="action-btn btn btn-primary"
														>
															<View />
														</Link>
													</div> */}

                          {!deleted && state.user.accessUser.user[`auth-as-user`] && item.organization && (
                            <div className="col-auto">
                              <button onClick={() => openUserAcc(item.id)} className="action-btn btn btn-primary">
                                <ProfileUser />
                              </button>
                            </div>
                          )}
                          {!deleted && state.user.accessUser.user.update && (
                            <div className="col-auto">
                              <button
                                className="action-btn btn btn-warning"
                                onClick={() => openEditUser(item)}
                                title="Изменить"
                              >
                                <Gear />
                              </button>
                            </div>
                          )}
                          {!deleted && state.user.accessUser.user[`set-active`] && (
                            <span>
                              <div className="col-auto">
                                <button
                                  className="action-btn btn btn-danger"
                                  onClick={() => deleteUserAlert(item.id)}
                                  title="Удалить"
                                >
                                  <TrashCan />
                                </button>
                              </div>
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <div className="mt-2 ta-center">
            <Pagination lastPage={response?.lastPage} page={page} />
            {/* <ReactPaginate
							pageCount={response?.lastPage == 1 ? 0 : response?.lastPage}
							pageRangeDisplayed={5}
							marginPagesDisplayed={3}
							onPageChange={e => setPage(e.selected + 1)}
							activeClassName={"active"}
							containerClassName={"pagination d-inline-flex ta-center"}
							pageLinkClassName={"link"}
							pageClassName={"link"}
							previousLabel={false}
							nextLabel={false}
						/> */}
          </div>
        </div>
      </div>

      <Modal
        show={viewModal}
        onClose={() => setViewModal(false)}
        modalTitle="Редактирование"
        footer={
          <div className="row row-sm jc-between">
            <div className="col-auto">
              {state.user.accessUser.user[`generate-password`] && (
                <button className="btn btn-primary edit-user-btn   mr-1" onClick={() => newPassAlert(editIdEditUser)}>
                  Отправить новый пароль
                </button>
              )}
            </div>

            <div className="col-auto">
              <div className="row row-sm">
                <div className="col-auto">
                  <button className="btn btn-danger  " onClick={() => deleteUserAlert(editIdEditUser)}>
                    Удалить
                  </button>
                </div>

                <div className="col-auto">
                  <button className="btn btn-success  " onClick={() => editUser(editIdEditUser)}>
                    Сохранить
                  </button>
                </div>
              </div>
            </div>
          </div>
        }
      >
        <div className="row">
          <div className="col-12 col-md-4">
            <Input label="Username" value={editUserName} onChange={setEditUserName} error={errObj.username} />
          </div>
          <div className="col-12 col-md-4">
            <Input label="Фамилия" value={editLastNameUser} onChange={setEditLastNameUser} error={errObj.lastName} />
          </div>
          <div className="col-12 col-md-4">
            <Input label="Имя" value={editNameUser} error={errObj.name} onChange={setEditNameUser} />
          </div>
          <div className="col-12 col-md-4">
            <Input
              label="Отчество"
              value={editPatronymicUser}
              onChange={setEditPatronymicUser}
              error={errObj.patronymic}
            />
          </div>
          <div className="col-12 col-md-4">
            <Input label="Почта" type="mail" value={editEmailUser} onChange={setEditEmailUser} error={errObj.email} />
          </div>
          <div className="col-12 col-md-4">
            <Input label="Телефон" type="tel" value={editUserPhone} onChange={setEditUserPhone} error={errObj.phone} />
          </div>
        </div>
      </Modal>

      <Modal
        show={viewModalCreate}
        onClose={() => toggleModalCreate(false)}
        modalTitle="Создание нового пользователя"
        footer={
          <button onClick={() => createUser()} className="btn btn-primary">
            Создать
          </button>
        }
      >
        <div className="row">
          <div className="col-12 col-md-4">
            <Input
              label="Username"
              type="number"
              onChange={setNewUserUserName}
              value={newUserUserName}
              error={errObj.username}
            />
          </div>

          <div className="col-12 col-md-4">
            <Input label="Фамилия" value={NewUserLastName} onChange={setNewUserLastName} error={errObj.lastName} />
          </div>

          <div className="col-12 col-md-4">
            <Input label="Имя" value={NewUserName} onChange={setNewUserName} error={errObj.name} />
          </div>

          <div className="col-12 col-md-4">
            <Input
              label="Отчество"
              value={NewUserPatronymic}
              onChange={setNewUserPatronymic}
              error={errObj.patronymic}
            />
          </div>

          <div className="col-12 col-md-4">
            <Input label="E-Mail" type="mail" value={NewUserEmail} onChange={setNewUserEmail} error={errObj.email} />
          </div>
          <div className="col-12 col-md-4">
            <Input label="Телефон" type="tel" value={newUserPhone} onChange={setNewUserPhone} error={errObj.phone} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Users;
