import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useUrl from '../../../../hooks/useUrl';
import { createUrlQuery } from '../../../../utils/createApiUrl';
import { apiGet, apiPost } from '../../../../api';
import { message } from 'antd';
import { confirmAlert } from 'react-confirm-alert';
import { downloadReport } from '../../../../utils/downloadReport';
import { modalApi } from '../../../../redux/actions/modal';
import { setProtocol } from '../../../../redux/actions/dk';
import { useQuery } from '@tanstack/react-query';
import { fetchProtocolList } from '../../../../api/dk/dk';

export const useProtocolPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { page } = useUrl();
  const history = useHistory();
  const protocolQueryList = useQuery(['protocol-list', page, id], () =>
    fetchProtocolList({
      dk_folder_id: id,
      page,
    })
  );

  const deleteProtocol = async id => {
    const { error } = await apiPost({ url: `/dk-protocol/delete${createUrlQuery({ id })}` });
    if (!error) {
      await protocolQueryList.refetch();
      message.success('Успешно удалено!');
    }
  };

  const handleConfirmDeleteProtocol = id => {
    confirmAlert({
      title: 'Удаление',
      message: 'Вы точно хотите удалить протокол?',
      buttons: [
        {
          label: 'Да',
          onClick: () => deleteProtocol(id),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  const downloadFile = id => {
    apiGet({ url: `/dk-protocol/download?id=${id}` }).then(res => {
      if (res.error) {
        console.error('Скачивание документа (шаблон)');
        return;
      }

      downloadReport(res.data, true);
    });
  };

  const handleProtocolUpdate = protocol => {
    dispatch(modalApi('update-protocol'));
    dispatch(setProtocol(protocol));
  };

  return {
    downloadFile,
    handleConfirmDeleteProtocol,
    protocolQueryList,
    handleProtocolUpdate,
    dispatch,
    page,
    id,
    history,
  };
};
