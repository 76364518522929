import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { apiGet, apiPost } from '../../../../api';
import { STATUS_CLASS_LIST } from '../../../../global';
import updateUrl from '../../../../hooks/updateUrl';
import { useFetch } from '../../../../hooks/useFetch';
import useUrl from '../../../../hooks/useUrl';
import { countNotReprimanded } from '../../../../redux/actions/user';
import hospital from '../../../../static/img/icons/hospital.svg';
import { goToLink } from '../../../../utils/goToLink';
import { Breadcrumbs, Dropzone, Input, Modal, Pagination, Select } from '../../../components';
import { Blogging, Plus, TrashCan } from '../../../icons';
import './index.scss';
import { useForm } from 'react-hook-form';

const OrganizationReports = () => {
  const history = useHistory();
  const { page, search, reportStatus, isNotReprimanded, limitExceeded } = useUrl();
  const { id } = useParams();
  const dispatch = useDispatch();
  const state = useSelector(state => state);

  const [sending, setSending] = useState(false);
  const [file, setFile] = useState([]);
  const [incomingNum, setIncomingNum] = useState('');
  const [orgId, setOrgId] = useState('');
  const [filterReport, setFilterReport] = useState(search);
  const [filterStatus, setFilterStatus] = useState(reportStatus);
  const [filterReprimanded, setFilterReprimanded] = useState(isNotReprimanded);
  const [filterLimitExceeded, setFilterLimitExceeded] = useState(limitExceeded);
  const [modalUpload, setModalUpload] = useState(false);
  const [modalMembershipPayment, setModalMembershipPayment] = useState(false);
  const [membershipPaymentOrgId, setMembershipPaymentOrgId] = useState(null);
  const [sorting, setSorting] = useState('-instanceUploadDate');
  const [checkOnChecking, setCheckOnChecking] = useState(false);
  const [errObj, setErrObj] = useState({});
  const [yearReportUpload, setYearReportUpload] = useState(new Date().getFullYear());
  const [membershipPaymentAmount, setMembershipPaymentAmount] = useState('');
  const [membershipPaymentAmountOffset, setMembershipPaymentAmountOffset] = useState('');
  const [membershipPaymentsList, setMembershipPaymentsList] = useState([]);
  const [membershipPaymentOrganizationId, setMembershipPaymentOrganizationId] = useState(null);
  const [membershipPaymentCustomModal, setMembershipPaymentCustomModal] = useState(false);
  const [membershipPaymentCustomData, setMembershipPaymentCustomData] = useState(null);
  const [mplModal, setMplModal] = useState(false);
  const [mplInputValue1, setMplInputValue1] = useState('');
  const [mplInputValue2, setMplInputValue2] = useState('');
  const [mplInputValue3, setMplInputValue3] = useState('');
  const [mplInputValue4, setMplInputValue4] = useState('');
  const [mplInputLabel1, setMplInputLabel1] = useState('');
  const [mplInputLabel2, setMplInputLabel2] = useState('');
  const [mplInputLabel3, setMplInputLabel3] = useState('');
  const [mplInputLabel4, setMplInputLabel4] = useState('');
  const [mplSettingsEnable, setMplSettingsEnable] = useState(null);

  const { watch, register, setValue } = useForm();

  let url = `/organization/report?report_id=${id}&page=${page || 1}&limit=10&sort=${sorting}`;

  if (filterReport) {
    url += `&q=${filterReport}`;
  }

  const updateFilterStatus = e => {
    history.push(updateUrl({ key: 'page', value: '1' }));
    setFilterStatus(e);
    history.push(updateUrl({ key: 'reportStatus', value: e }));
  };

  const updateFilterLimitExceeded = e => {
    history.push(updateUrl({ key: 'page', value: '1' }));
    setFilterLimitExceeded(e);
    history.push(updateUrl({ key: 'limitExceeded', value: e }));
  };

  const updateIsNotReprimanded = e => {
    history.push(updateUrl({ key: 'page', value: '1' }));
    setFilterReprimanded(e);
    history.push(updateUrl({ key: 'isNotReprimanded', value: e }));
  };

  if (filterStatus) {
    if (filterStatus === 0) {
      setFilterStatus('');
    } else url += `&reportStatus=${filterStatus}`;
  }

  if (filterLimitExceeded) {
    if (filterLimitExceeded === 0) {
      setFilterLimitExceeded('');
    } else url += `&limitExceeded=${filterLimitExceeded}`;
  }

  if (filterReprimanded) {
    url += `&isNotReprimanded=${filterReprimanded}`;
  }

  const { response, fetchData: restartPage } = useFetch({ url });

  const { response: viewReportData, fetchData } = useFetch({
    url: `/report/view?id=${id}`,
  });

  const getSortClass = sortType => {
    if (sorting.match(sortType)) {
      if (sorting === `-${sortType}`) {
        return '_desc';
      } else {
        return '_asc';
      }
    }

    return '';
  };

  const getDocAlert = () => {
    confirmAlert({
      message: 'Пожалуйста добавьте отчет',
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  const emptyIncomingNum = () => {
    confirmAlert({
      message: 'Пожалуйста введите входящий номер или установите отчет как отчет на проверку',
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  const sortTable = sortType => {
    if (sorting.match(sortType)) {
      if (sorting === `-${sortType}`) {
        setSorting(sortType);
      } else {
        setSorting(`-${sortType}`);
      }
    } else {
      setSorting(sortType);
    }
  };

  const openModalUpload = id => {
    setOrgId(id);
    setModalUpload(true);
  };

  const handleUploadFiles = e => {
    setFile(e);
  };

  const uploadDoc = () => {
    if (!checkOnChecking && incomingNum === '') {
      emptyIncomingNum();
      return;
    }
    if (checkOnChecking) {
      setIncomingNum('');
    }
    if (file == null) {
      getDocAlert();
      return;
    }

    if (!sending) {
      setSending(true);

      const postData = new FormData();
      !checkOnChecking && postData.append('incomingNumber', `ТР-${yearReportUpload}-${incomingNum}`);
      file.forEach(file => {
        postData.append('file', file);
      });

      apiPost({
        url: `/report-instance/upload?report_id=${id}&organization_id=${orgId}`,
        postData,
      })
        .then(res => {
          if (res.error) {
            console.error('Загрузка документа');
            setErrObj(res.data);
            return;
          }
          setErrObj({});
          setCheckOnChecking(false);
          setIncomingNum('');
          setFile([]);
          setModalUpload(false);
          fetchData();
          restartPage();

          apiGet({ url: `/report/not-reprimanded-count` }).then(res => {
            dispatch(countNotReprimanded(res.data));
          });
        })
        .finally(() => setSending(false));
    }
  };

  const filterSearch = e => {
    setFilterReport(e);
    history.push(updateUrl({ key: 'page', value: '1' }));
    history.push(updateUrl({ key: 'search', value: e }));
  };

  const linkRoute = [
    {
      name: 'Отчеты',
      link: '/reports',
    },
    {
      name: 'Отчет организаций',
      link: `/organization-reports/${id}`,
    },
  ];

  const funcIncomingNum = e => {
    setErrObj({});
    if (e.length >= 9) {
      setErrObj({ incNum: 'Максимальное количество цифр - 8' });
      return;
    }
    setIncomingNum(e);
  };

  useEffect(() => {
    setErrObj({});
  }, [file]);

  useEffect(() => {
    apiGet({ url: `/report/not-reprimanded-count` }).then(res => {
      dispatch(countNotReprimanded(res.data));
    });

    apiGet({ url: `/mpl?id=${id}` }).then(res => {
      if (res.error) {
        alert(res.data);
      } else {
        setMplInputValue1(res.data.condition1);
        setMplInputValue2(res.data.condition2);
        setMplInputValue3(res.data.condition3);
        setMplInputValue4(res.data.condition4);
        setMplSettingsEnable(res.data.isEnable);
      }
    });

    apiGet({ url: `/mpl/labels?id=${id}` }).then(res => {
      if (res.error) {
        alert(res.data);
      } else {
        setMplInputLabel1(res.data.condition1);
        setMplInputLabel2(res.data.condition2);
        setMplInputLabel3(res.data.condition3);
        setMplInputLabel4(res.data.condition4);
        setMplSettingsEnable(res.data.isEnable);
      }
    });
  }, []);

  const openModalMembershipPayment = id => {
    setMembershipPaymentOrgId(id);
    setModalMembershipPayment(true);
    actualizeMembershipPaymentsList(id);
  };

  const onchangeMembershipPaymentAmount = e => {
    setErrObj({});
    setMembershipPaymentAmount(e);
  };

  const onchangeMembershipPaymentAmountOffset = e => {
    setErrObj({});
    setMembershipPaymentAmountOffset(e);
  };

  const actualizeMembershipPaymentsList = id => {
    setMembershipPaymentOrganizationId(id);
    getMembershipPaymentCustomData(id);
    apiGet({ url: `/membership-payment?organization_id=${id}&report_id=${viewReportData.id}&limit=100` }).then(res => {
      if (res.error) {
        console.log('Ошибка при получении платежей ЧВ');
      } else {
        setMembershipPaymentsList(res.data.models);
      }
    });
  };

  const membershipPaymentUpdate = () => {
    const postData = new FormData();

    if (membershipPaymentAmount) {
      postData.append('amount', membershipPaymentAmount);
    }

    if (membershipPaymentAmountOffset) {
      postData.append('amountOffset', membershipPaymentAmountOffset);
    }

    apiPost({
      url: `/membership-payment/set?organization_id=${membershipPaymentOrgId}&report_id=${viewReportData.id}`,
      postData,
    }).then(res => {
      if (res.error) {
        setErrObj(res.data);
        return;
      }
      setErrObj({});

      setMembershipPaymentAmount('');
      setMembershipPaymentAmountOffset('');
      actualizeMembershipPaymentsList(membershipPaymentOrgId);
      restartPage();

      apiGet({ url: `/report/not-reprimanded-count` }).then(res => {
        dispatch(countNotReprimanded(res.data));
      });
    });
  };

  const deleteMembershipPaymentAlert = id => {
    confirmAlert({
      message: 'Вы точно хотите удалить платеж?',
      buttons: [
        {
          label: 'Да',
          onClick: () => deleteMembershipPayment(id),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  const deleteMembershipPayment = id => {
    apiPost({ url: `/membership-payment/delete?id=${id}` });
    actualizeMembershipPaymentsList(membershipPaymentOrganizationId);
    restartPage();
  };

  const closeMembershipPaymentModal = () => {
    setMembershipPaymentOrganizationId(null);
    setModalMembershipPayment(false);
    setMembershipPaymentsList([]);
    setMembershipPaymentAmount('');
    setMembershipPaymentAmountOffset('');
    setValue('membershipPaymentCustomInputValue', '');
  };

  const getMembershipPaymentCustomData = organizationId => {
    apiGet({
      url: `/membership-payment/get-custom?organization_id=${organizationId}&report_id=${id}`,
    }).then(res => {
      if (res.error) {
        alert(res.data);
      }

      setMembershipPaymentCustomData(res.data);
    });
  };

  const saveMembershipPaymentCustom = () => {
    const postData = new FormData();

    postData.append('amount', watch('membershipPaymentCustomInputValue'));

    apiPost({
      url: `/membership-payment/set-custom?organization_id=${membershipPaymentOrganizationId}&report_id=${id}`,
      postData,
    }).then(res => {
      if (res.error) {
        setErrObj(res.data);
      } else {
        setMembershipPaymentCustomModal(false);
        closeMembershipPaymentModal();
        setValue('membershipPaymentCustomInputValue', '');
        restartPage();
      }
    });
  };

  const deleteMembershipPaymentCustomModal = () => {
    apiPost({
      url: `/membership-payment/delete-custom?organization_id=${membershipPaymentOrganizationId}&report_id=${id}`,
    });

    setMembershipPaymentCustomModal(false);
    closeMembershipPaymentModal();
    restartPage();
  };

  const setBlockUpload = () => {
    apiPost({
      url: `/report/set-block-upload?id=${id}`,
    }).then(res => {
      if (res.error) {
        alert(res.data);
      } else {
        fetchData();
      }
    });
  };

  const unsetBlockUpload = () => {
    apiPost({
      url: `/report/unset-block-upload?id=${id}`,
    }).then(res => {
      if (res.error) {
        alert(res.data);
      } else {
        fetchData();
      }
    });
  };

  const saveMpl = () => {
    apiPost({
      url: `/mpl/update?id=${id}`,
      postData: {
        condition1: mplInputValue1,
        condition2: mplInputValue2,
        condition3: mplInputValue3,
        condition4: mplInputValue4,
      },
    }).then(res => {
      if (res.error) {
        setErrObj(res.data);
      } else {
        setMplInputValue1(res.data.condition1);
        setMplInputValue2(res.data.condition2);
        setMplInputValue3(res.data.condition3);
        setMplInputValue4(res.data.condition4);
        setMplSettingsEnable(res.data.isEnable);
      }
    });
  };

  const deleteMpl = () => {
    apiPost({
      url: `/mpl/delete?id=${id}`,
    }).then(res => {
      if (res.error) {
        alert(res.data);
      } else {
        setMplInputValue1('');
        setMplInputValue2('');
        setMplInputValue3('');
        setMplInputValue4('');
        setMplSettingsEnable(false);
      }
    });
  };

  return (
    <>
      <Breadcrumbs linkRoute={linkRoute} />
      <div className="card organization-reports">
        <div className="card-header d-flex jc-between">
          <div className="name">Список всех организаций использующих этот отчет</div>
          <div>
            {viewReportData?.limitLogicEnable && (
              <button onClick={() => setMplModal(true)} className="btn btn-primary mr-2">
                Установить МПЛ
              </button>
            )}
            {state.user.accessUser[`summary-report`].index && viewReportData?.membershipPaymentsEnable && (
              <Link
                to={`/membership-payments-reports/${id}/${viewReportData?.template?.id}`}
                className="btn btn-primary mr-2"
              >
                Отчеты по ЧВ
              </Link>
            )}
            {state.user.accessUser.trigger.index && (
              <Link to={`/report-triggers/${id}`} className="btn btn-success mr-2">
                Триггеры отчета
              </Link>
            )}
            {state.user.accessUser[`summary-report`].index && (
              <Link to={`/summary-reports/${id}/${viewReportData?.template?.id}`} className="btn btn-primary mr-2">
                Сводные отчеты
              </Link>
            )}
            {state.user.accessUser[`report`]['set-block-upload'] && viewReportData && !viewReportData.isBlockUpload && (
              <button onClick={setBlockUpload} className="btn btn-danger">
                Запретить загрузку отчетов
              </button>
            )}
            {state.user.accessUser[`report`]['unset-block-upload'] && viewReportData && viewReportData.isBlockUpload && (
              <button onClick={unsetBlockUpload} className="btn btn-success">
                Разрешить загрузку отчетов
              </button>
            )}
          </div>
        </div>
        <div className="card-content">
          <div className="row">
            <div className="col-12 col-md-6 name-event">
              <Input
                label="Название"
                disabled
                defaultValue={viewReportData?.template?.name || ''}
                onChange={() => {}}
              />
            </div>
            <div className="col-12 col-md-6">
              <Input
                label="Период"
                disabled
                value={`${viewReportData?.period?.startDate} - ${viewReportData?.period?.endDate}`}
              />
            </div>
            <div className="col-12 col-md-4">
              <Input label="Поиск" value={filterReport} onChange={e => filterSearch(e)} />
            </div>
            <div className="col-12 col-md-4">
              <Select
                label="Выбор статуса"
                value={filterStatus}
                onChange={e => updateFilterStatus(e)}
                values={[
                  { id: '', name: 'Выберите статус' },
                  { id: 1, name: 'Не загружался' },
                  { id: 2, name: 'На проверке' },
                  { id: 3, name: 'Принят с ошибками' },
                  { id: 4, name: 'Не удалось проверить на триггеры' },
                  { id: 5, name: 'Принят без ошибок' },
                  { id: 6, name: 'Не принят БР' },
                ]}
              />
            </div>
            <div className="col-12 col-md-4">
              <Select
                label="Не выгруженные"
                value={filterReprimanded}
                onChange={e => updateIsNotReprimanded(e)}
                values={[
                  { id: '', name: 'Все отчёты' },
                  {
                    id: true,
                    name: 'Не выгруженные',
                  },
                ]}
              />
            </div>
            {viewReportData?.limitLogicEnable ? (
              <div className="col-12 col-md-4">
                <Select
                  label="Превышение лимита"
                  value={filterLimitExceeded}
                  onChange={e => updateFilterLimitExceeded(e)}
                  values={[
                    { id: '', name: 'Выберите статус лимита' },
                    { id: 1, name: 'Лимит превышен' },
                    { id: 2, name: 'Лимит не превышен' },
                  ]}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="scroll-x">
            <table className="mt-3 middle-table">
              <thead>
                <tr>
                  <th>Username</th>
                  <th>Название</th>
                  <th>Статус</th>
                  <th
                    onClick={() => sortTable('instanceUploadDate')}
                    style={{
                      maxWidth: 240,
                      display: 'flex',
                      cursor: 'pointer',
                    }}
                  >
                    <span>Дата последней загрузки</span>
                    <div className={`sort ${getSortClass('instanceUploadDate')}`} />
                  </th>
                  <th>Не выгруженные</th>
                  {viewReportData?.membershipPaymentsEnable ? <th>Сформированная сумма ЧВ</th> : <></>}
                  {viewReportData?.membershipPaymentsEnable ? <th>Оплаченная сумма ЧВ</th> : <></>}
                  {viewReportData?.limitLogicEnable ? <th>Превышение лимита</th> : <></>}
                  <th />
                </tr>
              </thead>

              <tbody>
                {response?.models.map(item => (
                  <tr
                    key={item.id}
                    className={'go-link'}
                    style={{ cursor: 'pointer' }}
                    onClick={e => goToLink(e, () => history.push(`/view-organization-reports/${item.user_id}/${id}`))}
                  >
                    <td>{item.ogrn}</td>
                    <td>{item.name}</td>
                    <td>
                      <div className={`badge badge-${STATUS_CLASS_LIST[item.status]}`}>{item.statusText}</div>
                    </td>

                    <td>{item.uploadTime || '-'}</td>
                    <td>
                      {item.isNotReprimanded && (
                        <div
                          style={{
                            borderRadius: '100%',
                            backgroundColor: '#5b73e8',
                            display: 'inline-flex',
                            padding: '5px',
                          }}
                        >
                          <img style={{ width: '12px' }} src={hospital} />
                        </div>
                      )}
                    </td>
                    {viewReportData?.membershipPaymentsEnable ? <td>{item.membershipPaymentCalc}</td> : <></>}
                    {viewReportData?.membershipPaymentsEnable ? <td>{item.membershipPaymentFact}</td> : <></>}
                    {viewReportData?.limitLogicEnable ? (
                      <td>
                        {item.limitExceeded ? (
                          <div className={'badge badge-red'}>Лимит превышен</div>
                        ) : item.limitExceeded !== null ? (
                          <div className={'badge badge-green'}>Лимит не превышен</div>
                        ) : (
                          <></>
                        )}
                      </td>
                    ) : (
                      <></>
                    )}
                    <td style={{ width: '200px' }}>
                      <div className="row row-sm jc-end">
                        {/* <div className="col-auto">
													<Link
														to={`/view-organization-reports/${item.user_id}/${id}`}
														className="action-btn btn btn-primary"
													>
														<View />
													</Link>
												</div> */}
                        {state.user.accessUser[`report-instance`].upload && (
                          <div className="col-auto">
                            <button
                              className="action-btn btn btn-success"
                              onClick={() => openModalUpload(item.id)}
                              title="Загрузить отчет"
                            >
                              <Plus />
                            </button>
                          </div>
                        )}
                        {state.user.accessUser[`membership-payment`].set && viewReportData?.membershipPaymentsEnable && (
                          <div className="col-auto">
                            <button
                              className="action-btn btn btn-primary"
                              onClick={() => openModalMembershipPayment(item.id)}
                              title="Указать оплаченную сумму"
                            >
                              <Blogging />
                            </button>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-2 ta-center">
            <Pagination lastPage={response?.lastPage} page={page} />
          </div>
        </div>

        <Modal
          show={modalUpload}
          onClose={() => setModalUpload(false)}
          modalTitle="Загрузка отчета"
          footer={
            <button className="btn btn-success" onClick={() => uploadDoc()}>
              Загрузить
            </button>
          }
        >
          <div className="col-auto mb-3" style={{ display: 'inline-block' }}>
            <label className="d-flex">
              <input
                type="checkbox"
                className="checkbox mr-2"
                checked={checkOnChecking}
                onChange={() => setCheckOnChecking(!checkOnChecking)}
              />
              <span>Отправка отчета на проверку</span>
            </label>
          </div>
          {!checkOnChecking && (
            <>
              f
              <div className="d-flex">
                <span className="mt-auto mb-auto">ТР-</span>
                <Select
                  fieldClasses="mt-auto mb-auto"
                  style={{ minWidth: '75px' }}
                  onChange={e => setYearReportUpload(e)}
                  value={yearReportUpload}
                  values={[
                    {
                      value: new Date().getFullYear() - 1,
                      name: new Date().getFullYear() - 1,
                    },
                    {
                      value: new Date().getFullYear(),
                      name: new Date().getFullYear(),
                    },
                    {
                      value: new Date().getFullYear() + 1,
                      name: new Date().getFullYear() + 1,
                    },
                  ]}
                />
                <span className="mt-auto mb-auto">-</span>
                <Input
                  fieldClasses="mt-auto mb-auto"
                  value={incomingNum}
                  onChange={funcIncomingNum}
                  errObj={errObj.incNum}
                />
              </div>
              {errObj.incNum && <span className="_error">{errObj.incNum}</span>}
            </>
          )}

          <div className="mt-3">
            <Dropzone
              maxSize={1000000000}
              onFileRemove={() => setFile([])}
              onFileUpload={handleUploadFiles}
              styles={{ height: 120 }}
              files={file}
              error={errObj.file}
            />
            {errObj.file &&
              errObj.file.map(i => (
                <span key={i} className="_error">
                  {i}
                </span>
              ))}
          </div>
        </Modal>

        <Modal
          show={modalMembershipPayment}
          onClose={() => closeMembershipPaymentModal()}
          modalTitle="Оплаченная сумма ЧВ"
          footer={
            <button className="btn btn-success" onClick={() => membershipPaymentUpdate()}>
              Сохранить
            </button>
          }
        >
          <table className="mt-3 middle-table">
            <thead>
              <tr>
                <th>Сумма</th>
                <th>Время</th>
                <th>ID пользователя</th>
                <th>Тип</th>
              </tr>
            </thead>

            <tbody>
              {membershipPaymentsList.map(item => (
                <tr key={item.id}>
                  <td>{item.amount}</td>
                  <td>{item.createdAt}</td>
                  <td>{item.created_by}</td>
                  <td>{item.typeText}</td>
                  <td>
                    {state.user.accessUser[`membership-payment`].delete && (
                      <div className="col-auto">
                        <button
                          className="action-btn btn btn-danger"
                          onClick={() => deleteMembershipPaymentAlert(item.id)}
                          title="Удалить"
                        >
                          <TrashCan />
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className={'mt-4'}>
            <button className="btn btn-primary" onClick={() => setMembershipPaymentCustomModal(true)}>
              Установить произвольную сумму ЧВ
            </button>

            <button className="btn btn-danger ml-2" onClick={() => deleteMembershipPaymentCustomModal()}>
              Удалить
            </button>
          </div>

          <div className="d-flex">
            <Input
              label="Основной"
              fieldClasses="mt-auto mb-auto mt-6"
              value={membershipPaymentAmount}
              onChange={onchangeMembershipPaymentAmount}
              error={errObj.amount}
            />

            <Input
              label="Зачет"
              fieldClasses="mt-auto mb-auto mt-6 ml-3"
              value={membershipPaymentAmountOffset}
              onChange={onchangeMembershipPaymentAmountOffset}
              error={errObj.amount}
            />
          </div>
        </Modal>

        <Modal
          show={membershipPaymentCustomModal}
          onClose={() => setMembershipPaymentCustomModal(false)}
          modalTitle="Сформированная сумма ЧВ"
          footer={
            <button className="btn btn-success" onClick={() => saveMembershipPaymentCustom()}>
              Сохранить
            </button>
          }
        >
          <div>Сформированная сумма ЧВ: {membershipPaymentCustomData?.amount}</div>

          <div className="d-flex">
            <Input
              label="Сформированная сумма ЧВ"
              fieldClasses="mt-auto mb-auto mt-6"
              name={'membershipPaymentCustomInputValue'}
              error={errObj.amount}
              register={register}
            />
          </div>
        </Modal>

        <Modal
          show={mplModal}
          onClose={() => setMplModal(false)}
          modalTitle="Плановые значения МПЛ"
          footer={
            <div>
              <button className="btn btn-success" onClick={() => saveMpl()}>
                Сохранить
              </button>
              {mplSettingsEnable && (
                <button className="btn btn-danger ml-2" onClick={() => deleteMpl()}>
                  Удалить настройки
                </button>
              )}
            </div>
          }
        >
          <span className={'mb-2'}>Если условие является ошибочным, оставьте соответствующее поле пустым</span>

          {mplSettingsEnable ? (
            <div className={'badge badge-green mb-5'}>Активировано</div>
          ) : (
            <div className={'badge badge-red mb-5'}>Отключено</div>
          )}

          <div className="d-flex">
            <Input
              label={mplInputLabel1}
              fieldClasses=""
              name={'mplCondition1'}
              onChange={setMplInputValue1}
              value={mplInputValue1}
              error={errObj.condition1}
              register={register}
            />

            <Input
              label={mplInputLabel2}
              fieldClasses="ml-2"
              name={'mplCondition2'}
              onChange={setMplInputValue2}
              value={mplInputValue2}
              error={errObj.condition2}
              register={register}
            />

            <Input
              label={mplInputLabel3}
              fieldClasses="ml-2"
              name={'mplCondition3'}
              onChange={setMplInputValue3}
              value={mplInputValue3}
              error={errObj.condition3}
              register={register}
            />

            <Input
              label={mplInputLabel4}
              fieldClasses="ml-2"
              name={'mplCondition4'}
              onChange={setMplInputValue4}
              value={mplInputValue4}
              error={errObj.condition4}
              register={register}
            />
          </div>
        </Modal>
      </div>
    </>
  );
};

export default OrganizationReports;
