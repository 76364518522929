import React, { useState } from 'react';
import { Modal } from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { modalApi } from '../../../../../redux/actions/modal';
import { CustomButton } from '../../../../components/Button/Button';
import { message } from 'antd';
import { apiPost } from '../../../../../api';
import PropTypes from 'prop-types';
import { createUrlQuery } from '../../../../../utils/createApiUrl';
import { useOrganization } from '../../../../../hooks/api/useOrganization';
import { useParams } from 'react-router-dom';
import Index from '../../../../components/Spinner';
import { SelectMultiple } from '../../../../components/MultipleSelect/MultipleSelect';
import './ProtocolAddOrganizationModal.css';

// eslint-disable-next-line react/prop-types
export const ProtocolAddOrganizationModal = ({ fetchData }) => {
  const [organizationId, setOrganizationId] = useState(null);
  const dispatch = useDispatch();
  const { id } = useParams();
  const isVisible = !!useSelector(s => s.modal.name === 'protocol-add-organization');
  const { selectOrganizationOptions, loading } = useOrganization({ enabled: isVisible });

  const hideModal = () => {
    dispatch(modalApi(null));
    // clearErrors();
    setOrganizationId('');
  };

  const addOrganization = async () => {
    const { error } = await apiPost({
      url: `/dk-protocol/add-organization${createUrlQuery({ id, organizationId })}`,
    });

    if (!error) {
      message.success('Успешно добавлено!');
      await fetchData();
      hideModal();
    }
  };

  return (
    <Modal
      windowScroll={false}
      modalTitle="Добавить организацию"
      footer={
        <CustomButton disabled={!organizationId} onClick={addOrganization}>
          Добавить
        </CustomButton>
      }
      show={isVisible}
      onClose={hideModal}
    >
      {loading ? (
        <div className="w-full justify-center flex">
          <Index size={60} />
        </div>
      ) : (
        <SelectMultiple
          value={organizationId}
          mode=""
          values={selectOrganizationOptions}
          label="Организация"
          fieldClasses="!mb-0 !p-0 protocol-add-organization-select"
          onChange={id => setOrganizationId(id)}
        />
      )}
    </Modal>
  );
};

ProtocolAddOrganizationModal.protoTypes = {
  fetchData: PropTypes.any,
};
