import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { apiGet, apiPost } from '../../../../api/index';
import { CURRENT_YEAR, STATUS_CLASS_LIST, YEARS } from '../../../../global';
import { useFetch } from '../../../../hooks/useFetch';
import { countNotReprimanded } from '../../../../redux/actions/user';
import { downloadByLink } from '../../../../utils/downloadByLink';
import { Breadcrumbs, Dropzone, Input, Modal, Select, Textarea } from '../../../components';
import { Approval, Blogging, Download, Information, Refresh, WarningSign } from '../../../icons/index';
import BadgeTab from './badgeTab';
import './index.scss';
import dayjs from 'dayjs';
import ControlBtn from './ControlBtn';

const ViewOrganizationReports = () => {
  const state = useSelector(state => state);

  const dispatch = useDispatch();

  const { id, idReport } = useParams();
  const [page, setPage] = useState(1);
  const [sending, setSending] = useState(false);
  const [viewNote, setViewNote] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [ErrorModal, setErrorModal] = useState(false);
  const [noteId, setNoteId] = useState();
  const [noteData, setNoteData] = useState('');
  const [triggerWarning, setTriggerWarning] = useState('');
  const [triggerError, setTriggerError] = useState();
  const [sorting, setSorting] = useState('-createdDate');
  const [modalUpload, setModalUpload] = useState(false);
  const [incomingNum, setIncomingNum] = useState('');
  const [incomingDate, setIncomingDate] = useState('');
  const [file, setFile] = useState([]);
  const [checkOnChecking, setCheckOnChecking] = useState(false);
  const [detailTriggersModal, setDetailTriggersModal] = useState(false);
  const [detailTriggers, setDetailTriggers] = useState(false);
  const [errObj, setErrObj] = useState({});
  const [yearReportUpload, setYearReportUpload] = useState(CURRENT_YEAR);

  // Report instance modals
  const [addReportInstanceModalVisible, setAddReportInstanceModalVisible] = useState(false);
  const [addReportInstanceModalId, setAddReportInstanceModalId] = useState(null);
  const [addReportInstanceModalValue, setAddReportInstanceModalValue] = useState('');
  const [addReportInstanceModalYear, setAddReportInstanceModalYear] = useState(CURRENT_YEAR);

  const [editReportInstanceModalVisible, setEditReportInstanceModalVisible] = useState(false);
  const [editReportInstanceModalId, setEditReportInstanceModalId] = useState(null);
  const [editReportInstanceModalValue, setEditReportInstanceModalValue] = useState('');
  const [editReportInstanceModalYear, setEditReportInstanceModalYear] = useState(CURRENT_YEAR);

  const { response } = useFetch({
    url: `/organization/view-by-user?user_id=${id}`,
  });

  const url = response
    ? `/report-instance?organization_id=${response?.id}&report_id=${idReport}&page=${page}&limit=10&sort=${sorting}`
    : null;
  const { response: organizationReports, fetchData } = useFetch({ url });

  const { response: getReportData } = useFetch({
    url: `/report/view?id=${idReport}`,
  });

  const getSortClass = sortType => {
    if (sorting.match(sortType)) {
      if (sorting === `-${sortType}`) {
        return '_desc';
      } else {
        return '_asc';
      }
    }

    return '';
  };

  const uploadDocAlert = () => {
    confirmAlert({
      message: 'Пожалуйста добавьте отчет',
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  const updateActualAlert = id => {
    confirmAlert({
      message: 'Вы точно хотите сделать этот экземпляр актуальным?',
      buttons: [
        {
          label: 'Да',
          onClick: () => updateActual(id),
        },
        {
          label: 'Нет',
          onClick: () => {},
        },
      ],
    });
  };

  const textReportAlert = text => {
    confirmAlert({
      message: text,
      buttons: [
        {
          label: 'Ок',
          onClick: e => e,
        },
      ],
    });
  };

  const sendingRefreshTriggersAlert = () => {
    confirmAlert({
      message: 'Происходит проверка на триггеры, подождите...',
      buttons: [
        {
          label: 'Ok',
          onClick: () => {},
        },
      ],
    });
  };

  const refreshTrigger = id => {
    if (!sending) {
      setSending(true);
      sendingRefreshTriggersAlert();

      apiGet({ url: `/report-instance/check?id=${id}`, timeout: 300000 })
        .then(res => {
          if (res.error) {
            textReportAlert('Ошибка');
            return;
          }

          textReportAlert('Проверка на триггеры завершена.');

          fetchData();
        })
        .finally(() => setSending(false));
    }
  };

  const sortTable = sortType => {
    if (sorting.match(sortType)) {
      if (sorting === `-${sortType}`) {
        setSorting(sortType);
      } else {
        setSorting(`-${sortType}`);
      }
    } else {
      setSorting(sortType);
    }
  };

  const showWarning = isShow => {
    setWarningModal(isShow);
  };
  const showError = isShow => {
    setErrorModal(isShow);
  };

  const showNote = isShow => {
    setViewNote(isShow);
  };

  //Установить отчет актуальным
  const updateActual = id => {
    if (!sending) {
      setSending(true);

      apiGet({ url: `/report-instance/set-actual?id=${id}` })
        .then(res => {
          if (res.error) {
            console.error('Установка актуального экземпляра отчета');
            return;
          }

          fetchData();
        })
        .finally(() => setSending(false));
    }
  };

  const emptyIncomingNum = () => {
    confirmAlert({
      message: 'Пожалуйста введите входящий номер или установите отчет как отчет на проверку',
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  const uploadDoc = () => {
    if (!checkOnChecking && incomingNum === '') {
      emptyIncomingNum();
      return;
    }
    if (checkOnChecking) {
      setIncomingNum('');
    }
    if (file == null) {
      uploadDocAlert();
      return;
    }

    if (!sending) {
      setSending(true);

      const postData = new FormData();
      !checkOnChecking && postData.append('incomingNumber', `ТР-${yearReportUpload}-${incomingNum}`);
      postData.append('incomingNumberDate', incomingDate);

      file.forEach(file => {
        postData.append('file', file);
      });

      apiPost({
        url: `/report-instance/upload?report_id=${idReport}&organization_id=${response.id}`,
        postData,
      })
        .then(res => {
          if (res.error) {
            console.error('Загрузка документа');
            setErrObj(res.data);
            return;
          }
          setErrObj({});
          setIncomingNum('');
          setCheckOnChecking(false);
          setFile([]);
          setModalUpload(false);
          fetchData();

          apiGet({ url: '/report/not-reprimanded-count' }).then(res => {
            dispatch(countNotReprimanded(res.data));
          });
        })
        .finally(() => setSending(false));
    }
  };

  const funcIncomingNum = e => {
    setErrObj({});
    if (e.length >= 9) {
      setErrObj({ incNum: 'Максимальное количество цифр - 8' });
      return;
    }
    setIncomingNum(e);
  };

  //Скачать файлы
  const downloadFile = id => {
    if (!sending) {
      setSending(true);

      // apiGet({ url: `/report-instance/download?id=${id}` })
      //   .then(res => {
      //     if (res.error) {
      //       console.error('Скачивание документа (шаблон)');
      //       return;
      //     }
      //
      //     downloadBase64File(res.data.asBase64, res.data.name);
      //   })
      //   .finally(() => setSending(false));

      apiGet({ url: `/report-instance/download-by-link?id=${id}` })
        .then(res => {
          downloadByLink(res.data.link, res.data.name);
        })
        .finally(() => setSending(false));
    }
  };

  // Сработал триггера
  const viewTriggerWarning = id => {
    if (!sending) {
      setSending(true);

      apiGet({ url: `/report-instance/triggered?id=${id}` })
        .then(res => {
          if (res.error) {
            console.error('Сработал триггер');
            return;
          }

          setTriggerWarning(res.data);
          showWarning(true);
        })
        .finally(() => setSending(false));
    }
  };

  //Ошибки триггера
  const viewTriggerError = id => {
    if (!sending) {
      setSending(true);

      apiGet({ url: `/report-instance/problem?id=${id}` })
        .then(res => {
          if (res.error) {
            console.error('Ошибки тригера');
            return;
          }

          setTriggerError(res.data);
          showError(true);
        })
        .finally(() => setSending(false));
    }
  };

  //Просмотр html контента триггеров отчета принятных или принятных с ошибками
  const viewReportDetailTriggers = id => {
    if (!sending) {
      setSending(true);

      apiGet({ url: `/report-instance/eval?id=${id}` })
        .then(res => {
          if (res.error) {
            console.error('Ошибки получения детализации триггера');
            return;
          }
          setDetailTriggers(res.data);
          setDetailTriggersModal(true);
        })
        .finally(() => setSending(false));
    }
  };

  //Создание коментария
  const openNoteMod = (id, note) => {
    if (note !== null) {
      setNoteData(note);
    }
    if (note == null) {
      setNoteData('');
    }
    setNoteId(id);
    showNote(true);
  };

  const editNoteSave = () => {
    if (!sending) {
      setSending(true);

      const postData = {
        note: noteData,
      };

      apiPost({ url: `/report-instance/note?id=${noteId}`, postData })
        .then(res => {
          if (res.error) {
            console.error('Редактирование записи');
            return;
          }

          fetchData();
          showNote(false);
        })
        .finally(() => setSending(false));
    }
  };

  const handleUploadFiles = e => {
    setFile(e);
  };

  const checked = check => {
    switch (check) {
      case false:
        return false;
      case true:
        return 1;
    }
  };

  const linkRoute = [
    {
      name: 'Отчеты',
      link: '/reports',
    },
    {
      name: 'Отчеты организации',
      link: `/organization-reports/${idReport}`,
    },
    {
      name: 'Загруженные отчеты организации',
      link: `/view-organization-reports/${id}/${idReport}`,
    },
  ];

  const openModalUpload = () => {
    setModalUpload(true);
  };

  const updateAllRepr = () => {
    if (!sending) {
      setSending(true);

      apiGet({
        url: `/organization/set-as-reprimanded?id=${response.id}&report_id=${idReport}`,
      })
        .then(() => {
          fetchData();
          apiGet({ url: '/report/not-reprimanded-count' }).then(res => {
            dispatch(countNotReprimanded(res.data));
          });
        })
        .finally(() => setSending(false));
    }
  };

  const editIncomingNumberFns = async () => {
    const res = await apiPost({
      url: `/report-instance/update?id=${editReportInstanceModalId}`,
      postData: {
        incomingNumber: editReportInstanceModalValue.length
          ? `ТР-${editReportInstanceModalYear}-${editReportInstanceModalValue}`
          : '',
        incomingNumberDate: incomingDate || '',
      },
    });

    if (res.error) return;

    await fetchData();
    setEditReportInstanceModalVisible(false);
  };

  const addIncomingNumberFns = async () => {
    const res = await apiPost({
      url: `/report-instance/update?id=${addReportInstanceModalId}`,
      postData: {
        incomingNumber: addReportInstanceModalValue.length
          ? `ТР-${addReportInstanceModalYear}-${addReportInstanceModalValue}`
          : '',
        incomingNumberDate: incomingDate || '',
      },
    });

    if (res.error) return;

    await fetchData();
    setAddReportInstanceModalVisible(false);
  };

  const modalFuncIncomingNum = (type, value) => {
    setErrObj({});
    if (value.length >= 9) {
      setErrObj({ incNum: 'Максимальное количество цифр - 8' });
      return;
    }

    if (type === 'add') {
      setAddReportInstanceModalValue(value);
    } else if (type === 'edit') {
      setEditReportInstanceModalValue(value);
    }
  };

  const makeReportRejected = async id => {
    const res = await apiPost({
      url: `/report-instance/set-as-rejected?id=${id}`,
    });

    if (res.error) {
      return false;
    }

    await fetchData();
  };

  useEffect(() => {
    setErrObj({});
  }, [file]);

  return (
    <div className="view-organization-reports">
      <Breadcrumbs linkRoute={linkRoute} />

      <div className="card">
        <div className="card-header d-flex jc-between">
          <div className="name">Загруженные отчеты</div>
          <div className="row">
            <div className="col-auto">
              <button className="btn ml-auto btn-warning" onClick={updateAllRepr}>
                Установить все отчеты как выгруженные
              </button>
            </div>
            {state.user.accessUser['report-instance'].upload && (
              <div className="col-auto">
                <button className="btn ml-auto btn-success" onClick={openModalUpload}>
                  Загрузить отчет
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="card-content">
          <div className="row">
            <div className="col-12 col-md-4">
              <Input label="Username" disabled value={response?.username} onChange={() => {}} />
            </div>
            <div className="col-12 col-md-4">
              <Input label="Название организации" disabled defaultValue={response?.name} onChange={() => {}} />
            </div>
            <div className="col-12 col-md-4">
              <Input label="ИНН" disabled defaultValue={response?.inn} onChange={() => {}} />
            </div>
            <div className="col-12 col-md-4">
              <Input label="ОГРН" disabled defaultValue={response?.ogrn} onChange={() => {}} />
            </div>
            <div className="col-12 col-md-4">
              <Input label="Название отчета" disabled defaultValue={getReportData?.template?.name || ''} />
            </div>
            <div className="col-12 col-md-4">
              <Input
                label="Период отчета"
                disabled
                value={`${getReportData?.period?.startDate} - ${getReportData?.period?.endDate}`}
              />
            </div>
          </div>

          <div className="scroll-x">
            <table className="mt-3 middle-table">
              <thead>
                <tr>
                  <th>#</th>
                  {organizationReports?.models.length !== 1 && <th>Актуал.</th>}

                  <th onClick={() => sortTable('createdDate')} style={{ cursor: 'pointer' }}>
                    <div className="d-flex">
                      <span>Дата загрузки</span>
                      <div className={`sort ${getSortClass('createdDate')}`} />
                    </div>
                  </th>

                  {getReportData?.membershipPaymentsEnable ? <th>Размер ЧВ</th> : <></>}

                  <th className={'d-flex'} style={{ cursor: 'pointer' }} onClick={() => sortTable('status')}>
                    <span>Статус</span>
                    <div className={`sort ${getSortClass('status')}`} />
                  </th>
                  <th>Входящий номер</th>
                  <th>Выгрузка отчета</th>
                  {getReportData?.limitLogicEnable ? <th>Превышение лимита</th> : <></>}
                  <th></th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {organizationReports?.models.map(item => {
                  return (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      {organizationReports?.models.length !== 1 && (
                        <td>
                          {state.user.accessUser['report-instance']['set-actual'] && (
                            <input
                              type="checkbox"
                              className="checkbox"
                              checked={checked(item.isActual)}
                              onClick={() => updateActualAlert(item.id)}
                              onChange={e => e}
                            />
                          )}
                        </td>
                      )}

                      <td>{item.createdDate}</td>

                      {getReportData?.membershipPaymentsEnable ? <td>{item.membershipPaymentAmount}</td> : <></>}

                      <td>
                        <div className="row row-sm ai-center">
                          <div className="col-auto">
                            <div className={`badge badge-${STATUS_CLASS_LIST[item.status]}`}>{item.statusText}</div>
                          </div>

                          {/* {item.status !== 7 && (
													<div className="col-auto">
														{state.user.accessUser[`report-instance`]
															.reject && (
															<button
																className="btn btn-primary"
																onClick={() => rejectReportAlert(item.id)}
															>
																Отклонить
															</button>
														)}
													</div>
												)} */}

                          {/* {item.status === 5 && (
													<div className="col-auto">
														{state.user.accessUser[`report-instance`]
															.accept && (
															<button
																className="btn btn-success"
																onClick={() => acceptReportAlert(item.id)}
															>
																Принять
															</button>
														)}
													</div>
												)} */}
                        </div>
                      </td>

                      <td>
                        {item?.incomingNumber ? (
                          <div className="row ai-center">
                            <div className="col-auto">
                              {item?.incomingNumber}
                              <br />
                              {item.incomingNumberDate ? dayjs(item.incomingNumberDate).format('DD-MM-YYYY') : ''}
                            </div>

                            <div className="col-auto pl-0">
                              <button
                                className="badge badge-blue"
                                onClick={() => {
                                  setErrObj({});
                                  setEditReportInstanceModalId(item.id);
                                  setEditReportInstanceModalValue(item?.incomingNumber.split('-')[2] || '');
                                  setEditReportInstanceModalVisible(true);

                                  setIncomingDate(
                                    item.incomingNumberDate ? dayjs(item.incomingNumberDate).format('YYYY-MM-DD') : null
                                  );
                                }}
                              >
                                Редактировать
                              </button>
                            </div>
                          </div>
                        ) : (
                          <button
                            className="badge badge-green"
                            onClick={() => {
                              setErrObj({});
                              setAddReportInstanceModalId(item.id);
                              setAddReportInstanceModalVisible(true);
                              setAddReportInstanceModalValue('');
                            }}
                          >
                            Добавить
                          </button>
                        )}
                      </td>

                      <td style={{ minWidth: '156px' }}>
                        <BadgeTab notReprimanded={item?.isNotReprimanded} id={item.id} refreshReports={fetchData} />
                      </td>

                      {getReportData?.limitLogicEnable ? (
                        <td>
                          {item.limitExceeded ? (
                            <div className={'badge badge-red'}>Лимит превышен</div>
                          ) : item.limitExceeded !== null ? (
                            <div className={'badge badge-green'}>Лимит не превышен</div>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : (
                        <></>
                      )}

                      <td>
                        {!item.isRejected && (
                          <button
                            className="badge badge-blue"
                            title="Сделать отчет не принятым"
                            onClick={() => {
                              makeReportRejected(item.id);
                            }}
                          >
                            Не принят
                          </button>
                        )}

                        {/*{item.isRejected && <span className={'badge badge-pale-red'}>Отчет не принят</span>}*/}
                      </td>

                      <td>
                        <div className="row row-sm" style={{ minWidth: 280, flexWrap: 'nowrap' }}>
                          <ControlBtn
                            idLink={item.id}
                            activeControl={item.activeControl}
                            requiRement={item.activeControl ? item.activeControl?.requirement : ''}
                            requirementId={item.activeControl ? item.activeControl.requirement?.id : ''}
                            fetchData={fetchData}
                          />
                          {state.user.accessUser['report-instance'].note && (
                            <div className="col-auto mt-1 mb-1">
                              <button
                                className="action-btn btn btn-success "
                                onClick={() => openNoteMod(item.id, item.note)}
                                title="Комментарий к отчету"
                              >
                                <Blogging />
                              </button>
                            </div>
                          )}
                          {state.user.accessUser['report-instance'].check && (
                            <div className="col-auto mt-1 mb-1">
                              <button
                                className="action-btn btn btn-warning"
                                onClick={() => refreshTrigger(item.id)}
                                title="Запустить проверку на триггеры"
                              >
                                <Refresh />
                              </button>
                            </div>
                          )}
                          {state.user.accessUser['report-instance'].download && (
                            <div className="col-auto mt-1 mb-1">
                              <button
                                className="action-btn btn btn-light"
                                onClick={() => downloadFile(item.id)}
                                title="Скачать отчет"
                              >
                                <Download />
                              </button>
                            </div>
                          )}

                          {/* <div className="col-auto">
													<Link
														to={"/tree/" + item.id}
														className="action-btn btn btn-info"
														target="_blank"
														title="Открыть древо"
													>
														<Consent />
													</Link>
												</div> */}
                          {state.user.accessUser['report-instance'].problem && (
                            <>
                              {item.status === 3 && (
                                <div className="col-auto mt-1 mb-1">
                                  <button
                                    className="action-btn btn btn-warning"
                                    onClick={() => viewTriggerWarning(item.id)}
                                    title="Предупреждения триггера"
                                  >
                                    <Information />
                                  </button>
                                </div>
                              )}
                            </>
                          )}
                          {state.user.accessUser['report-instance'].triggered && (
                            <>
                              {item.status === 4 && (
                                <div className="col-auto mt-1 mb-1">
                                  <button
                                    className="action-btn btn btn-danger"
                                    onClick={() => viewTriggerError(item.id)}
                                    title="Ошибки триггера"
                                  >
                                    <WarningSign />
                                  </button>
                                </div>
                              )}
                            </>
                          )}

                          {(item.status === 3 || item.status === 5) && (
                            <div className="col-auto mt-1 mb-1">
                              <button
                                className="action-btn btn btn-primary"
                                onClick={() => viewReportDetailTriggers(item.id)}
                                title="Детализация условия"
                              >
                                <Approval />
                              </button>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="mt-2 ta-center">
            <ReactPaginate
              pageCount={organizationReports?.lastPage === 1 ? 0 : organizationReports?.lastPage}
              pageRangeDisplayed={5}
              marginPagesDisplayed={3}
              onPageChange={e => setPage(e.selected + 1)}
              activeClassName={'active'}
              containerClassName={'pagination d-inline-flex ta-center'}
              pageLinkClassName={'link'}
              pageClassName={'link'}
              previousLabel={false}
              nextLabel={false}
            />
          </div>
        </div>
      </div>

      <Modal
        show={addReportInstanceModalVisible}
        onClose={() => {
          setIncomingDate('');
          setAddReportInstanceModalVisible(false);
        }}
        modalTitle="Добавить входящий номер"
        footer={
          <button className="btn btn-success" onClick={addIncomingNumberFns}>
            Добавить
          </button>
        }
      >
        <div className="d-flex">
          <span className="mt-auto mb-auto">ТР-</span>
          <Select
            fieldClasses="mt-auto mb-auto"
            style={{ minWidth: '75px' }}
            onChange={setAddReportInstanceModalYear}
            value={addReportInstanceModalYear}
            values={YEARS}
          />
          <span className="mt-auto mb-auto">-</span>
          <Input
            fieldClasses="mt-auto mb-auto"
            value={addReportInstanceModalValue}
            onChange={e => modalFuncIncomingNum('add', e)}
          />
        </div>

        {errObj.incNum && <span className="_error">{errObj.incNum}</span>}

        <Input
          fieldClasses="mt-3"
          value={incomingDate}
          onChange={setIncomingDate}
          errObj={errObj.incDate}
          label="Дата присвоения входящего номера"
          type="date"
          max="9999-01-01"
        />
      </Modal>

      <Modal
        show={editReportInstanceModalVisible}
        onClose={() => {
          setIncomingDate('');
          setEditReportInstanceModalVisible(false);
        }}
        modalTitle="Редактировать входящий номер"
        footer={
          <button className="btn btn-success" onClick={editIncomingNumberFns}>
            Сохранить
          </button>
        }
      >
        <div className="d-flex">
          <span className="mt-auto mb-auto">ТР-</span>
          <Select
            fieldClasses="mt-auto mb-auto"
            style={{ minWidth: '75px' }}
            onChange={setEditReportInstanceModalYear}
            value={editReportInstanceModalYear}
            values={YEARS}
          />
          <span className="mt-auto mb-auto">-</span>
          <Input
            fieldClasses="mt-auto mb-auto"
            value={editReportInstanceModalValue}
            onChange={e => modalFuncIncomingNum('edit', e)}
          />
        </div>
        {errObj.incNum && <span className="form-error">{errObj.incNum}</span>}

        <Input
          fieldClasses="mt-3"
          value={incomingDate}
          onChange={setIncomingDate}
          errObj={errObj.incDate}
          label="Дата присвоения входящего номера"
          type="date"
          max="9999-01-01"
        />
      </Modal>

      <Modal show={warningModal} onClose={() => showWarning(false)} modalTitle="Ошибки в отчете">
        <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: triggerWarning }} />
      </Modal>

      <Modal show={ErrorModal} onClose={() => showError(false)} modalTitle="Ошибки при проверке триггера">
        <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: triggerError }} />
      </Modal>

      <Modal
        footer={
          <button className="btn btn-primary" onClick={() => editNoteSave()}>
            Сохранить
          </button>
        }
        show={viewNote}
        onClose={() => {
          showNote(false);
        }}
        modalTitle="Коментарий"
      >
        <Textarea rows="10" onChange={setNoteData} value={noteData} />
      </Modal>
      <Modal
        show={modalUpload}
        onClose={() => {
          setIncomingDate('');
          setModalUpload(false);
        }}
        modalTitle="Загрузка отчета"
        footer={
          <button className="btn btn-success" onClick={uploadDoc}>
            Загрузить
          </button>
        }
      >
        <div className="col-auto mb-3" style={{ display: 'inline-block' }}>
          <label className="d-flex">
            <input
              type="checkbox"
              className="checkbox mr-2"
              checked={checkOnChecking}
              onChange={() => setCheckOnChecking(!checkOnChecking)}
            />
            <span>Отправка отчета на проверку</span>
          </label>
        </div>

        {!checkOnChecking && (
          <>
            <div className="d-flex">
              <span className="mt-auto mb-auto">ТР-</span>
              <Select
                fieldClasses="mt-auto mb-auto"
                style={{ minWidth: '75px' }}
                onChange={e => setYearReportUpload(e)}
                value={yearReportUpload}
                values={YEARS}
              />
              <span className="mt-auto mb-auto">-</span>
              <Input
                fieldClasses="mt-auto mb-auto"
                value={incomingNum}
                onChange={funcIncomingNum}
                errObj={errObj.incNum}
              />
            </div>
            {errObj.incNum && <span className="_error">{errObj.incNum}</span>}

            <Input
              fieldClasses="mt-3"
              value={incomingDate}
              onChange={setIncomingDate}
              errObj={errObj.incDate}
              label="Дата присвоения входящего номера"
              type="date"
              max="9999-01-01"
            />
          </>
        )}

        <div className="mt-3">
          <Dropzone
            maxSize={1000000000}
            onFileRemove={() => setFile([])}
            onFileUpload={handleUploadFiles}
            styles={{ height: 120 }}
            files={file}
            error={errObj.file}
          />
          {errObj.file &&
            errObj.file.map(i => (
              <span key={i} className="_error">
                {i}
              </span>
            ))}
        </div>
      </Modal>
      <Modal
        show={detailTriggersModal}
        onClose={() => setDetailTriggersModal(false)}
        modalTitle="Отчет о проверке условий триггеров с детализацией условия"
      >
        <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: detailTriggers }} />
      </Modal>
    </div>
  );
};

export default ViewOrganizationReports;
