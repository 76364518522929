import { Breadcrumbs, Input, Modal, Select } from '../../components';
import ReactPaginate from 'react-paginate';
import { useFetch } from '../../../hooks/useFetch';
import React, {useEffect, useState} from 'react';
import { Blogging, Download } from '../../icons';
import { apiGet, apiPost } from '../../../api';
import { CURRENT_YEAR, YEARS } from '../../../global';
import dayjs from 'dayjs';
import { downloadReport } from '../../../utils/downloadReport';

export const ReportArchives = () => {
  const [page, setPage] = useState(1);
  const [createModalIsVisible, setCreateModalIsVisible] = useState(false);
  const [sending, setSending] = useState(false);

  const [addNumberModalIsVisible, setAddNumberModalIsVisible] = useState(false);
  const [addNumberId, setAddNumberId] = useState('');

  const [createFormReport_template_id, setCreateFormReport_template_id] = useState('');
  const [createFormReportTemplatePeriodIds, setCreateFormReportTemplatePeriodIds] = useState([]);
  const [createFormDateStart, setCreateFormDateStart] = useState('');
  const [createFormDateEnd, setCreateFormDateEnd] = useState('');
  const [maxOrganizationsCount, setMaxOrganizationsCount] = useState('');

  const [addNumberIncoming_number, setAddNumberIncoming_number] = useState('');
  const [addNumberIncoming_numberYear, setAddNumberIncoming_numberYear] = useState(CURRENT_YEAR);
  const [addNumberIncoming_number_date, setAddNumberIncoming_number_date] = useState('');
  const [errObj, setErrObj] = useState({});

  const [reprimanded, setReprimanded] = useState(false);
  const [doNotAddUnloaded, setDoNotAddUnloaded] = useState(false);

  const [reportPeriodSelectClass, setReportPeriodSelectClass] = useState('form-input _select');
  const [reportPeriods, setReportPeriods] = useState(null);

  const reportArchive = useFetch({
    url: `/report-archive?page=${page}&limit=10`,
  });

  const reportTemplate = useFetch({
    url: '/report-template?limit=1000',
  });

  useEffect(() => {
    apiGet({ url: '/report-period?limit=10000' }).then(res => {
      if (res.error) {
        console.error(res.error);
        return;
      }

      let periods = res.data.models;
      let newReportPeriod = { id: 0, name: '' };
      periods.unshift(newReportPeriod);

      setReportPeriods(periods);
    });
  }, []);

  const linkRoute = [
    {
      name: 'Архивы отчетов',
      link: '/report-archives',
    },
  ];

  const downloadFile = id => {
    if (sending) return false;

    setSending(true);

    apiGet({ url: `/report-archive/download?id=${id}` })
      .then(res => {
        if (res.error) {
          console.error('Скачивание документа (шаблон)');
          return;
        }

        downloadReport(res.data);
      })
      .finally(() => {
        setSending(false);
      });
  };

  const renderReport = report => {
    return (
      <tr key={report.id}>
        <td>{report.id}</td>
        <td>{report.createdAt}</td>
        <td>
          <div className="d-flex flex-column">{report.reportTemplate.name}</div>
        </td>
        <td>
          <div className="d-flex flex-column">
            {report.reportArchivePeriods.map(period => (
              <span key={period.id}>{period.name}</span>
            ))}
          </div>
        </td>
        <td>
          с {report.dateStart} по {report.dateEnd}
        </td>
        <td>
          {report.isCompleted ? (
            <div className="row row-sm">
              <div className="col-auto">
                <button className="action-btn btn btn-light" title="Скачать" onClick={() => downloadFile(report.id)}>
                  <Download />
                </button>
              </div>

              <div className="col-auto">
                <button
                  className="action-btn btn btn-success"
                  title="Добавить номер"
                  onClick={() => {
                    setAddNumberId(report.id);
                    setAddNumberModalIsVisible(true);

                    setAddNumberIncoming_number(report?.incoming_number?.split('-')[2] || '');

                    if (report.incoming_number_date) {
                      setAddNumberIncoming_number_date(dayjs(report.incoming_number_date).format('YYYY-MM-DD'));
                    } else {
                      setAddNumberIncoming_number_date('');
                    }
                  }}
                >
                  <Blogging />
                </button>
              </div>
            </div>
          ) : (
            <div className="badge badge-blue">В обработке</div>
          )}
        </td>
      </tr>
    );
  };

  const createReport = async () => {
    const res = await apiPost({
      url: '/report-archive/create',
      postData: {
        report_template_id: createFormReport_template_id,
        reportTemplatePeriodIds: createFormReportTemplatePeriodIds,
        dateStart: createFormDateStart,
        dateEnd: createFormDateEnd,
        setAsReprimanded: reprimanded,
        doNotAddUnloaded,
        maxOrganizationsCount,
      },
    });

    if (res.error) {
      setErrObj(res.data);
      if (res.data?.reportTemplatePeriodIds) {
        setReportPeriodSelectClass('form-input _select _error');
      } else {
        setReportPeriodSelectClass('form-input _select');
      }
      return false;
    }

    await reportArchive.fetchData();

    setCreateModalIsVisible(false);
    setCreateFormReport_template_id('');
    setCreateFormReportTemplatePeriodIds([]);
    setCreateFormDateStart('');
    setCreateFormDateEnd('');
    setMaxOrganizationsCount('');
  };

  const addReportNumber = async () => {
    const res = await apiPost({
      url: `/report-archive/update?id=${addNumberId}`,
      postData: {
        incoming_number: addNumberIncoming_number.length
          ? `ТР-${addNumberIncoming_numberYear}-${addNumberIncoming_number}`
          : '',
        incoming_number_date: addNumberIncoming_number_date,
      },
    });

    if (res.error) {
      console.error('Ошибка при присвоении номера');
      return false;
    }

    await reportArchive.fetchData();

    setAddNumberIncoming_number_date('');
    setAddNumberIncoming_number('');
    setAddNumberModalIsVisible(false);
  };

  const modalFuncIncomingNum = value => {
    setErrObj({});

    if (value.length >= 9) {
      setErrObj({ incNum: 'Максимальное количество цифр - 8' });
      return;
    }

    setAddNumberIncoming_number(value);
  };

  return (
    <>
      <Breadcrumbs linkRoute={linkRoute} />

      <div className="card">
        <div className="card-content">
          <div className="d-flex jc-end">
            <button className="btn btn-primary" onClick={() => setCreateModalIsVisible(true)}>
              Сформировать архив
            </button>
          </div>

          <div className="scroll-x">
            <table className="mt-3 big-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Время создания</th>
                  <th>Форма отчетности</th>
                  <th>Отчетный период</th>
                  <th>Отчетная дата</th>
                  <th />
                </tr>
              </thead>
              <tbody>{reportArchive.response?.models.map(renderReport)}</tbody>
            </table>
          </div>

          <div className="mt-2 ta-center">
            <ReactPaginate
              pageCount={reportArchive.response?.lastPage === 1 ? 0 : reportArchive.response?.lastPage}
              pageRangeDisplayed={5}
              marginPagesDisplayed={3}
              onPageChange={e => setPage(e.selected + 1)}
              activeClassName={'active'}
              containerClassName={'pagination d-inline-flex ta-center'}
              pageLinkClassName={'link'}
              pageClassName={'link'}
              previousLabel={false}
              nextLabel={false}
            />
          </div>
        </div>
      </div>

      <Modal
        show={addNumberModalIsVisible}
        onClose={() => setAddNumberModalIsVisible(false)}
        modalTitle="Присвоить номер архиву"
        footer={
          <button className="btn btn-primary" onClick={addReportNumber}>
            Присвоить
          </button>
        }
      >
        <div className="d-flex">
          <span className="mt-auto mb-auto">ТР-</span>
          <Select
            fieldClasses="mt-auto mb-auto"
            style={{ minWidth: '75px' }}
            onChange={setAddNumberIncoming_numberYear}
            value={addNumberIncoming_numberYear}
            values={YEARS}
          />
          <span className="mt-auto mb-auto">-</span>
          <Input fieldClasses="mt-auto mb-auto" value={addNumberIncoming_number} onChange={modalFuncIncomingNum} />
        </div>
        {errObj.incNum && <span className="form-error">{errObj.incNum}</span>}

        <Input
          label="Дата присвоения входящего номера"
          type="date"
          value={addNumberIncoming_number_date}
          onChange={setAddNumberIncoming_number_date}
          fieldClasses="mt-3"
        />
      </Modal>

      <Modal show={createModalIsVisible} onClose={() => setCreateModalIsVisible(false)} modalTitle="Сформировать архив">
        <Select
          label="Форма отчетности"
          values={
            reportTemplate.response?.models
              ? [
                  {
                    id: 1,
                    name: '',
                  },
                  ...(reportTemplate?.response?.models || []),
                ]
              : []
          }
          onChange={setCreateFormReport_template_id}
          error={errObj.report_template_id}
        />

        <label className="form-field">
          <span className="form-label">Отчетный период</span>

          <select
            onChange={e => setCreateFormReportTemplatePeriodIds(e.target.value)}
            className={reportPeriodSelectClass}
            disabled={false}
          >
            {reportPeriods
              ? reportPeriods.map(v => {
                  return (
                    <option key={v.id} value={v.id}>
                      {v.name} {v?.startDate && v?.endDate && '('}
                      {v?.startDate} {v?.startDate && v?.endDate && '-'} {v?.endDate}
                      {v?.startDate && v?.endDate && ')'}
                    </option>
                  );
                })
              : []}
          </select>

          {errObj.reportTemplatePeriodIds && <div className="form-error">{errObj.reportTemplatePeriodIds}</div>}
        </label>

        <div className="row ai-end">
          <div className="col-sm-6">
            <Input
              label="Отчетная дата"
              type="date"
              value={createFormDateStart}
              onChange={setCreateFormDateStart}
              error={errObj.dateStart}
            />
          </div>

          <div className="col-sm-6">
            <Input type="date" value={createFormDateEnd} onChange={setCreateFormDateEnd} error={errObj.dateEnd} />
          </div>
        </div>

        <Input
            name='maxOrganizationCount'
            fieldClasses='w-full'
            error={errObj.maxOrganizationCount}
            label='Максимальное количество организаций в одном архиве'
            onChange={setMaxOrganizationsCount}
        />

        <label className="d-flex mb-4">
          <input
            type="checkbox"
            className="checkbox mr-2"
            checked={reprimanded}
            onChange={() => setReprimanded(!reprimanded)}
          />
          <span>Отметить отчеты как выгруженные</span>
        </label>
        <label className="d-flex mb-4">
          <input
            type="checkbox"
            className="checkbox mr-2"
            checked={doNotAddUnloaded}
            onChange={() => setDoNotAddUnloaded(!doNotAddUnloaded)}
          />
          <span>Не включать выгруженные</span>
        </label>

        <button className="btn btn-xl btn-primary" onClick={createReport}>
          Сформировать архив
        </button>
      </Modal>
    </>
  );
};
