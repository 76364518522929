/* eslint-disable no-unused-vars */
import PropType from 'prop-types';
import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useHistory } from 'react-router-dom';
import { apiPost } from '../../../../api';
import { Breadcrumbs, Input, Select } from '../../../components';
import './index.scss';

const CreateTemplate = () => {
  const [reportName, setReportName] = useState('');
  const [showTriggersDetails, setShowTriggersDetails] = useState(true);
  const [requirementsLogic, setRequirementsLogic] = useState(1);
  const [errObj, setErrObj] = useState({});

  const customAlert = text => {
    confirmAlert({
      message: text,
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  const history = useHistory();
  const templateCreate = () => {
    if (reportName === '') {
      customAlert("необходимо заполнить 'Название шаблона' ");
      return;
    }

    apiPost({
      url: `/report-template/create`,
      postData: {
        name: reportName,
        showTriggersDetails,
        requirementsLogic,
      },
    }).then(res => {
      if (res.status === 406) {
        setErrObj(res.data);
        return;
      } else if (res.error) {
        console.error('Изменение шаблона');
        return;
      }

      history.goBack();
      customAlert('Шаблон успешно создан');
    });
  };

  const linkRoute = [
    {
      name: 'Шаблоны отчетов',
      link: '/reports-template',
    },
    {
      name: 'Создание шаблона',
      link: `/create-template`,
    },
  ];

  return (
    <div className="create-template">
      <Breadcrumbs linkRoute={linkRoute} />
      <div className="card">
        <div className="card-header">Создание шаблона</div>

        <div className="card-content">
          <Input
            label="Название шаблона"
            onChange={setReportName}
            error={errObj.name}
            placeholder="Введите название шаблона"
          />
        </div>

        <div className="card-content">
          <label className="d-flex">
            <input
              name="allowed_absence"
              type="checkbox"
              className="checkbox"
              checked={showTriggersDetails}
              onChange={() => setShowTriggersDetails(!showTriggersDetails)}
            />
            <span>Показывать детализацию по триггерам в пользовательской версии</span>
          </label>
        </div>

        <div className="card-content">
          <Select
            label="Логика отправки писем"
            value={requirementsLogic}
            onChange={setRequirementsLogic}
            values={[
              { id: 1, name: 'Отправка требований' },
              { id: 2, name: 'Отправка запросов пояснений' },
              { id: 3, name: 'Не отправляется' },
            ]}
          />
        </div>

        <div className="card-footer">
          <button onClick={templateCreate} className="btn btn-primary">
            Создать
          </button>
        </div>
      </div>
    </div>
  );
};
CreateTemplate.propTypes = {
  history: PropType.object,
};
export default CreateTemplate;
