import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { apiGet, apiPost } from '../../../../api';
import { countNotReprimanded } from '../../../../redux/actions/user';
import { downloadBase64File } from '../../../../utils/downloadBase64';
import { goToLink } from '../../../../utils/goToLink';
import { Breadcrumbs, Pagination } from '../../../components';
import { Download } from '../../../icons';
import './index.scss';
import useUrl from '../../../../hooks/useUrl';
import { confirmAlert } from 'react-confirm-alert';

const MembershipPaymentsReports = () => {
  const state = useSelector(state => state);
  const history = useHistory();
  const { id, templateId } = useParams();
  const dispatch = useDispatch();
  const [summaryReports, setSummaryReports] = useState(null);

  const { page } = useUrl();

  useEffect(() => {
    refreshSummaryReports();
  }, [page]);

  const refreshSummaryReports = () => {
    apiGet({
      url: `/summary-report?report_id=${id}&page=${page || 1}&limit=10&types=12,13`,
    }).then(res => {
      setSummaryReports(res);
    });
  };

  const linkRoute = [
    {
      name: 'Отчеты',
      link: '/reports',
    },
    {
      name: 'Отчет организаций',
      link: `/organization-reports/${id}`,
    },
    {
      name: 'Отчеты по ЧВ',
      link: `/membership-payments-reports/${id}`,
    },
  ];

  const downloadFile = id => {
    apiGet({
      url: `/summary-report/view?id=${id}&withFile=1`,
    }).then(res => {
      if (res?.error) {
        console.error('Скачивание элемента шаблона сводного отчета');
        return;
      }
      downloadBase64File(res.data.asBase64, `${res.data.fileName}`);
    });
  };

  useEffect(() => {
    apiGet({ url: `/report/not-reprimanded-count` }).then(res => {
      dispatch(countNotReprimanded(res.data));
    });
  }, []);

  const createReport = type => {
    const postData = new FormData();

    postData.append('type', type);

    apiPost({
      url: `/summary-report/create?report_id=${id}`,
      postData,
    }).then(res => {
      var message = null;
      if (res.error) {
        message = 'Произошла ошибка';
      } else {
        message = 'Отчет скоро будет сформирован';
        refreshSummaryReports();
      }

      confirmAlert({
        message: message,
        buttons: [
          {
            label: 'Ок',
            onClick: () => {},
          },
        ],
      });
    });
  };

  return (
    <>
      <Breadcrumbs linkRoute={linkRoute} />
      <div className="summary card">
        <div className="card-header">Отчеты по ЧВ</div>
        <div className="card-content">
          {state.user.accessUser[`summary-report`].create && (
            <div>
              <button className="btn btn-success mr-2" onClick={() => createReport(12)}>
                Отчет по оплатившим
              </button>
              <button className="btn btn-danger mr-2" onClick={() => createReport(13)}>
                Отчет по должникам
              </button>
            </div>
          )}

          <div className="scroll-x">
            <table className="mt-3 low-table">
              <thead>
                <tr>
                  <th>Время создания</th>
                  <th>Тип</th>
                  <th>Статус</th>
                  <th className="edit"></th>
                </tr>
              </thead>
              <tbody>
                {summaryReports ? (
                  summaryReports?.data?.models.map(i => (
                    <tr
                      className={'go-link'}
                      style={{ cursor: 'pointer' }}
                      key={i.id}
                      onClick={e => goToLink(e, () => history.push(`/edit-summary-report/${id}/${i.id}/${templateId}`))}
                    >
                      <td>{i?.createdAt}</td>
                      <td>{i?.typeText}</td>
                      <td>{i?.completedAt ? 'Готово к выгрузке' : 'Обработка на сервере'}</td>
                      <td>
                        {i?.completedAt && (
                          <button
                            className="action-btn btn btn-light"
                            onClick={() => downloadFile(i.id)}
                            title="Скачать отчет"
                          >
                            <Download />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </div>

          <div className="mt-2 ta-center">
            <Pagination lastPage={summaryReports?.data?.lastPage} page={page} />
            {/* <ReactPaginate
							pageCount={
								summaryReports?.lastPage == 1
									? 0
									: getSummaryReports?.lastPage
							}
							pageRangeDisplayed={5}
							marginPagesDisplayed={3}
							onPageChange={e => setPage(e.selected + 1)}
							activeClassName={"active"}
							containerClassName={"pagination d-inline-flex ta-center"}
							pageLinkClassName={"link"}
							pageClassName={"link"}
							previousLabel={false}
							nextLabel={false}
						/> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default MembershipPaymentsReports;
