import React, { useState } from 'react';
import { Input, Modal } from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { modalApi } from '../../../../../redux/actions/modal';
import { useForm } from 'react-hook-form';
import { CustomButton } from '../../../../components/Button/Button';
import { DatePicker, message, TimePicker } from 'antd';
import { DATE_FORMAT, DATETIME_FORMAT, TIME } from '../../../../../constants/formats';
import dayjs from 'dayjs';
import { apiPost } from '../../../../../api';
import { errorParse } from '../../../../../utils/errorParse';
import { useProtocolPage } from '../useProtocolPage';
import PropTypes from 'prop-types';
import Approval from '../../../../icons/Approval';
import { createUrlQuery } from '../../../../../utils/createApiUrl';
import { useParams } from 'react-router-dom';
import { CustomEditor } from '../../../../components/CustomEditor/CustomEditor';

// eslint-disable-next-line react/prop-types
export const CreateProtocolModal = ({ fetchData }) => {
  const {
    register,
    setError,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: {
      number: '',
      date: '',
      timeStart: '',
      timeEnd: '',
      membersInfo: '',
    },
  });
  const { dispatch } = useProtocolPage();
  const { id } = useParams();
  const isVisible = !!useSelector(s => s.modal.name === 'create-protocol');

  const [editorValue, setEditorValue] = useState('');

  const [editorErrors, setEditorErrors] = useState([]);

  const hideModal = () => {
    dispatch(modalApi(null));
    reset();
  };

  const createProtocol = async formData => {
    const postData = { ...formData, date: dayjs(formData.date).format(DATE_FORMAT), membersInfo: editorValue };

    const { data, error, status } = await apiPost({
      url: `/dk-protocol/create${createUrlQuery({ dkFolderId: id })}`,
      postData,
    });

    if (status === 406) {
      if (data?.membersInfo) {
        if (Array.isArray(data.membersInfo)) {
          setEditorErrors(data.membersInfo[0]);
        } else {
          setEditorErrors([data.membersInfo]);
        }
      }

      errorParse(data, setError);
      return;
    }

    if (!error) {
      setEditorErrors([]);

      message.success('Успешно создано!');
      await fetchData();
      hideModal();
    }
  };

  return (
    <div>
      <Modal
        modalTitle="Сформировать протокол"
        footer={<CustomButton onClick={handleSubmit(createProtocol)}>Сформировать</CustomButton>}
        show={isVisible}
        onClose={hideModal}
      >
        <div className="flex space-y-3 flex-col">
          <Input
            register={register}
            label="Номер"
            name="number"
            fieldClasses="!mb-0"
            error={errors.number && [errors.number.message]}
          />

          <Input
            register={register}
            name="date"
            error={errors.date && [errors.date.message]}
            type="date"
            label="Дата протокола"
          />

          <div className="flex items-center gap-2">
            <Input
              register={register}
              name="timeStart"
              error={errors.timeStart && [errors.timeStart.message]}
              fieldClasses="w-full"
              type="time"
              label="	Время начала заседания "
            />

            <Input
              register={register}
              name="timeEnd"
              fieldClasses="w-full"
              error={errors.timeEnd && [errors['timeEnd'].message]}
              type="time"
              label="Время завершения заседания"
            />
          </div>

          <CustomEditor
            label="Состав комитета"
            error={!!editorErrors?.length && editorErrors}
            value={editorValue}
            onChange={setEditorValue}
          />
        </div>
      </Modal>
    </div>
  );
};

CreateProtocolModal.protoTypes = {
  fetchData: PropTypes.any,
};
