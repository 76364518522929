import React, { useEffect, useState } from 'react';
import { TrashCan } from '../../icons';
import { useFetch } from '../../../hooks/useFetch';
import { createUrlQuery } from '../../../utils/createApiUrl';
import { Pencil } from '../../icons/Pencil';
import { Pagination } from '../../components';
import { ReasonCreateModal } from './ReasonCreateModal';
import { useDispatch } from 'react-redux';
import { toggleCreateReasonModal, toggleUpdateReasonModal } from '../../../redux/actions/dk';
import Index from '../../components/Spinner';
import { confirmAlert } from 'react-confirm-alert';
import { apiGet, apiPost } from '../../../api';
import { ReasonUpdateModal } from './ReasonUpdateModal';
import useUrl from '../../../hooks/useUrl';
import { message } from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useReasonPage } from './useReasonPage';

const ReasonPage = () => {
  const { handleConfirmDeleteReason, dispatch, reasonQueryList, page } = useReasonPage();

  return (
    <div>
      <div className="card">
        <div className="card-header flex-wrap jc-between d-flex ai-center">
          Причины приглашений
          <button onClick={() => dispatch(toggleCreateReasonModal(true))} className="btn btn-primary btn-xs">
            Создать новую причину приглашения
          </button>
        </div>
        <div className="card-content">
          {reasonQueryList.isLoading ? (
            <div className="d-flex jc-center ">
              <Index size={60} />
            </div>
          ) : (
            <div className="scroll-x">
              <table className="mt-4 middle-table">
                {!reasonQueryList.data?.data?.models?.length && !reasonQueryList.isLoading ? (
                  <div className="w-full text-center font-medium text-primary">Ничего не найдено</div>
                ) : (
                  <thead>
                    <tr>
                      <th>Название</th>
                      <th className="settings" />
                    </tr>
                  </thead>
                )}

                <tbody>
                  {reasonQueryList.data.data?.models.map(reason => (
                    <tr key={reason.id}>
                      <td>{reason.name || ''}</td>
                      <td>
                        <div className="d-flex ai-center jc-end">
                          <button
                            className="action-btn btn btn-warning mr-3"
                            onClick={() =>
                              dispatch(
                                toggleUpdateReasonModal({
                                  show: true,
                                  reason,
                                })
                              )
                            }
                            title="Редактировать"
                          >
                            <Pencil />
                          </button>
                          <button
                            className="action-btn btn btn-danger"
                            onClick={() => handleConfirmDeleteReason(reason.id)}
                            title="Удалить"
                          >
                            <TrashCan />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {!reasonQueryList.isLoading && (
            <div className="d-flex jc-center">
              <Pagination lastPage={reasonQueryList.data.data?.lastPage} page={page} />
            </div>
          )}
        </div>
      </div>

      <ReasonCreateModal fetchData={() => reasonQueryList.refetch()} />
      <ReasonUpdateModal fetchData={() => reasonQueryList.refetch()} />
    </div>
  );
};

export default ReasonPage;
