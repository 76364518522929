import React, { Fragment, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Route, useLocation } from 'react-router-dom';
import { apiGet } from '../../../api';
import { getUnReadMessagesIncoming } from '../../../redux/actions/user';
import { Footer, Header, Menu } from '../../layouts/index';
import {
  CalendarAppPage,
  CalendarPage,
  CreateRulePage,
  CreateSummaryReportPage,
  CreateTemplatePage,
  CreateTriggerPage,
  EditReportPage,
  EditRulePage,
  EditSummaryReportPage,
  EditTriggerPage,
  EventsPage,
  InboxMessagePage,
  InboxPage,
  LoginPage,
  MfoPage,
  MfoReportsExamplesPage,
  OrganizationReportsPage,
  PeriodReportsPage,
  ProfilePage,
  ReportsPage,
  ReportsTemplatePage,
  ReportTriggersPage,
  SummaryReportsPage,
  TemplateSummaryReportElementsDownloadPage,
  TemplateSummaryReportElementsPage,
  TemplateSummaryReportPage,
  TemplateSummaryReportTriggersElementsDownloadPage,
  TemplateSummaryReportTriggersElementsPage,
  TemplateSummaryReportTriggersPage,
  TreePage,
  UsersPage,
  ViewReportPage,
  ViewUserPage,
} from '../../pages/index';
import MfoReportsPage from '../../pages/MfoReportPage';
import { ViewOrganizationReports } from './index';
import { ReportArchives } from '../../pages/ReportArchives/ReportArchives';
import { JournalRequirements } from '../../pages/JournalRequirements/JournalRequirements';
import EditTriggerReportsPage from '../../pages/EditTriggerReportsPage';
import ReasonPage from '../../pages/ReasonPage/ReasonPage';
import InvitationListPage from '../../pages/Invitation/InvitationListPage/InvitationListPage';
import InvitationViewPage from '../../pages/Invitation/InvitationViewPage/InvitationViewPage';
import ExtractPage from '../../pages/ExtractPage/ExtractPage';
import ProtocolPage from '../../pages/Protocol/ProtocolPage/ProtocolPage';
import ProtocolViewPage from '../../pages/Protocol/ProtocolViewPage/ProtocolViewPage';
import OrganizationDkPage from '../../pages/OrganizationDkPage/OrganizationDkPage';
import OrganizationUpdateDkPage from '../../pages/OrganizationUpdateDkPage/OrganizationUpdateDkPage';
import MembershipPaymentsReportsPage from '../../pages/MembershipPaymentsReportsPage';
import OrganizationRegistryListPage from '../../pages/OrganizationRegistryListPage';
import OrganizationRegistryRowListPage from '../../pages/OrganizationRegistryRowListPage';

const LkFlow = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const mobMenuRef = useRef();

  const getUnreadableMsg = () => {
    apiGet({ url: '/mail-message/not-viewed-count?group=0' }).then(res => {
      if (res?.error) {
        console.error('Получение непрочитанных сообщений');
        return;
      }
      dispatch(getUnReadMessagesIncoming(res.data));
    });
  };

  useEffect(() => {
    getUnreadableMsg();
  }, [location]);

  const toggleMobMenu = () => {
    mobMenuRef.current.classList.toggle('_visible');
  };

  useEffect(() => {
    mobMenuRef.current.classList.remove('_visible');
  }, [location]);

  return (
    <>
      <Header onOpenMobMenu={toggleMobMenu} />

      <div className="flex-fill content d-flex">
        <Menu ref={mobMenuRef} />

        <div className="content-block">
          {/* <Route path="/" component={HomePage} exact /> */}
          <Route path="/" component={UsersPage} exact />

          <Route path="/mfo" component={MfoPage} />
          <Route path="/mfo-reports-examples/:id/:idReport" component={MfoReportsExamplesPage} />
          <Route path="/mfo-reports/:id" component={MfoReportsPage} />

          <Route path="/view-user/:userId" component={ViewUserPage} />

          <Route path="/events" component={EventsPage} />
          <Route path="/inbox" component={InboxPage} />
          <Route path="/inbox/:idFolder" component={InboxPage} />
          <Route path="/message/:idMessage" component={InboxMessagePage} />
          <Route path="/users" component={UsersPage} />
          <Route path="/template-summary-report/:id" component={TemplateSummaryReportPage} />
          <Route
            path="/template-summary-report-elements/:id/:idTemplateSummaryReport"
            component={TemplateSummaryReportElementsPage}
          />
          <Route
            path="/template-summary-report-elements-download/:id/:idTemplateSummaryReport"
            component={TemplateSummaryReportElementsDownloadPage}
          />
          <Route path="/template-summary-report-triggers/:id" component={TemplateSummaryReportTriggersPage} />
          <Route
            path="/template-summary-report-triggers-elements/:id/:idTemplateSummaryReport"
            component={TemplateSummaryReportTriggersElementsPage}
          />
          <Route
            path="/template-summary-report-triggers-elements-download/:id/:idTemplateSummaryReport"
            component={TemplateSummaryReportTriggersElementsDownloadPage}
          />

          <Route path="/organization-reports/:id" component={OrganizationReportsPage} />

          <Route path="/report-triggers/:id" component={ReportTriggersPage} />

          <Route path="/summary-reports/:id/:templateId" component={SummaryReportsPage} />
          <Route path="/membership-payments-reports/:id/:templateId" component={MembershipPaymentsReportsPage} />
          <Route path="/create-summary-report/:id/:templateId" component={CreateSummaryReportPage} />
          <Route path="/edit-summary-report/:idReport/:id/:templateId" component={EditSummaryReportPage} />
          <Route path="/view-organization-reports/:id/:idReport" component={ViewOrganizationReports} />
          <Route path="/create-template" component={CreateTemplatePage} />
          <Route path="/edit-report/:id" component={EditReportPage} />
          <Route path="/view-report/:id" component={ViewReportPage} />
          <Route path="/edit-trigger/:id/:idTrigger" component={EditTriggerPage} />
          <Route path="/edit-trigger-reports/:id/:idTrigger" component={EditTriggerReportsPage} />

          <Route path="/edit-rule/:id/:idRule" component={EditRulePage} />

          <Route path="/create-rule/:id" component={CreateRulePage} />
          <Route path="/create-trigger/:id" component={CreateTriggerPage} />
          <Route path="/profile" component={ProfilePage} />
          <Route path="/tree/:id" component={TreePage} />
          <Route path="/login" component={LoginPage} />

          <Route path="/reports" component={ReportsPage} />
          <Route path="/reports-period" component={PeriodReportsPage} />
          <Route path="/reports-template" component={ReportsTemplatePage} />
          <Route path="/calendar" component={CalendarPage} />
          <Route path="/calendar-app" component={CalendarAppPage} />

          <Route path="/report-archives" component={ReportArchives} />
          <Route path="/requirement" component={JournalRequirements} />
          <Route path="/reason/:id" component={ReasonPage} />
          <Route path="/invitation/:id" component={InvitationListPage} />
          <Route path="/invitation-view/:id" component={InvitationViewPage} />
          <Route path="/extract/:id" component={ExtractPage} />
          <Route path="/protocol/:id" component={ProtocolPage} />
          <Route path="/protocol-view/:id" component={ProtocolViewPage} />
          <Route path="/dk-organization/:id" component={OrganizationDkPage} />
          <Route path="/organization-update/:id" component={OrganizationUpdateDkPage} />

          <Route path="/organization-registry/:organizationId" component={OrganizationRegistryListPage} />
          <Route path="/organization-registry-row/:id" component={OrganizationRegistryRowListPage} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LkFlow;
