import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useState } from 'react';

export const OrganizationDragContainer = ({ organizations, onDrag }) => {
  const [dragList, setDragList] = useState(organizations);

  const handleDrag = ({ destination, source }) => {
    if (!destination) return;
    const items = [...dragList];

    const [reorderItem] = items.splice(source.index, 1);

    items.splice(destination.index, 0, reorderItem);

    setDragList(items);

    onDrag(items.map(el => el.extractId));
  };

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId="dataOrganization">
        {provided => (
          <ul className="p-3 space-y-3" {...provided.droppableProps} ref={provided.innerRef}>
            {dragList.map(({ extractId, organization }, index) => {
              return (
                <Draggable key={extractId.toString()} draggableId={extractId.toString()} index={index}>
                  {provided => (
                    <li
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      className="border-b text-[#495057] border-solid border-[#ced4da] bg-[#e7e7e7] p-2"
                    >
                      <span>
                        {index + 1}. {organization?.name}
                      </span>
                    </li>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};
