import React, { useEffect, useState } from 'react';
import { Input, Modal } from '../../../../components';
import { useSelector } from 'react-redux';
import { modalApi } from '../../../../../redux/actions/modal';
import { useForm } from 'react-hook-form';
import { CustomButton } from '../../../../components/Button/Button';
import { message } from 'antd';
import { DATE_FORMAT } from '../../../../../constants/formats';
import dayjs from 'dayjs';
import { apiPost } from '../../../../../api';
import { errorParse } from '../../../../../utils/errorParse';
import { useProtocolPage } from '../useProtocolPage';
import { createUrlQuery } from '../../../../../utils/createApiUrl';
import { setProtocol } from '../../../../../redux/actions/dk';
import PropTypes from 'prop-types';
import { CreateProtocolModal } from './CreateProtocolModal';
import { CustomEditor } from '../../../../components/CustomEditor/CustomEditor';

const reverseDate = date => date.split('.').reverse().join('-');
const parseDate = date => reverseDate(new Date(date * 1000).toLocaleDateString());

// eslint-disable-next-line react/prop-types
export const UpdateProtocolModal = ({ fetchData }) => {
  const {
    register,
    clearErrors,
    formState: { errors },
    setError,
    setValue,
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: {
      number: '',
      date: '',
      timeStart: '',
      timeEnd: '',
      membersInfo: '',
    },
  });
  const { dispatch } = useProtocolPage();
  const isVisible = !!useSelector(s => s.modal.name === 'update-protocol');
  const protocol = useSelector(s => s.dk.protocol);

  const [editorValue, setEditorValue] = useState('');

  const [editorErrors, setEditorErrors] = useState([]);

  const hideModal = () => {
    dispatch(setProtocol(null));
    clearErrors();
    dispatch(modalApi(null));
  };
  const updateProtocol = async formData => {
    const postData = { ...formData, date: dayjs(formData['date']).format(DATE_FORMAT), membersInfo: editorValue };

    const { data, error, status } = await apiPost({
      url: `/dk-protocol/update${createUrlQuery({ id: protocol.id })}`,
      postData,
    });

    if (status === 406) {
      if (data?.membersInfo) {
        if (Array.isArray(data.membersInfo)) {
          setEditorErrors(data.membersInfo[0]);
        } else {
          setEditorErrors([data.membersInfo]);
        }
      }

      errorParse(data, setError);
      return;
    }

    if (!error) {
      setEditorErrors([]);

      message.success('Успешно изменено!');
      reset();
      fetchData();
      hideModal();
    }
  };

  useEffect(() => {
    if (protocol) {
      setValue('number', protocol.number);
      setValue('date', parseDate(protocol.date));
      setValue('timeStart', protocol.timeStart);
      setValue('timeEnd', protocol['timeEnd']);
      setEditorValue(protocol.membersInfo);
    }
  }, [protocol]);

  return (
    <div>
      <Modal
        modalTitle="Изменить протокол"
        footer={<CustomButton onClick={handleSubmit(updateProtocol)}>Сохранить</CustomButton>}
        show={isVisible}
        onClose={hideModal}
      >
        <div className="flex space-y-3 flex-col">
          <Input
            register={register}
            label="Номер"
            name="number"
            fieldClasses="!mb-0"
            error={errors.number && [errors.number.message]}
          />

          <Input
            register={register}
            name="date"
            error={errors.date && [errors.date.message]}
            type="date"
            label="Дата протокола"
          />

          <div className="flex items-center gap-2">
            <Input
              register={register}
              name="timeStart"
              error={errors.timeStart && [errors.timeStart.message]}
              fieldClasses="w-full"
              type="time"
              label="	Время начала заседания "
            />

            <Input
              register={register}
              name="timeEnd"
              fieldClasses="w-full"
              error={errors.timeEnd && [errors['timeEnd'].message]}
              type="time"
              label="Время завершения заседания"
            />
          </div>

          <CustomEditor
            label="Состав комитета"
            error={!!editorErrors?.length && editorErrors}
            value={editorValue}
            onChange={setEditorValue}
          />
        </div>
      </Modal>
    </div>
  );
};

UpdateProtocolModal.protoTypes = {
  fetchData: PropTypes.func,
};
