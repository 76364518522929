import React from 'react';
import MembershipPaymentsReports from '../../containers/Lk/MembershipPaymentsReports';

const MembershipPaymentsReportsPage = () => {
  return (
    <>
      <MembershipPaymentsReports />
    </>
  );
};

export default MembershipPaymentsReportsPage;
