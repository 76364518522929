import React, { useEffect, useState } from 'react';
import { Input, Modal, Select } from '../../components';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleUpdateInvitationModal } from '../../../redux/actions/dk';
import { apiPost } from '../../../api';
import { createUrlQuery } from '../../../utils/createApiUrl';
import { useFetch } from '../../../hooks/useFetch';
import dayjs from 'dayjs';
import { message } from 'antd';
import getUrlParams from '../../../hooks/getUrlParams';

const dateParse = ({ time, timeAsString }) => {
  if (!timeAsString?.length) return null;

  const string = timeAsString.replace('в', '').replace('ч', '').trim();
  return {
    date: new Date(time * 1000).toLocaleDateString().split('.').reverse().join('-'),
    time: string.split(' ')[2],
  };
};

const DEFAULT_VALUES = {
  name: '',
  dkReasonId: '',
  organizationId: '',
  date: '',
  timeValue: '',
};

// eslint-disable-next-line react/prop-types
export const InvitationUpdateModal = ({ fetchData }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { archive } = getUrlParams();
  const { show, invitation } = useSelector(state => state.dk.updateInvitationModal);
  const { response, loading } = useFetch({
    url: show && `/dk-reason${createUrlQuery({ limit: 1500, dk_folder_id: archive })}`,
  });
  const organizationQuery = useFetch({ url: show && `/organization${createUrlQuery({ limit: 1500 })}` });
  const selectReasonOptions = !loading ? response?.models?.map(({ id, name }) => ({ id, name })) : [];
  const selectOrganizationOptions = !organizationQuery.loading
    ? organizationQuery.response?.models?.map(({ id, name }) => ({ id, name }))
    : [];

  const [values, setValues] = useState(DEFAULT_VALUES);

  const [errors, setErrors] = useState(DEFAULT_VALUES);

  const hideModal = () => {
    dispatch(toggleUpdateInvitationModal({ show: false, invitation: null }));
  };

  const updateInvitation = async () => {
    const postData = {
      time: `${dayjs(values.date).format('DD.MM.YYYY')} ${values.timeValue}`,
      ...values,
    };
    const { data, error, status } = await apiPost({
      url: `/dk-invitation/update${createUrlQuery({ id: invitation.id })}`,
      postData,
    });
    if (status === 406) {
      setErrors({ ...data });
    }
    if (!error) {
      hideModal();
      fetchData();
      message.success('Успешно отредактировано!');
    }
  };

  const handleValuesChange = (value, name) => {
    setValues(p => ({ ...p, [name]: value }));
  };

  useEffect(() => {
    if (invitation && show && !loading) {
      const { time, date } = dateParse(invitation);

      setValues({
        dkReasonId: invitation.dkReason.id,
        organizationId: invitation?.organization?.id,
        date,
        timeValue: time,
      });
    }
  }, [show, loading, invitation]);

  return (
    <div>
      <Modal
        footer={
          <button className="btn btn-bg-acent" onClick={updateInvitation}>
            Редактировать
          </button>
        }
        modalTitle="Редактировать приглашение"
        show={show}
        onClose={hideModal}
      >
        <div className="d-flex flex-column">
          <div className="d-flex w-full form-wrap gap-2">
            <Select
              fieldClasses="w-full"
              label="Причина приглашения"
              value={values.dkReasonId}
              onChange={v => handleValuesChange(v, 'dkReasonId')}
              values={[{ id: '', name: 'Выберите приглашение' }, ...selectReasonOptions]}
              error={errors.dkReasonId && [errors.dkReasonId]}
            />
            <Select
              fieldClasses="w-full"
              label="Организация"
              value={values.organizationId}
              onChange={v => handleValuesChange(v, 'organizationId')}
              values={[{ id: '', name: 'Выберите организацию' }, ...selectOrganizationOptions]}
              error={errors.organizationId && [errors.organizationId]}
            />
          </div>
          <div className="d-flex w-full form-wrap gap-2">
            <Input
              style={{ minHeight: 42.7 }}
              fieldClasses="w-full"
              type="date"
              label="Дата приглашения"
              error={errors.time && [errors.time]}
              value={values.date}
              onChange={v => handleValuesChange(v, 'date')}
            />
            <Input
              fieldClasses="w-full"
              type="time"
              label="Время приглашения"
              value={values.timeValue}
              onChange={v => handleValuesChange(v, 'timeValue')}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
