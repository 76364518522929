import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';

const Breadcrumbs = ({ linkRoute, liClassName }) => {
  const { length } = linkRoute;

  return (
    <ul className="cmp-breadcrumbs f-300 h5 mb-4 h6">
      {linkRoute.map((item, index) => (
        <li className={liClassName} key={item.name}>
          {index + 1 === length ? <span>{item.name}</span> : <Link to={item.link}>{item.name}</Link>}
        </li>
      ))}
    </ul>
  );
};

Breadcrumbs.propTypes = {
  linkRoute: PropTypes.any,
  liClassName: PropTypes.string,
};

export default Breadcrumbs;
