export const prepareSearchParams = values => {
  if (!values.length) return null;

  const url = new URL(window.location.href);
  const params = new window.URLSearchParams(window.location.search);

  for (const el of values) {
    const val = String(el.value);

    if (String(val).length) {
      params.set(el.key, String(val).trim());
    } else {
      params.delete(el.key);
    }
  }

  const searchParams = decodeURIComponent(String(params).replace(/\+/g, '%20'));

  return {
    fullUrl: `${url.pathname}?${searchParams}`,
    search: searchParams,
  };
};
