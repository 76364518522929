import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Cancel } from '../../icons';
import './index.scss';

const Modal = ({ children, show, onClose, modalTitle, footer = null, windowScroll = true }) => {
  const closeClick = e => {
    e.target.classList.contains('modal') && onClose();
  };

  useEffect(() => {
    const html = document.documentElement;

    if (!windowScroll && html) {
      if (show) {
        html.classList.add('overflow-y-hidden');
        html.classList.add('h-full');

        return;
      }

      html.classList.remove('overflow-y-hidden');
      html.classList.remove('h-full');
    }
  }, [show]);

  if (!show) return null;

  return (
    <div className={'modal scroll-y scroll-y-custom'} onClick={closeClick}>
      <div className="block d-flex flex-column">
        <div className="block-head pr-5">
          {modalTitle}
          <button className="close flex cursor-pointer justify-center items-center transition-all" onClick={onClose}>
            <Cancel />
          </button>
        </div>

        <div className="block-content scroll-y d-flex flex-column flex-fill  scroll-y-custom">{children}</div>

        {footer && <div className="block-bottom">{footer}</div>}
      </div>
    </div>
  );
};

export default Modal;

Modal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  show: PropTypes.any,
  onClose: PropTypes.func,
  modalTitle: PropTypes.string,
  footer: PropTypes.any,
};
