import PropTypes from 'prop-types';
import * as React from 'react';

const ResetFilters = ({ size = 15, color = '#f6f6f6', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill={color} viewBox="0 0 24 24" {...props}>
      <path d="M23.707,22.293l-7.414-7.414,4.345-4.478c.903-.93,1.4-2.155,1.4-3.451,0-2.73-2.221-4.951-4.951-4.951H6.951c-.973,0-1.909,.297-2.712,.825L1.707,.293C1.316-.098,.684-.098,.293,.293S-.098,1.316,.293,1.707L22.293,23.707c.195,.195,.451,.293,.707,.293s.512-.098,.707-.293c.391-.391,.391-1.023,0-1.414ZM6.951,4h10.137c1.627,0,2.951,1.324,2.951,2.951,0,.772-.296,1.503-.835,2.058l-4.324,4.456L5.703,4.289c.389-.182,.81-.289,1.247-.289Zm8.049,15v4c0,.379-.214,.725-.553,.895-.142,.071-.295,.105-.447,.105-.212,0-.423-.068-.6-.2l-4-3c-.252-.188-.4-.485-.4-.8v-3.844L3.401,10.402c-.426-.438-.764-.946-1.004-1.51-.216-.508,.02-1.096,.528-1.312,.508-.218,1.095,.019,1.312,.528,.143,.336,.344,.639,.598,.899l5.882,6.045c.182,.187,.283,.437,.283,.697v3.75l2,1.5v-2c0-.552,.448-1,1-1s1,.448,1,1Z" />
    </svg>
  );
};

ResetFilters.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default ResetFilters;
