import PropTypes from 'prop-types';
import { memo } from 'react';
import { Select } from 'antd';

export const SelectMultiple = memo(
  ({
    label = null,
    fieldClasses = '',
    disabled = false,
    onChange = () => {},
    values = [],
    error = null,
    mode = 'multiple',
    ...props
  }) => {
    const filterOption = (input, option) => {
      return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    return (
      <label className={`form-field ${fieldClasses}`}>
        {label && <span className={`form-label ${disabled ? 'cursor-default' : ''}`}>{label}</span>}

        <Select
          optionFilterProp="children"
          mode={mode}
          onChange={onChange}
          style={{ width: '100%' }}
          allowClear
          showSearch
          filterOption={filterOption}
          {...props}
        >
          {values.map(v => {
            return (
              <Select.Option key={v.id} value={v.id}>
                {v.name}
              </Select.Option>
            );
          })}
        </Select>

        {error && <div className="form-error">{error}</div>}
      </label>
    );
  }
);

SelectMultiple.displayName = 'SelectMultiple';

SelectMultiple.propTypes = {
  label: PropTypes.any,
  values: PropTypes.array,
  fieldClasses: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.any,
};
