import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useFetch } from '../../../../hooks/useFetch';
import { createUrlQuery } from '../../../../utils/createApiUrl';
import { Breadcrumbs, Input } from '../../../components';
import useUrl from '../../../../hooks/useUrl';
import './InvitationViewPage.scss';
import { Descriptions, message } from 'antd';
import { InvitationFilesTable } from './elems/InvitationFilesTable';
import { useInvitationViewPage } from './useInvitationViewPage';
import Index from '../../../components/Spinner';
import { apiPost } from '../../../../api';
import { toggleUpdateInvitationModal } from '../../../../redux/actions/dk';
import { InvitationUpdateModal } from '../InvitationUpdateModal';
import { InvitationVar } from './elems/InvitationVar';

const InvitationViewPage = () => {
  const { linkRoute, loading, response, id, downloadFile, dispatch, handleConfirmDeleteInvitation, sendInvitation } =
    useInvitationViewPage();

  const saveVar = async (name, value) => {
    const { error } = await apiPost({
      url: `/dk-invitation/set-var-value${createUrlQuery({ id, name })}`,
      postData: { value },
    });
    if (!error) {
      message.success('Успешно изменено!');
    }
  };

  const updateInvitation = () => {
    dispatch(toggleUpdateInvitationModal({ show: true, invitation: response }));
  };

  if (loading)
    return (
      <div className="d-flex jc-center">
        <Index size={60} />
      </div>
    );

  return (
    <div>
      <Breadcrumbs liClassName="breadcrumbs-li" linkRoute={linkRoute} />
      <div className="card">
        <div className="card-header jc-between d-flex ai-center">Приглашение № {id}</div>
        <div className="card-content">
          <div className=" d-flex ai-center gap-2">
            <button onClick={updateInvitation} className="btn btn-warning ">
              Редактировать
            </button>
            <button onClick={downloadFile} className="btn btn-success ">
              Скачать
            </button>
            <button onClick={sendInvitation} className="btn btn-primary">
              Отправить приглашение
            </button>
            <button onClick={handleConfirmDeleteInvitation} className="btn btn-danger ">
              Удалить
            </button>
          </div>
        </div>
      </div>

      <Descriptions className=" mb-4" bordered column={1}>
        <Descriptions.Item style={{ padding: '10px' }} label="Дата и Время">
          {response?.timeAsString}
        </Descriptions.Item>
        <Descriptions.Item style={{ padding: '10px' }} label="Статус">
          {response?.statusText}
        </Descriptions.Item>
        <Descriptions.Item style={{ padding: '10px' }} label="Номер исходящего">
          {response?.outgoing_number}
        </Descriptions.Item>
        <Descriptions.Item style={{ padding: '10px' }} label="Дата исходящего">
          {response?.outgoingDateAsString}
        </Descriptions.Item>
        <Descriptions.Item style={{ padding: '10px' }} label="Наименование организации">
          {response?.organization.name}
        </Descriptions.Item>
        <Descriptions.Item style={{ padding: '10px' }} label="ИНН">
          {response?.organization.inn}
        </Descriptions.Item>
        <Descriptions.Item style={{ padding: '10px' }} label="ОГРН">
          {response?.organization.ogrn}
        </Descriptions.Item>
        <Descriptions.Item style={{ padding: '10px' }} label="Причина приглашения">
          {response?.dkReason.name}
        </Descriptions.Item>
      </Descriptions>

      <div className="card">
        <div className="card-header jc-between d-flex ai-center">Переменные</div>
        {!!response?.vars.length && (
          <div className="card-content gap-2">
            {response?.vars?.map(({ name, value }) => (
              <InvitationVar
                statusInvitation={response?.status}
                key={name}
                value={value}
                onSave={saveVar}
                name={name}
              />
            ))}
          </div>
        )}
      </div>

      <InvitationFilesTable />

      <InvitationUpdateModal />
    </div>
  );
};

export default InvitationViewPage;
