import React, { useState } from 'react';
import { Input, Modal, Select } from '../../../../components';
import Dropzone from '../../../../components/Dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { modalApi } from '../../../../../redux/actions/modal';
import { useForm } from 'react-hook-form';
import { apiPost } from '../../../../../api';
import { createUrlQuery } from '../../../../../utils/createApiUrl';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import { errorParse } from '../../../../../utils/errorParse';
import PropTypes from 'prop-types';

export const InvitationAddFileModal = ({ fetchData }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isVisible = useSelector(state => state.modal.name) === 'invitation-add-file';
  const { setValue, watch, register, formState, reset, handleSubmit, setError } = useForm({
    defaultValues: { name: '', file: null },
  });
  const [files, setFiles] = useState(null);

  const addFile = async form => {
    const postData = new FormData();
    postData.append('name', form.name);
    postData.append('file', files[0]);
    const { error, data, status } = await apiPost({
      url: `/dk-invitation/upload${createUrlQuery({ id })}`,
      postData,
    });

    if (status === 406) {
      errorParse(data, setError);

      return;
    }

    if (!error) {
      message.success('Добавлено');
      fetchData();
      hideModal();
    }
  };
  const hideModal = () => {
    reset();
    setFiles(null);
    dispatch(modalApi(null));
  };

  return (
    <Modal
      footer={
        <button className="btn btn-bg-acent" onClick={handleSubmit(addFile)}>
          Добавить
        </button>
      }
      modalTitle="Добавить файл"
      show={isVisible}
      onClose={hideModal}
    >
      <div className="d-flex flex-column">
        <Input
          register={register}
          name="name"
          fieldClasses="w-full"
          error={formState.errors.name && [formState.errors.name.message]}
          label="Название файла"
        />

        <Dropzone
          maxSize={1000000000000000}
          onFileRemove={() => setFiles(null)}
          files={Array.isArray(files) ? [...files] : []}
          errorMessage={formState.errors.file && formState.errors.file.message}
          multiple={false}
          onFileUpload={f => setFiles(f)}
        />
      </div>
    </Modal>
  );
};

InvitationAddFileModal.propTypes = {
  fetchData: PropTypes.func,
};
