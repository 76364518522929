/* eslint-disable */
import React, { forwardRef, Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useHistory, useLocation, useParams } from 'react-router-dom';
import { apiGet } from '../../../api';
import { useFetch } from '../../../hooks/useFetch';
import {
  countNotReprimanded,
  getUnReadMessages,
  getUnReadMessagesIncoming,
  toggleAddFolderChat,
} from '../../../redux/actions/user';
import {
  AllMessages,
  ArrowLeft,
  IconChat,
  IconEvents,
  IconReportPeriod,
  IconReports,
  IconReportTemplates,
  IconUsers,
  Plus,
  UnSortMessages,
} from '../../icons';
import Calendar from '../../icons/Menu/Calendar';
import UnreadMessage from '../../icons/UnreadMessage';
import './index.scss';
import { DkSubmenu } from './elems/DkSubmenu/DkSubmenu';
import { showDk, toggleDk, toggleNewArchiveModal } from '../../../redux/actions/dk';
import { DkNewArchiveModal } from './elems/DkNewArchiveModal/DkNewArchiveModal';
import ManyPeople from '../../icons/ManyPeople';
import { getFolderIdUrl } from '../../../utils/getFolderId';
import getUrlParams from '../../../hooks/getUrlParams';
import Folder from '../../icons/Folder';

const Menu = forwardRef((props, ref) => {
  const notViewShow = new RegExp(`\\?onlyNotViewed=[0-9]&page=[0-9]`, 'g');

  const location = useLocation();

  const state = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const [urlPage, setUrlPage] = useState(window.location.pathname);
  const isDkPages =
    urlPage.includes('reason') ||
    urlPage.includes('invitation') ||
    urlPage.includes('extract') ||
    urlPage.includes('protocol') ||
    urlPage.includes('dk-organization') ||
    urlPage.includes('organization-update');
  const { response: ticketsFolders, fetchData: refreshTicketsFolders } = useFetch({
    url: `/mail-message-group`,
  });
  const folderListQuery = useFetch({ url: '/dk-folder' });

  const { archive } = getUrlParams();
  const archiveName = isDkPages ? folderListQuery?.response?.models?.find(el => el.id === +archive) : '';

  useEffect(() => {
    refreshTicketsFolders();
  }, [state.user.dataInboxFolders]);

  const getUnreadMessagesAll = () => {
    apiGet({ url: `/mail-message/not-viewed-count` }).then(res => {
      dispatch(getUnReadMessages(res.data));
    });
  };

  const getUnreadableMsgIncoming = () => {
    apiGet({ url: `/mail-message/not-viewed-count?group=0` }).then(res => {
      dispatch(getUnReadMessagesIncoming(res.data));
    });
  };

  const getNotReprimanded = () => {
    apiGet({ url: `/report/not-reprimanded-count` }).then(res => {
      dispatch(countNotReprimanded(res.data));
    });
  };

  useEffect(() => {
    setUrlPage(window.location.pathname);
  }, [window.location.pathname]);

  useEffect(() => {
    getUnreadMessagesAll();
    getNotReprimanded();
    getUnreadableMsgIncoming();
  }, []);

  // messageGroupShow.test(location.search) ? "_active" : ""

  const searchGetActiveClassName = reg => {
    let result = '';
    const arr = [];
    reg.forEach(e => arr.push(!!location.search.match(e)));

    if (!arr.includes(false)) {
      result = '_active';
    }

    return result;
  };

  const showAddNewArchiveModal = e => {
    e.stopPropagation();
    dispatch(toggleNewArchiveModal(true));
  };

  return (
    <div ref={ref} id="menu" className="scroll-y scroll-y-custom">
      {isDkPages && (
        <Link onClick={() => dispatch(showDk())} to="/" className="archive_name">
          <ArrowLeft className="menu-back-icon" color="#5B73E8FF" size={24} />
          <Folder className="menu-folder-icon" size={18} color="#5B73E8FF" />
          <span className="archive_name_text">Архив {archiveName?.name}</span>
        </Link>
      )}

      {urlPage === '/inbox' && (
        <Link style={{ cursor: 'pointer' }} to="/" className="btn-primary inbox-exit">
          Назад
        </Link>
      )}

      {urlPage.indexOf('/message/') >= 0 && (
        <div style={{ cursor: 'pointer' }} onClick={() => history.goBack()} className="btn-primary inbox-exit">
          Назад
        </div>
      )}

      <div className="name mb-3 d-flex jc-between">
        <span>Меню</span>
      </div>
      {isDkPages ? (
        <div className="d-flex flex-column">
          <NavLink
            activeClassName="invitation-link_active"
            className="invitation-link"
            to={`/reason/${getUrlParams().archive}${getFolderIdUrl()}`}
          >
            Причины приглашений
          </NavLink>
          <NavLink
            activeClassName="invitation-link_active"
            className="invitation-link"
            to={`/invitation/${getUrlParams().archive}${getFolderIdUrl()}`}
          >
            Приглашения
          </NavLink>
          <NavLink
            activeClassName="invitation-link_active"
            className="invitation-link"
            to={`/protocol/${getUrlParams().archive}${getFolderIdUrl()}`}
          >
            Протоколы
          </NavLink>
          <NavLink
            activeClassName="invitation-link_active"
            className="invitation-link"
            to={`/extract/${getUrlParams().archive}${getFolderIdUrl()}`}
          >
            Журнал выписок
          </NavLink>
        </div>
      ) : (
        <>
          {urlPage === '/inbox' || urlPage.indexOf('/message/') >= 0 ? (
            <>
              <div className="link-folder">
                <div className="pos">
                  <NavLink
                    activeClassName={searchGetActiveClassName([/mail_message_group_id=0/g, /from=1/g])}
                    className="link"
                    to={`/inbox?mail_message_group_id=0&from=1`}
                  >
                    <AllMessages className="pos-abs-y" />
                    Входящие
                    {!!+state.user.unreadMessages && (
                      <span className="new-message-in-folder new-message-in-folder-with-add">
                        {state.user.countNotReprimandedIncoming}
                      </span>
                    )}
                  </NavLink>
                  <NavLink
                    activeClassName={searchGetActiveClassName([/from=2/g])}
                    className="link"
                    to={`/inbox?from=2&page=1`}
                  >
                    <UnSortMessages className="pos-abs-y" />
                    Исходящие
                  </NavLink>

                  <NavLink
                    activeClassName={notViewShow.test(location.search) ? '_active' : ''}
                    className="link"
                    to={`/inbox?onlyNotViewed=1&page=1`}
                  >
                    <UnreadMessage className="pos-abs-y" />
                    Не прочитанные
                  </NavLink>

                  {state.user.accessUser[`ticket-group`].create && (
                    <div className="add-folder" onClick={() => dispatch(toggleAddFolderChat(true))}>
                      <Plus color={localStorage.getItem('theme') === 'dark' ? '#f6f6f6' : '#7b8190'} />
                    </div>
                  )}
                </div>
                {ticketsFolders?.data.map(item => {
                  return (
                    <NavLink
                      key={item.name}
                      activeClassName={searchGetActiveClassName([`mail_message_group_id=${item.id}`])}
                      className="link"
                      to={`/inbox?mail_message_group_id=${item.id}&page=1`}
                    >
                      {item.name}
                      {!!+item.newMessageCount && (
                        <span className="new-message-in-folder ">
                          <span className="num">{item.newMessageCount}</span>
                        </span>
                      )}
                    </NavLink>
                  );
                })}
              </div>
            </>
          ) : (
            <>
              {state.user.accessUser.user.index && (
                <NavLink activeClassName="_active" className="link" to="/users">
                  <IconUsers className="pos-abs-y" />
                  Пользователи
                </NavLink>
              )}
              {/* <NavLink activeClassName="_active" className="link" to="/mfo">
						<IconMfo className="pos-abs-y" />
						Организации
					</NavLink> */}
              {state.user.accessUser[`report-template`].index && (
                <NavLink activeClassName="_active" className="link" to="/reports-template">
                  <IconReportTemplates className="pos-abs-y" />
                  Шаблоны отчетов
                </NavLink>
              )}
              {state.user.accessUser[`report-period`].index && (
                <NavLink activeClassName="_active" className="link" to="/reports-period">
                  <IconReportPeriod className="pos-abs-y" />
                  Отчетный период
                </NavLink>
              )}
              {state.user.accessUser.report.index && (
                <NavLink activeClassName="_active" className="link" to="/reports">
                  <IconReports className="pos-abs-y" />
                  Отчеты
                  <span className="new-message-in-folder">{state.user.countNotReprimanded}</span>
                </NavLink>
              )}
              {state.user.accessUser.event.index && (
                <NavLink activeClassName="_active" className="link" to="/events">
                  <Calendar className="pos-abs-y" />
                  Мероприятия
                </NavLink>
              )}

              <NavLink activeClassName="_active" className="link" to="/calendar">
                <IconEvents className="pos-abs-y" />
                Календарь
              </NavLink>

              {state.user.accessUser.ticket.index && (
                <NavLink activeClassName="_active" className="link" to="/inbox?mail_message_group_id=0&from=1">
                  <IconChat className="pos-abs-y" />
                  Сообщения{' '}
                  {state.user.unreadMessages !== 0 && (
                    <span className="new-message-in-folder">{state.user.unreadMessages}</span>
                  )}
                </NavLink>
              )}

              {state.user.accessUser['report-archive'].index && (
                <NavLink activeClassName="_active" className="link" to="/report-archives">
                  <IconReports className="pos-abs-y" />
                  Архивы отчетов
                </NavLink>
              )}

              {state.user.accessUser['report-archive'].index && (
                <NavLink activeClassName="_active" className="link" to="/requirement">
                  <IconReports className="pos-abs-y" />
                  Журнал исходящих требований
                </NavLink>
              )}

              <div onClick={() => dispatch(toggleDk())} className="d-flex cursor-pointer jc-between ai-center">
                <div className="link mb-0">
                  <ManyPeople className="pos-abs-y" />
                  ДК
                </div>

                <div className="d-flex ai-center ">
                  <Plus
                    onClick={showAddNewArchiveModal}
                    className="add-folder-plus-icon"
                    color={localStorage.getItem('theme') === 'dark' ? '#f6f6f6' : '#7b8190'}
                  />
                  {!!folderListQuery?.response?.models?.length && (
                    <svg
                      className={`cursor-pointer collapse-arrow ${
                        state.dk.isVisibleFolders && 'collapse-arrow-rotate'
                      }`}
                      fill={localStorage.getItem('theme') === 'dark' ? '#f6f6f6' : '#7b8190'}
                      xmlns="http://www.w3.org/2000/svg"
                      id="Outline"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path d="M18.71,8.21a1,1,0,0,0-1.42,0l-4.58,4.58a1,1,0,0,1-1.42,0L6.71,8.21a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.59,4.59a3,3,0,0,0,4.24,0l4.59-4.59A1,1,0,0,0,18.71,8.21Z" />
                    </svg>
                  )}
                </div>
              </div>

              {!folderListQuery.loading && <DkSubmenu {...folderListQuery} />}
            </>
          )}
        </>
      )}

      <DkNewArchiveModal {...folderListQuery} />
    </div>
  );
});

export default Menu;
