import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { apiGet, apiPost } from '../../../../api';
import { STATUS_CLASS_LIST } from '../../../../global';
import { useFetch } from '../../../../hooks/useFetch';
import useUrl from '../../../../hooks/useUrl';
import { countNotReprimanded } from '../../../../redux/actions/user';
import hospital from '../../../../static/img/icons/hospital.svg';
import { goToLink } from '../../../../utils/goToLink';
import { Breadcrumbs, Dropzone, Input, Modal, Pagination, Select } from '../../../components';
import { Plus } from '../../../icons';
import './index.scss';

const MfoReports = () => {
  const { id } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();

  const { page } = useUrl();

  const [file, setFile] = useState([]);
  const [sending, setSending] = useState(false);
  const [sorting, setSorting] = useState('-instanceUploadDate');
  const [reportId, setReportId] = useState('');
  const [modalUpload, setModalUpload] = useState(false);
  const [incomingNum, setIncomingNum] = useState('');
  const [checkOnChecking, setCheckOnChecking] = useState(false);
  const [errObj, setErrObj] = useState({});
  const [yearReportUpload, setYearReportUpload] = useState(new Date().getFullYear());

  const { response: organizationInfo } = useFetch({
    url: `/organization/view?id=${id}`,
  });

  const { response: reportList, fetchData: restartPage } = useFetch({
    url: `/report/organization?organization_id=${id}&page=${page || 1}&limit=10&sort=${sorting}`,
  });

  const getSortClass = sortType => {
    if (sorting.match(sortType)) {
      if (sorting === `-${sortType}`) {
        return '_desc';
      } else {
        return '_asc';
      }
    }

    return '';
  };

  const getDocAlert = () => {
    confirmAlert({
      message: 'Пожалуйста добавьте отчет',
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  const sortTable = sortType => {
    if (sorting.match(sortType)) {
      if (sorting === `-${sortType}`) {
        setSorting(sortType);
      } else {
        setSorting(`-${sortType}`);
      }
    } else {
      setSorting(sortType);
    }
  };

  const openModalUpload = id => {
    setReportId(id);
    setModalUpload(true);
  };

  const handleUploadFiles = e => {
    setFile(e);
  };

  const emptyIncomingNum = () => {
    confirmAlert({
      message: 'Пожалуйста введите входящий номер или установите отчет как отчет на проверку',
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  const uploadDoc = () => {
    if (!checkOnChecking && incomingNum === '') {
      emptyIncomingNum();
      return;
    }
    if (checkOnChecking) {
      setIncomingNum('');
    }
    if (file == null) {
      getDocAlert();
      return;
    }

    if (!sending) {
      setSending(true);

      const postData = new FormData();
      !checkOnChecking && postData.append('incomingNumber', `ТР-${yearReportUpload}-${incomingNum}`);
      file.forEach(file => {
        postData.append('file', file);
      });

      apiPost({
        url: `/report-instance/upload?report_id=${reportId}&organization_id=${id}`,
        postData,
      })
        .then(res => {
          if (res.error) {
            setErrObj(res.data);
            return;
          }
          setErrObj({});
          setCheckOnChecking(false);
          setIncomingNum('');
          setFile([]);
          setModalUpload(false);
          restartPage();

          apiGet({ url: '/report/not-reprimanded-count' }).then(res => {
            dispatch(countNotReprimanded(res.data));
          });
        })
        .finally(() => setSending(false));
    }
  };

  const linkRoute = [
    {
      name: 'Пользователи',
      link: '/users',
    },
    {
      name: 'Просмотр пользователя',
      link: `/view-user/${id}`,
    },
    {
      name: 'Отчеты организации',
      link: `/mfo-reports/${id}`,
    },
  ];

  const funcIncomingNum = e => {
    setErrObj({});
    if (e.length >= 9) {
      setErrObj({ incNum: 'Максимальное количество цифр - 8' });
      return;
    }
    setIncomingNum(e);
  };

  useEffect(() => {
    setErrObj({});
  }, [file]);

  return (
    <>
      <Breadcrumbs linkRoute={linkRoute} />
      <div className="card mfo-reports">
        <div className="card-header d-flex">
          <div className="name">Отчеты организации</div>

          <div className="close-modal close-modal-view-mfo">
            <img src="/static/img/icon/cancel.svg" alt="" />
          </div>
        </div>

        <div className="card-content">
          <div className="row">
            <div className="col-12">
              <Input label="Название организации" disabled value={organizationInfo?.name || ''} onChange={() => {}} />
            </div>
            <div className="col-12 col-md-4">
              <Input label="Username" disabled value={organizationInfo?.username || ''} onChange={() => {}} />
            </div>
            <div className="col-12 col-md-4">
              <Input label="ИНН" disabled value={organizationInfo?.inn || ''} onChange={() => {}} />
            </div>
            <div className="col-12 col-md-4">
              <Input label="ОГРН" disabled value={organizationInfo?.ogrn || ''} onChange={() => {}} />
            </div>
          </div>

          <div className="scroll-x">
            <table className="big-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Период</th>
                  <th>Дата</th>
                  <th>Название шаблона</th>
                  <th onClick={() => sortTable('instanceUploadDate')} style={{ cursor: 'pointer' }}>
                    <div className="d-flex">
                      <span>Дата загрузки</span>
                      <div className={`sort ${getSortClass('instanceUploadDate')}`} />
                    </div>
                  </th>
                  <th onClick={() => sortTable('organizationStatus')} style={{ cursor: 'pointer' }}>
                    <div className="d-flex">
                      <span>Статус</span>
                      <div className={`sort ${getSortClass('organizationStatus')}`} />
                    </div>
                  </th>
                  <th>Не выгруженные</th>
                  <th />
                </tr>
              </thead>

              <tbody>
                {reportList?.models.map(item => (
                  <tr
                    className={'go-link'}
                    key={item.id}
                    style={{ cursor: 'pointer' }}
                    onClick={e => goToLink(e, () => history.push(`/mfo-reports-examples/${id}/${item.id}`))}
                  >
                    <td>{item.id}</td>
                    <td>{item.period.name}</td>
                    <td>
                      {item.period.startDate}-{item.period.endDate}
                    </td>
                    <td style={{ width: '300px' }}>{item.template.name}</td>
                    <td>{item.uploadTime}</td>
                    <td>
                      <div className={`badge badge-${STATUS_CLASS_LIST[item.status]}`}>{item.statusText}</div>
                    </td>
                    <td>
                      {item.isNotReprimanded && (
                        <div
                          style={{
                            borderRadius: '100%',
                            backgroundColor: '#5b73e8',
                            display: 'inline-flex',
                            padding: '5px',
                          }}
                        >
                          <img style={{ width: '12px' }} src={hospital} />
                        </div>
                      )}
                    </td>
                    <td className="ta-right d-flex">
                      {/* <div className="col-auto">
												<Link
													to={`/mfo-reports-examples/${id}/${item.id}`}
													className="action-btn btn btn-primary"
												>
													<View />
												</Link>
											</div> */}
                      <div className="col-auto">
                        {organizationInfo?.isDeleted === false && (
                          <button
                            className="action-btn btn btn-success"
                            onClick={() => openModalUpload(item.id)}
                            title="Загрузить отчет"
                          >
                            <Plus />
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-2 ta-center">
            <Pagination lastPage={reportList?.lastPage} page={page} />
            {/* <ReactPaginate
							pageCount={reportList?.lastPage == 1 ? 0 : reportList?.lastPage}
							pageRangeDisplayed={5}
							marginPagesDisplayed={3}
							onPageChange={e => setPage(e.selected + 1)}
							activeClassName={"active"}
							containerClassName={"pagination d-inline-flex ta-center"}
							pageLinkClassName={"link"}
							pageClassName={"link"}
							previousLabel={false}
							nextLabel={false}
						/> */}
          </div>
          <Modal
            show={modalUpload}
            onClose={() => setModalUpload(false)}
            modalTitle="Загрузка отчета"
            footer={
              <button className="btn btn-success" onClick={() => uploadDoc()}>
                Загрузить
              </button>
            }
          >
            <div className="col-auto mb-3" style={{ display: 'inline-block' }}>
              <label className="d-flex">
                <input
                  type="checkbox"
                  className="checkbox mr-2"
                  checked={checkOnChecking}
                  onChange={() => setCheckOnChecking(!checkOnChecking)}
                />
                <span>Отправка отчета на проверку</span>
              </label>
            </div>
            {!checkOnChecking && (
              <>
                <div className="d-flex">
                  <span className="mt-auto mb-auto">ТР-</span>
                  <Select
                    fieldClasses="mt-auto mb-auto"
                    style={{ minWidth: '75px' }}
                    onChange={e => setYearReportUpload(e)}
                    value={yearReportUpload}
                    values={[
                      {
                        value: new Date().getFullYear() - 1,
                        name: new Date().getFullYear() - 1,
                      },
                      {
                        value: new Date().getFullYear(),
                        name: new Date().getFullYear(),
                      },
                      {
                        value: new Date().getFullYear() + 1,
                        name: new Date().getFullYear() + 1,
                      },
                    ]}
                  />
                  <span className="mt-auto mb-auto">-</span>
                  <Input
                    fieldClasses="mt-auto mb-auto"
                    value={incomingNum}
                    onChange={funcIncomingNum}
                    errObj={errObj.incNum}
                  />
                </div>
                {errObj.incNum && <span className="_error">{errObj.incNum}</span>}
              </>
            )}

            <div className="mt-3">
              <Dropzone
                maxSize={1000000000}
                onFileRemove={() => setFile([])}
                onFileUpload={handleUploadFiles}
                styles={{ height: 120 }}
                files={file}
                error={errObj.file}
              />
              {errObj.file &&
                errObj.file.map(i => (
                  <span key={i} className="_error">
                    {i}
                  </span>
                ))}
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default MfoReports;
