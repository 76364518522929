/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { apiGet, apiPost } from '../../../../api/index';
import { updateProfile } from '../../../../redux/actions/user';
import { Input, Modal } from '../../../components';
import Breadcrumbs from '../../../components/Breadcrumbs';
import './index.scss';
import { Pencil } from '../../../icons/Pencil';
import { OrganizationAttributeModal } from '../OrganizationAttributeModal/OrganizationAttributeModal';
import { resetCurrentAttribute, setCurrentAttribute } from '../../../../redux/actions/organizationAttrubute';

const ViewUser = () => {
  const { userId } = useParams();
  const state = useSelector(state => state);
  const dispatch = useDispatch();
  const [viewModal, setViewModal] = useState(false);
  const [valueEdit, setValueEdit] = useState(true);
  const [company, setCompany] = useState(false);
  const [compId, setCompId] = useState('');
  const [sending, setSending] = useState(false);
  const [companyErrObj, setCompanyErrObj] = useState({});
  const [dataListOkopf, setDataListOkopf] = useState([]);
  const [profileErrObj, setProfileErrObj] = useState({});

  const [createNameComp, setCreateNameComp] = useState('');
  const [createInnComp, setCreateInnComp] = useState('');
  const [createOgrnComp, setCreateOgrnComp] = useState('');
  const [createRegNumComp, setCreateRegNumComp] = useState('');
  const [createOkopfComp, setCreateOkopfComp] = useState('');
  const [organizationInStakeholders, setOrganizationInStakeholders] = useState(0);
  const [businessFinancing, setBusinessFinancing] = useState(0);
  const [msp, setMsp] = useState(0);

  const [createNameFull, setCreateNameFull] = useState('');
  const [createOkato, setCreateOkato] = useState('');
  const [createOktmo, setCreateOktmo] = useState('');
  const [createAddress, setCreateAddress] = useState('');

  const [userDataEmail, setUserDataEmail] = useState('');
  const [userDataPhone, setUserDataPhone] = useState('');
  const [userDataLastName, setUserDataLastName] = useState('');
  const [userUserName, setUserUserName] = useState('');
  const [userDataName, setUserDataName] = useState('');
  const [userDataPatronymic, setUserDataPatronymic] = useState('');

  const [mfoName, setMfoName] = useState('');
  const [mfoInn, setMfoInn] = useState('');
  const [mfoOgrn, setMfoOgrn] = useState('');
  const [mfoRegNum, setMfoRegNum] = useState('');
  const [mfoOkopf, setMfoOkopf] = useState('');
  const [sendedMfoOkopf, setSendedMfoOkopf] = useState('');

  const [mfoNameFull, setmfoNameFull] = useState('');
  const [mfoOkato, setmfoOkato] = useState('');
  const [mfoOktmo, setmfoOktmo] = useState('');
  const [mfoAddress, setmfoAddress] = useState('');

  const [errorUpdateProfile, setErrorUpdateProfile] = useState(false);
  const [errorUpdateMfo, setErrorUpdateMfo] = useState(false);

  const showModal = isShow => {
    setViewModal(isShow);
  };

  const toggleInputEdit = () => {
    setErrorUpdateMfo(true);
    setErrorUpdateProfile(true);
    setValueEdit(false);
  };

  // const updateDataAlert = () => {
  // 	confirmAlert({
  // 		message: "Данные успешно обновлены",
  // 		buttons: [
  // 			{
  // 				label: "Ок",
  // 				onClick: () => {},
  // 			},
  // 		],
  // 	})
  // }

  const createMfoAlert = () => {
    confirmAlert({
      message: 'Организация успешно создана',
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  const errorDataAlert = error => {
    confirmAlert({
      message: error,
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  const getOkpfData = () => {
    apiGet({ url: '/organization/okopf-list' }).then(res => {
      if (res.error) {
        console.error('Получение списка ОКОПФ');
        return;
      }
      let arr = res.data;
      arr.unshift(null);
      setDataListOkopf(arr);
    });
  };

  // Отображение пользователя
  const viewUser = () => {
    apiGet({ url: `/user/view?id=${userId}` }).then(res => {
      if (res.error) {
        console.error('Отображение пользователя');
        return;
      }

      const { email, lastName, name, patronymic, username, phone } = res.data;
      setUserDataPhone(phone);
      setUserDataEmail(email);
      setUserDataLastName(lastName);
      setUserUserName(username);
      setUserDataName(name);
      setUserDataPatronymic(patronymic);
    });
  };

  // Редактирование пользователя
  const updateUserProfile = () => {
    if (!sending) {
      setSending(true);

      apiPost({
        url: `/user/update?id=${userId}`,
        postData: {
          username: userUserName,
          email: userDataEmail,
          name: userDataName,
          lastName: userDataLastName,
          patronymic: userDataPatronymic,
          phone: userDataPhone,
        },
      })
        .then(res => {
          if (res.error) {
            setProfileErrObj(res.data);
            setErrorUpdateMfo(true);
            return;
          }

          if (state?.user.profile.id == res.data.id) {
            dispatch(updateProfile(res.data));
          }

          setProfileErrObj({});
          setErrorUpdateProfile(false);
        })
        .finally(() => setSending(false));
    }
  };

  // Изменение организации
  const updateCompany = () => {
    if (sendedMfoOkopf == '') {
      setSendedMfoOkopf(null);
    }
    if (!sending && company) {
      setSending(true);

      const postData = {
        user_id: userId,
        name: mfoName,
        inn: mfoInn,
        ogrn: mfoOgrn,
        okopf: sendedMfoOkopf,
        reg_number: mfoRegNum,
        name_full: mfoNameFull,
        okato: mfoOkato,
        oktmo: mfoOktmo,
        address: mfoAddress,
        organizations_in_stakeholders: organizationInStakeholders,
        business_financing: businessFinancing,
        msp: msp,
      };

      apiPost({ url: `/organization/update?id=${compId}`, postData })
        .then(res => {
          if (res.error) {
            setCompanyErrObj(res.data);
            setErrorUpdateProfile(true);
            return;
          }
          setCompanyErrObj({});
          setErrorUpdateMfo(false);
        })
        .finally(() => setSending(false));
    }
  };

  // Отображение организация
  const getUserCompany = () => {
    apiGet({ url: `/organization/view-by-user?user_id=${userId}` }).then(res => {
      if (res.error) {
        console.error('Отображение организации');
        return;
      }

      if (!res.data) {
        setCompany(false);
        return;
      }

      const {
        id,
        name,
        inn,
        ogrn,
        okopf,
        reg_number,
        name_full,
        okato,
        oktmo,
        address,
        organizations_in_stakeholders,
        business_financing,
        msp,
      } = res.data;
      setCompId(id);
      setMfoName(name);
      setMfoInn(inn);
      setMfoOgrn(ogrn);
      setMfoRegNum(reg_number);
      setMfoOkopf(okopf);
      setSendedMfoOkopf(okopf);
      setmfoNameFull(name_full);
      setmfoOkato(okato);
      setmfoOktmo(oktmo);
      setmfoAddress(address);
      setCompany(true);
      setOrganizationInStakeholders(organizations_in_stakeholders ? 1 : 0);
      setBusinessFinancing(business_financing ? 1 : 0);
      setMsp(msp ? 1 : 0);
    });
  };

  const emptyMfo = () => {
    confirmAlert({
      message: 'Пожалуйста введите название организации',
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  // Создание организации
  const createCompany = () => {
    if (createNameComp == '') {
      emptyMfo();
      return;
    }
    if (!sending) {
      setSending(true);

      const postData = {
        user_id: userId,
        name: createNameComp,
        inn: createInnComp,
        ogrn: createOgrnComp,
        okopf: createOkopfComp,
        reg_number: createRegNumComp,
        name_full: createNameFull,
        okato: createOkato,
        oktmo: createOktmo,
        address: createAddress,
        organizations_in_stakeholders: organizationInStakeholders,
        business_financing: businessFinancing,
        msp: msp,
      };

      apiPost({ url: '/organization/create', postData })
        .then(res => {
          if (res.error) {
            errorDataAlert('Ошибка');
            return;
          }
          getUserCompany();
          createMfoAlert();
          showModal(false);
        })
        .finally(() => setSending(false));
    }
  };

  const confirmUpdate = () => {
    updateUserProfile();
    updateCompany();
  };

  useEffect(() => {
    if (errorUpdateMfo !== true && errorUpdateProfile !== true) {
      setValueEdit(true);
    }
  }, [errorUpdateMfo, errorUpdateProfile]);

  const linkRoute = [
    {
      name: 'Пользователи',
      link: '/users',
    },
    {
      name: 'Просмотр пользователя',
      link: `/view-user/${userId}`,
    },
  ];

  const onShowAttributeModal = attribute => {
    dispatch(setCurrentAttribute({ attribute, compId }));
  };

  useEffect(() => {
    viewUser();
    getUserCompany();
    getOkpfData();
  }, []);

  return (
    <div className="view-user">
      <Breadcrumbs linkRoute={linkRoute} />

      <div className="card form">
        <div className="card-header d-flex jc-between">
          <div className="name">Просмотр пользователя</div>

          <div className="flex items-center gap-2">
            <Link className="btn btn-primary hover:text-white" to={`/organization-registry/${compId}`}>
              Реестр по должникам
            </Link>

            {state.user.accessUser.user.update && (
              <div>
                {valueEdit ? (
                  <button className="btn btn-primary" onClick={() => toggleInputEdit()}>
                    Редактировать
                  </button>
                ) : (
                  <button className="btn btn-success" onClick={() => confirmUpdate()}>
                    Сохранить
                  </button>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="card-content">
          <div className="row d-flex ai-end">
            <div className="col-12 col-md-4">
              <Input
                label="Username"
                disabled={valueEdit}
                value={userUserName || ''}
                onChange={setUserUserName}
                error={profileErrObj.username}
              />
            </div>
            <div className="col-12 col-md-4">
              <Input
                label="Фамилия"
                disabled={valueEdit}
                value={userDataLastName || ''}
                onChange={setUserDataLastName}
                error={profileErrObj.lastName}
              />
            </div>
            <div className="col-12 col-md-4">
              <Input
                label="Имя"
                disabled={valueEdit}
                value={userDataName || ''}
                onChange={setUserDataName}
                error={profileErrObj.name}
              />
            </div>
            <div className="col-12 col-md-4">
              <Input
                onChange={setUserDataPatronymic}
                label="Отчество"
                disabled={valueEdit}
                value={userDataPatronymic || ''}
                error={profileErrObj.patronymic}
              />
            </div>
            <div className="col-12 col-md-4">
              <Input
                label="Почта"
                type="mail"
                disabled={valueEdit}
                value={userDataEmail || ''}
                onChange={setUserDataEmail}
                error={profileErrObj.email}
              />
            </div>
            <div className="col-12 col-md-4">
              <Input
                label="Телефон"
                type="tel"
                disabled={valueEdit}
                value={userDataPhone || ''}
                onChange={setUserDataPhone}
                error={profileErrObj.phone}
              />
            </div>

            {company ? (
              <>
                <div className="col-12 h4 mt-2 mb-3 d-flex jc-between">
                  <div>Информация об организации</div>
                  <Link to={`/mfo-reports/${compId}`} className="btn btn-success">
                    Отчеты организации
                  </Link>
                </div>

                <div className="col-12 col-md-4 name-event">
                  <Input
                    label="Наименование"
                    disabled={valueEdit}
                    value={mfoName || ''}
                    onChange={setMfoName}
                    error={companyErrObj.name}
                  />
                </div>
                <div className="col-12  col-md-4">
                  <Input
                    label="ИНН"
                    type="tel"
                    disabled={valueEdit}
                    value={mfoInn || ''}
                    onChange={setMfoInn}
                    error={companyErrObj.inn}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <Input
                    label="ОГРН"
                    type="tel"
                    disabled={valueEdit}
                    value={mfoOgrn || ''}
                    onChange={setMfoOgrn}
                    error={companyErrObj.ogrn}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <Input
                    label="Регистрационный номер в государственном реестре МФО"
                    type="tel"
                    disabled={valueEdit}
                    value={mfoRegNum || ''}
                    onChange={setMfoRegNum}
                    error={companyErrObj.reg_number}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <Input
                    label="ОКТМО"
                    type="tel"
                    disabled={valueEdit}
                    value={mfoOktmo || ''}
                    onChange={setmfoOktmo}
                    error={companyErrObj.oktmo}
                  />

                  <button onClick={() => onShowAttributeModal('oktmo')} className="group pos-abs-y right-8">
                    <Pencil className="group-hover:fill-primary transition-all duration-300 fill-[#9699a8]" />
                  </button>
                </div>
                <div className="col-12 col-md-4">
                  <Input
                    label="ОКАТО"
                    type="tel"
                    disabled={valueEdit}
                    value={mfoOkato || ''}
                    onChange={setmfoOkato}
                    error={companyErrObj.okato}
                  />
                </div>
                <div className="col-12">
                  <label className="form-field">
                    <span className="form-label">ОКОПФ</span>
                    <select
                      disabled={valueEdit}
                      value={mfoOkopf}
                      onChange={e => {
                        setMfoOkopf(e.target.value), setSendedMfoOkopf(e.target.value);
                      }}
                      className="form-input _select"
                    >
                      {dataListOkopf.map(i => (
                        <option value={i} key={i}>
                          {i}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
                <div className="col-12">
                  <Input
                    label="Полное наименование организации"
                    type="tel"
                    disabled={valueEdit}
                    value={mfoNameFull || ''}
                    onChange={setmfoNameFull}
                    error={companyErrObj.name_full}
                  />

                  <button onClick={() => onShowAttributeModal('name_full')} className="group pos-abs-y right-8">
                    <Pencil className="group-hover:fill-primary transition-all duration-300 fill-[#9699a8]" />
                  </button>
                </div>
                <div className="col-12">
                  <Input
                    label="Адрес места нахождения микрокредитной компании"
                    type="tel"
                    disabled={valueEdit}
                    value={mfoAddress || ''}
                    onChange={setmfoAddress}
                    error={companyErrObj.address}
                  />

                  <button onClick={() => onShowAttributeModal('address')} className="group pos-abs-y right-8">
                    <Pencil className="group-hover:fill-primary transition-all duration-300 fill-[#9699a8]" />
                  </button>
                </div>
                <div className="col-12">
                  <label className="form-field">
                    <span className="form-label">Участники</span>
                    <select
                      disabled={valueEdit}
                      value={organizationInStakeholders}
                      onChange={e => setOrganizationInStakeholders(e.target.value)}
                      className="form-input _select"
                    >
                      <option value={0} key={0}>
                        физ. лицо
                      </option>
                      <option value={1} key={1}>
                        юр. лицо
                      </option>
                    </select>
                  </label>
                </div>
                <div className="col-12">
                  <label className="form-field">
                    <span className="form-label">Предпринимательское финансирование</span>
                    <select
                      disabled={valueEdit}
                      value={businessFinancing}
                      onChange={e => setBusinessFinancing(e.target.value)}
                      className="form-input _select"
                    >
                      <option value={0} key={0}>
                        Нет
                      </option>
                      <option value={1} key={1}>
                        Да
                      </option>
                    </select>
                  </label>
                </div>
                <div className="col-12">
                  <label className="form-field">
                    <span className="form-label">МСП</span>
                    <select
                      disabled={valueEdit}
                      value={msp}
                      onChange={e => setMsp(e.target.value)}
                      className="form-input _select"
                    >
                      <option value={0} key={0}>
                        Нет
                      </option>
                      <option value={1} key={1}>
                        Да
                      </option>
                    </select>
                  </label>
                </div>
              </>
            ) : (
              <div className="col-12">
                <div className="h4 mt-2 mb-3">Организации нет</div>

                <button onClick={() => showModal(true)} className="btn btn-primary">
                  Добавить организацию
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <OrganizationAttributeModal />

      <Modal
        show={viewModal}
        onClose={() => showModal(false)}
        modalTitle="Создание организации"
        footer={
          <button className="btn btn-primary" onClick={() => createCompany()}>
            Создать
          </button>
        }
      >
        <div className="row d-flex ai-end">
          <div className="col-12 col-md-4">
            <Input label="Название организации" value={createNameComp || ''} onChange={setCreateNameComp} />
          </div>
          <div className="col-12 col-md-4">
            <Input label="ИНН" value={createInnComp || ''} onChange={setCreateInnComp} />
          </div>
          <div className="col-12 col-md-4">
            <Input label="ОГРН" value={createOgrnComp || ''} onChange={setCreateOgrnComp} />
          </div>
          <div className="col-12 col-md-4">
            <Input
              label="Регистрационный номер в государственном реестре МФО"
              value={createRegNumComp || ''}
              onChange={setCreateRegNumComp}
            />
          </div>
          <div className="col-12 col-md-4">
            <Input label="ОКТМО" value={createOktmo || ''} onChange={setCreateOktmo} />
          </div>
          <div className="col-12 col-md-4">
            <Input label="ОКАТО" value={createOkato || ''} onChange={setCreateOkato} />
          </div>
          <div className="col-12">
            <label className="form-field">
              <span className="form-label">ОКОПФ</span>
              <select
                value={createOkopfComp}
                onChange={e => setCreateOkopfComp(e.target.value)}
                className="form-input _select"
              >
                {dataListOkopf.map(i => (
                  <option value={i} key={i}>
                    {i}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="col-12">
            <Input label="Полное наименование организации" value={createNameFull || ''} onChange={setCreateNameFull} />
          </div>
          <div className="col-12">
            <Input
              label="Адрес места нахождения микрокредитной компании"
              value={createAddress || ''}
              onChange={setCreateAddress}
            />
          </div>
          <div className="col-12">
            <label className="form-field">
              <span className="form-label">Участники</span>
              <select
                value={organizationInStakeholders}
                onChange={e => setOrganizationInStakeholders(e.target.value)}
                className="form-input _select"
              >
                <option value={0} key={0}>
                  физ. лицо
                </option>
                <option value={1} key={1}>
                  юр. лицо
                </option>
              </select>
            </label>
          </div>
          <div className="col-12">
            <label className="form-field">
              <span className="form-label">Предпринимательское финансирование</span>
              <select
                value={businessFinancing}
                onChange={e => setBusinessFinancing(e.target.value)}
                className="form-input _select"
              >
                <option value={0} key={0}>
                  Нет
                </option>
                <option value={1} key={1}>
                  Да
                </option>
              </select>
            </label>
          </div>
          <div className="col-12">
            <label className="form-field">
              <span className="form-label">МСП</span>
              <select value={msp} onChange={e => setMsp(e.target.value)} className="form-input _select">
                <option value={0} key={0}>
                  Нет
                </option>
                <option value={1} key={1}>
                  Да
                </option>
              </select>
            </label>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ViewUser;
