import React from 'react';
import TriggerReports from '../../containers/Lk/TriggerReports';

const EditTriggerReportsPage = () => {
  return (
    <>
      <TriggerReports />
    </>
  );
};

export default EditTriggerReportsPage;
