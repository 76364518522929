import { useHistory, useParams } from 'react-router-dom';
import useUrl from '../../../hooks/useUrl';
import { useDispatch } from 'react-redux';
import { useFetch } from '../../../hooks/useFetch';
import { createUrlQuery } from '../../../utils/createApiUrl';
import { apiGet, apiPost } from '../../../api';
import { message } from 'antd';
import { confirmAlert } from 'react-confirm-alert';
import { getFolderIdUrl } from '../../../utils/getFolderId';
import { downloadReport } from '../../../utils/downloadReport';

export const useOrganizationDkPage = () => {
  const { id } = useParams();
  const params = useUrl();
  const dispatch = useDispatch();
  const history = useHistory();

  const { response, fetchData, loading } = useFetch({
    url: `/dk-protocol/view-organization${createUrlQuery({
      extractId: id,
      id: params.protocolId,
    })}`,
  });

  const linkRoute = [
    {
      name: 'Протоколы',
      link: `/protocol/${params.archive}${getFolderIdUrl()}`,
    },
    {
      name: 'Протокол ',
      link: `/protocol-view/${params.protocolId}${getFolderIdUrl()}`,
    },
    {
      name: 'Просмотр организации',
    },
  ];

  const deleteProtocol = async () => {
    const { error, data } = await apiPost({
      url: `/dk-protocol/delete-organization${createUrlQuery({ id: params.protocolId, organizationId: id })}`,
    });
    if (error) {
      message.error(data);
    }
    if (!error) {
      message.success('Успешно удалено!');
      history.goBack();
    }
  };

  const handleConfirmDeleteOrganization = () => {
    confirmAlert({
      title: 'Удаление',
      message: 'Вы точно хотите удалить организацию?',
      buttons: [
        {
          label: 'Да',
          onClick: () => deleteProtocol(),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  const sendExtractsById = async () => {
    const { error } = await apiPost({ url: `/dk-extract/send${createUrlQuery({ id: response.extractId })}` });
    if (!error) {
      message.success('Успешно отправлено!');
      await fetchData();
    }
  };

  const downloadFile = () => {
    apiGet({ url: `/dk-protocol/download-organization-doc?id=${params.protocolId}&extractId=${id}` }).then(res => {
      if (res.error) {
        console.error('Скачивание документа (шаблон)');
        return;
      }
      console.log(res);
      downloadReport(res.data, true);
    });
  };

  const deleteInvitation = async () => {
    const { error } = await apiPost({
      url: `/dk-protocol/delete-organization-doc${createUrlQuery({ id: params.protocolId, extractId: id })}`,
    });
    if (!error) {
      await fetchData();
      message.success('Успешно удалено!');
    }
  };

  const handleConfirmDeletefile = (e, id) => {
    e.stopPropagation();
    confirmAlert({
      title: 'Удаление',
      message: 'Вы точно хотите удалить файл?',
      buttons: [
        {
          label: 'Да',
          onClick: () => deleteInvitation(id),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  return {
    id,
    linkRoute,
    downloadFile,
    loading,
    dispatch,
    sendExtractsById,
    handleConfirmDeletefile,
    params,
    response,
    handleConfirmDeleteOrganization,
    fetchData,
  };
};
