import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { apiGet, apiPost } from '../../../../api';
import { useFetch } from '../../../../hooks/useFetch';
import document from '../../../../static/img/icons/document.svg';
import { downloadBase64File } from '../../../../utils/downloadBase64';
import { Dropzone, Input, Modal } from '../../../components';
import { Gear, TrashCan } from '../../../icons';
import './index.scss';
import { CustomEditor } from '../../../components/CustomEditor/CustomEditor';
import ReactQuill from 'react-quill';

const Event = () => {
  const state = useSelector(state => state);
  const [createEventSending, setCreateEventSending] = useState(false);
  const [editEventSending, setEditEventSending] = useState(false);
  const [createEventErrObj, setCreateEventErrObj] = useState({});
  const [editEventErrObj, setEditEventErrObj] = useState({});

  const [viewModalNewEvent, setViewModalNewEvent] = useState(false);
  const [viewModalEditEvent, setViewModalEditEvent] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [eventName, setEventName] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [filesEdited, setFilesEdited] = useState([]);
  const [eventDescription, setEventDescription] = useState('');
  const [page, setPage] = useState(1);
  const [sorting, setSorting] = useState('');
  const [listItemId, setListItemId] = useState(0);
  const [files, setFiles] = useState([]);

  const eventCreateTrue = () => {
    confirmAlert({
      message: 'Мероприятие успешно создано',
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  const eventEditAlert = () => {
    confirmAlert({
      message: 'Мероприятие успешно изменено',
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  const onFileRemove = id => {
    apiGet({
      url: `/event/delete-file?id=${id}`,
    })
      .then(res => {
        if (res.error) {
          console.error(res.data);
          return;
        }
        let arr = [];
        filesEdited.map(f => {
          if (f.id != id) {
            arr.push(f);
          }
        });
        setFilesEdited(arr);
      })
      .finally(() => setEditEventSending(false));
  };

  const eventCreateError = () => {
    confirmAlert({
      message: 'Ошибка при создании события',
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  const deleteEventAlert = id => {
    confirmAlert({
      title: 'Удаление',
      message: 'Вы точно хотите удалить мероприятие?',
      buttons: [
        {
          label: 'Да',
          onClick: () => deleteEvent(id),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  const reformatDate = dateStr => {
    return dateStr;
  };

  const reeformatDate = dateStr => {
    let dArr = [];
    if (dateStr != null) {
      dArr = dateStr.split('.');
    }
    return dArr[2] + '-' + dArr[1] + '-' + dArr[0];
  };

  const getSortClass = sortType => {
    if (sorting.match(sortType)) {
      if (sorting === `-${sortType}`) {
        return '_desc';
      } else {
        return '_asc';
      }
    }

    return '';
  };

  const setBackDate = date => {
    setEventDate(reeformatDate(date));
  };

  const onChangeDate = e => {
    setEventDate(reformatDate(e));
  };

  const showModalNewEvent = isShow => {
    setViewModalNewEvent(isShow);
    setEventDate('');
    setCreateEventErrObj({});
  };

  const showModalEditEvent = isShow => {
    setViewModalEditEvent(isShow);
  };

  const { response, fetchData } = useFetch({
    url: `/event?page=${page}&limit=10&sort=${sorting}`,
  });

  //Сортировка по дате
  const sortTable = sortType => {
    if (sorting.match(sortType)) {
      if (sorting === `-${sortType}`) {
        setSorting(sortType);
      } else {
        setSorting(`-${sortType}`);
      }
    } else {
      setSorting(sortType);
    }
  };

  // создание мироприятия
  const createEvent = () => {
    let formData = new FormData();
    formData.append('title', eventName);
    formData.append('content', eventDescription);
    formData.append('date', eventDate);

    if (files.length) {
      files.forEach(file => {
        formData.append('files[]', file);
      });
    }
    if (!createEventSending) {
      setCreateEventSending(true);

      apiPost({
        url: '/event/create',
        postData: formData,
      })
        .then(res => {
          if (res.status === 406) {
            setCreateEventErrObj(res.data);
            return;
          } else if (res.error) {
            eventCreateError();
            return;
          }

          setViewModalNewEvent(false);
          eventCreateTrue();
          fetchData();
          setCreateEventErrObj({});
          setEventDate('');
          setFiles([]);
          setEventDate('');
        })
        .finally(() => setCreateEventSending(false));
    }
  };

  //удаление мироприятия
  const deleteEvent = id => {
    apiGet({ url: `/event/delete?id=${id}` }).then(res => {
      if (res.error) {
        console.error('Удаление мероприятия');
        return;
      }

      showModalEditEvent(false);
      fetchData();
    });
  };

  // изменить мироприятие
  const editEvent = id => {
    let formData = new FormData();
    formData.append('title', eventName);
    formData.append('content', eventDescription);
    formData.append('date', eventDate);

    if (files.length) {
      files.forEach(file => {
        formData.append('files[]', file);
      });
    }
    if (!editEventSending) {
      setEditEventSending(true);

      apiPost({
        url: `/event/update?id=${id}`,
        postData: formData,
      })
        .then(res => {
          if (res.status === 406) {
            setEditEventErrObj(res.data);
            return;
          } else if (res.error) {
            console.error('Изменение события');
            return;
          }

          showModalEditEvent(false);
          fetchData();
          setEditEventErrObj({});
          eventEditAlert();
          setFiles([]);
        })
        .finally(() => setEditEventSending(false));
    }
  };

  const removeFile = fileName => {
    const data = files.filter(item => item.name !== fileName);
    setFiles(data);
  };

  const handleUploadFiles = file => {
    setFiles([...files, ...file]);
  };

  const downloadFile = id => {
    apiGet({ url: `/event/file?id=${id}`, timeout: 60000000 }).then(res => {
      if (res.error) {
        console.error('Скачивание файла (Мероприятия)');
        return;
      }

      downloadBase64File(res.data.asBase64, res.data.name);
    });
  };

  const openNewEvent = () => {
    showModalNewEvent(true);
    setEventName('');
    setEventDate('');
    setEventDescription('');
    setFiles([]);
  };

  useEffect(() => {
    response && setEventList(page === 1 ? eventList : [...eventList, ...response.data]);
  }, [response]);

  return (
    <div className="event">
      <div className="card">
        <div className="card-header">
          <div className="row ai-center jc-between">
            <div className="col-auto">Мероприятия</div>
            {state.user.accessUser.event.create && (
              <div className="col-auto">
                <button id="new-event" className="btn btn-primary btn-xs" onClick={() => openNewEvent()}>
                  Создать новое мероприятие
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="card-content">
          <div className="scroll-x">
            <table className="low-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Название мероприятия</th>
                  <th
                    // className={`sort ${getSortClass("date")}`}
                    onClick={() => sortTable('date')}
                    style={{
                      maxWidth: 188,
                      display: 'flex',
                      cursor: 'pointer',
                    }}
                  >
                    <span> Дата мероприятия</span>
                    <div className={`sort ${getSortClass('date')}`} />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {response &&
                  response.models.map((i, index) => (
                    <tr key={index}>
                      <td>{i.id}</td>
                      <td>{i.title}</td>
                      <td style={{ maxWidth: 100 }}>{i.eventDate}</td>
                      <td>
                        <div
                          className="row row-sm jc-end"
                          style={{
                            minWidth: 120,
                          }}
                        >
                          {state.user.accessUser.event.update && (
                            <div className="col-auto">
                              <button
                                className="action-btn btn btn-warning"
                                onClick={() => [
                                  showModalEditEvent(true),
                                  setListItemId(i.id),
                                  setEventName(i.title),
                                  setEventDescription(i.content),
                                  setBackDate(i.eventDate),
                                  setFilesEdited(i.files || null),
                                ]}
                                title="Изменить"
                              >
                                <Gear />
                              </button>
                            </div>
                          )}
                          {state.user.accessUser.event.delete && (
                            <div className="col-auto">
                              <button
                                className="action-btn btn btn-danger"
                                onClick={() => deleteEventAlert(i.id)}
                                title="Удалить"
                              >
                                <TrashCan />
                              </button>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="mt-2 ta-center">
            <ReactPaginate
              pageCount={response?.lastPage == 1 ? 0 : response?.lastPage}
              pageRangeDisplayed={5}
              marginPagesDisplayed={3}
              onPageChange={e => setPage(e.selected + 1)}
              activeClassName={'active'}
              containerClassName={'pagination d-inline-flex ta-center'}
              pageLinkClassName={'link'}
              pageClassName={'link'}
              previousLabel={false}
              nextLabel={false}
            />
          </div>
        </div>
      </div>

      <Modal
        show={viewModalNewEvent}
        onClose={() => showModalNewEvent(false)}
        modalTitle="Создание мероприятия"
        footer={
          <button onClick={() => createEvent()} className="btn btn-primary">
            Создать
          </button>
        }
      >
        <Input label="Название" value={eventName} onChange={setEventName} error={createEventErrObj.title} />

        <Input label="Дата" type="date" value={eventDate} onChange={onChangeDate} error={createEventErrObj.date} />
        {/*<CustomEditor className="mt-3 editor" value={eventDescription} onChange={setEventDescription} />*/}
        <ReactQuill className="mt-3 editor" value={eventDescription} onChange={setEventDescription} />
        {createEventErrObj.content && (
          <span style={{ color: '#f46a6a', fontSize: '12px', display: 'block', marginTop: 6, marginBottom: 6 }}>
            {createEventErrObj.content}
          </span>
        )}

        <div className="mt-1">
          {' '}
          <Dropzone
            maxSize={100000000}
            onFileRemove={removeFile}
            onFileUpload={handleUploadFiles}
            styles={{ height: 120 }}
            files={files}
            // acceptFileType=".jpg, .jpeg, .png, .doc, .docx, .pdf, .xls, .xlsx, .xtdd"
          />
        </div>
        {createEventErrObj.files && (
          <span style={{ color: '#f46a6a', fontSize: '12px', display: 'block' }}>{createEventErrObj.files}</span>
        )}
      </Modal>

      <Modal
        show={viewModalEditEvent}
        onClose={() => showModalEditEvent(false)}
        modalTitle="Редактирование мероприятия"
        footer={
          <div className="row row-sm">
            <div className="col-auto">
              <button onClick={() => deleteEventAlert(listItemId)} className="btn btn-danger">
                Удалить
              </button>
            </div>

            <div className="col-auto">
              <button onClick={() => editEvent(listItemId)} className="btn btn-primary">
                Сохранить
              </button>
            </div>
          </div>
        }
      >
        <Input label="Название" defaultValue={eventName} onChange={setEventName} error={editEventErrObj.name} />

        <Input label="Дата" type="date" defaultValue={eventDate} onChange={onChangeDate} error={editEventErrObj.date} />

        {/*<CustomEditor className="mt-3 editor" value={eventDescription} onChange={setEventDescription} />*/}
        <ReactQuill className="mt-3 editor" value={eventDescription} onChange={setEventDescription} />

        {filesEdited &&
          filesEdited.map((i, index) => (
            <div
              key={index}
              className={'file pos mb-3'}
              onClick={e => e.target.className !== 'file-remove btn btn-danger pos' && downloadFile(i.id)}
            >
              <div className="row row-sm ai-center">
                <div className="col-auto">
                  <div className="file-img pos">
                    <img src={document} alt={i.name} className="image-contain doc-icon pos-abs" />
                  </div>
                </div>

                <div className="col font-700">
                  <div className="file-name">{i.name}</div>
                  <div className="file-size">{i.size}</div>
                </div>

                <div className="col-auto d-flex">
                  <button className="file-remove btn btn-danger pos" onClick={() => onFileRemove(i.id)}>
                    <TrashCan size={14} color="#fff" className="pos-abs" />
                  </button>
                </div>
              </div>
            </div>
          ))}

        <div className="mt-1">
          {' '}
          <Dropzone
            maxSize={100000000}
            onFileRemove={removeFile}
            onFileUpload={handleUploadFiles}
            styles={{ height: 120 }}
            files={files}
            // acceptFileType=".jpg, .jpeg, .png, .doc, .docx, .pdf, .xls, .xlsx, .xtdd"
          />
        </div>
        {createEventErrObj.files && (
          <span style={{ color: '#f46a6a', fontSize: '12px', display: 'block' }}>{createEventErrObj.files}</span>
        )}
      </Modal>
    </div>
  );
};

export default Event;
